/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import Swal from "sweetalert2";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";
import SuiDatePicker from "components/SuiDatePicker";
import { useTranslation } from "react-i18next";
import FormField from "components/FormField";
import { useHistory } from "react-router-dom";

function ActionCell({ id }) {
  const [name, setName] = useState();
  const [data, setData] = useState(null);
  const [serialNo, setSerialNo] = useState();
  const [manufacter, setManufacter] = useState();
  const [type, setType] = useState();
  const [open, setOpen] = useState(false);
  const [battExp, setBattExp] = useState("");
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, []);

  const sentUpdate = (name, serialNo, manufacter, type, battExp, id) => {
    setOpen(false);
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
            mutation update_vessel_equipments_by_pk {
              update_vessel_equipments_by_pk(
                _set: { 
                name: ${name ? JSON.stringify(name) : null}, 
                manufacter:${manufacter ? JSON.stringify(manufacter) : null}, 
                serial_no: ${serialNo ? JSON.stringify(serialNo) : null},
                type: ${type ? JSON.stringify(type) : null},
                battery_expiry_date: ${
                  battExp ? JSON.stringify(battExp) : null
                } 
              }, pk_columns: {id: ${id}}) {
                vessel_id
              }
            }            
          `,
        })
        .then((result) => {
          Swal.fire({
            name: "Success",
            text: t("Equipment updated successfully"),
            icon: "success",
            confirmButtonText: t("Ok"),
          });
          getData();
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getData = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessel_equipments_by_pk($id: Int!) {
              vessel_equipments_by_pk(id: $id) {
                name
                manufacter
                type
                serial_no
                id
                battery_expiry_date
              }
            }
          `,
          variables: {
            id: id,
          },
        })
        .then((result) => {
          setData(result.data.vessel_equipments_by_pk);
          setName(result.data.vessel_equipments_by_pk.name);
          setManufacter(result.data.vessel_equipments_by_pk.manufacter);
          setSerialNo(result.data.vessel_equipments_by_pk.serial_no);
          setType(result.data.vessel_equipments_by_pk.type);
          setBattExp(result.data.vessel_equipments_by_pk.battery_expiry_date);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <SuiBox>
      <SuiButton
        display="flex"
        alignItems="center"
        onClick={() => setOpen(true)}
      >
        <Tooltip title="Edit product" placement="top">
          <Icon className="">edit</Icon>
        </Tooltip>
      </SuiButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            margin: "5%",
          }}
        >
          <Card>
            <SuiBox p={3}>
              <Grid item xs={12} sm={12}>
                <SuiBox display="flex" justifyContent="space-between">
                  <SuiTypography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    textColor="text"
                  >
                    {t("Equipment Add")}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    buttonColor="error"
                    size="small"
                    circular
                    iconOnly
                    onClick={() => setOpen(false)}
                  >
                    <Icon className="material-icons-round" fontSize="large">
                      close
                    </Icon>
                  </SuiButton>
                </SuiBox>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2.5}>
                  <FormField
                    name="InputText"
                    label={t("Name")}
                    placeholder="ex. 365Tech."
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.2}>
                  <FormField
                    name="InputText"
                    label={t("Serial No")}
                    placeholder="ex. 365Tech."
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <FormField
                    name="InputText"
                    label={t("Manufacter")}
                    placeholder="ex. 365Tech."
                    value={manufacter}
                    onChange={(e) => setManufacter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <FormField
                    name="InputText"
                    label={t("Type")}
                    placeholder="ex. 365Tech."
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.3}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Batt. Exp.")}
                  </SuiTypography>
                  <SuiDatePicker
                    //name={eta.name}
                    value={battExp}
                    onChange={setBattExp}
                  />
                </Grid>
              </Grid>
              <SuiBox display="flex" justifyContent="center" mt={2}>
                <SuiButton
                  variant="gradient"
                  onClick={() =>
                    sentUpdate(name, manufacter, serialNo, type, battExp, id)
                  }
                  buttonColor="info"
                  disabled={name ? false : true}
                >
                  <Icon className="material-icons-round" fontSize="large">
                    save
                  </Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Modal>
    </SuiBox>
  );
}

export default ActionCell;
