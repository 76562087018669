/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import { db, auth, fbstorage } from "firebase/firebase";

import image from "assets/images/pdf.png";

import { useTranslation } from "react-i18next";

function FolderCards({ title, data }) {
  const [loading, setLoading] = useState(false);
  const [dataSrc, setDataSrc] = useState(null);

  const { t } = useTranslation();

  // const deleteFile = (itemId) => {
  //   const userToken = getAccessToken();
  //   const uid = auth.currentUser.uid

  //   const headers = {
  //     Authorization: `Bearer ${userToken}`,
  //   }
  //   const client = new ApolloClient({
  //     uri: 'https://hasura-trc.365tech.dev/v1/graphql',
  //     headers,
  //     cache: new InMemoryCache()
  //   });

  //   if(auth.currentUser){
  //     client
  //       .mutate({
  //         mutation: gql`
  //         mutation user_resources_by_pk {
  //           update_user_resources_by_pk(pk_columns: {id: ${itemId}}, _set: {status: false}) {
  //             id
  //             status
  //           }
  //         }
  //         `
  //       })
  //       .then(result => {
  //         setDataSrc({result})
  //         setLoading(false)
  //       })
  //   }
  // }

  useEffect(() => {
    console.log(loading);
    if (data) {
      setLoading(true);
      fbstorage
        .ref(data.file_path)
        .getDownloadURL()
        .then((url) => {
          setDataSrc({
            src: url,
            name: data.name,
            id: data.id,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [data]);

  return (
    <Grid item xs={12} sm={12}>
      {dataSrc && dataSrc.name && !loading ? (
        <SuiBox mt={2}>
          <SuiBox
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SuiTypography
              component="label"
              textGradient={true}
              textColor="primary"
              variant="caption"
              fontWeight="regular"
              textTransform="capitalize"
            >
              {" "}
              {title}
            </SuiTypography>
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <SuiBox
              lineHeight={0}
              component="img"
              src={image}
              width="45px"
              customClass="cursor-pointer object-cover"
              onClick={() => window.open(dataSrc.src, "_blank")}
            />
            <SuiTypography
              component="label"
              variant="caption"
              textTransform="capitalize"
            >
              {dataSrc.name}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : (!dataSrc && !loading) || (dataSrc && !dataSrc.name && !loading) ? (
        <SuiBox>
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {" "}
            {title}
          </SuiTypography>
          <SuiBox display="grid">
            <SuiTypography
              component="label"
              variant="caption"
              textTransform="capitalize"
            >
              {t("Folders not found.")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : (
        <SuiBox display="flex" p={0}>
          <img
            src="/loader.gif"
            alt="TRC Logo"
            style={{
              objectFit: "cover",
              height: "130px",
              width: "100%",
              borderRadius: "5px",
            }}
          />
        </SuiBox>
      )}
    </Grid>
  );
}

export default FolderCards;
