/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
import Swal from "sweetalert2";
import FormField from "components/FormField";
import { useTranslation } from "react-i18next";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken, getUserClaims } from "constants/valueStorage";
import { auth } from "../../../firebase/firebase";
import moment from "moment";

function ProductEdit({ id }) {
  const [shipInfo, setShipInfo] = useState();
  const [locList, setLocList] = useState([]);
  const [shippingMethodList, setShippingMethodList] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [customsStatusList, setCustomsStatusList] = useState([]);
  const [locListLabel, setLocListLabel] = useState([]);
  const [shippingMethodListLabel, setShippingMethodListLabel] = useState([]);
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [assignedListLabel, setAssignedListLabel] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [staffListLabel, setStaffListLabel] = useState([]);

  const [imo, setImo] = useState("");
  const [shipname, setShipname] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [agent, setAgent] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [engineSupplier, setEngineSupplier] = useState("");
  const [orderTotal, setOrderTotal] = useState(Number);
  const [orderCurrency, setOrderCurrency] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [customsStatus, setCustomsStatus] = useState("");
  const [arrivalLocName, setArrivalLocName] = useState("");
  const [shippingMethod, setShippingMethod] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [ETD, setETD] = useState("");
  const [ETA, setETA] = useState("");
  const [ETB, setETB] = useState("");

  const history = useHistory();

  const { t } = useTranslation();

  const NotificationUser = [
    "ML6DuCNBhGhISeWb5t9ABeFyS9r2",
    "BZFSqkEZZvclVvILuYyJtdtB52n2",
    "aVfBC20bkpYKMGBUdU4U77GCF3T2",
    "UtOsXeh0gcdnfjMzOwFeWFKaLob2",
    "KLW6ev9wRsRODZ9LnOGhKUYQ9xj1",
  ];

  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  const setRealDeliveryDate = (e) => {
    if (e === "delivered") {
      setDeliveryDate(moment().format("YYYY-MM-DD"));
    } else {
      setDeliveryDate(null);
    }
    setOrderStatus(orderStatusList.find((item) => item.value === e));
  };

  useEffect(() => {
    if (shipInfo) {
      shipInfo.imo && setImo(shipInfo.imo);
      shipInfo.shipname && setShipname(shipInfo.shipname);
      shipInfo.order_number && setRefNumber(shipInfo.order_number);
      shipInfo.delivery_company &&
        setDeliveryLocation(shipInfo.delivery_company);
      shipInfo.agent && setAgent(shipInfo.agent);
      shipInfo.assigned_jobs && setAssignedJobs(shipInfo.assigned_jobs);
      shipInfo.company_name && setCompanyName(shipInfo.company_name);
      shipInfo.engine_supplier && setEngineSupplier(shipInfo.engine_supplier);
      shipInfo.order_total && setOrderTotal(shipInfo.order_total);
      shipInfo.order_currencies &&
        setOrderCurrency({
          value: shipInfo.order_currencies,
          label: shipInfo.order_currencies,
        });
      shipInfo.locations &&
        setArrivalLocName({
          value: shipInfo.locations.id,
          label: shipInfo.locations.location_full,
        });
      shipInfo.shippingMethodByShippingMethod &&
        setShippingMethod({
          value: shipInfo.shippingMethodByShippingMethod.method,
          label: shipInfo.shippingMethodByShippingMethod.method_desc,
        });
      shipInfo.ship_infos_status &&
        setOrderStatus({
          value: shipInfo.ship_infos_status.status,
          label: shipInfo.ship_infos_status.status_desc,
        });
      shipInfo.customsStatusByCustomsStatus &&
        setCustomsStatus({
          value: shipInfo.customsStatusByCustomsStatus.customs_status,
          label: shipInfo.customsStatusByCustomsStatus.customs_status_desc,
        });
      shipInfo.created_at &&
        setCreatedAt(moment(shipInfo.created_at, "YYYY-MM-DD HH:mm:ss"));
      shipInfo.delivery_date && setDeliveryDate(moment(shipInfo.delivery_date));
      shipInfo.ETD && setETD(moment(shipInfo.ETD, "YYYY-MM-DD"));
      shipInfo.ETA && setETA(moment(shipInfo.ETA, "YYYY-MM-DD"));
      shipInfo.ETB && setETB(moment(shipInfo.ETB, "YYYY-MM-DD"));
    }
  }, [shipInfo]);

  useEffect(() => {
    getOpUsers();
    getlocs();
    getShippingMethod();
    getOrderStatusList();
    getCustomsStatus();
    getDetail();
  }, []);

  const getDetail = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos {
              ship_infos(where: {id: {_eq: ${id}}}) {
                id
                ETD
                ETA
                ETB
                UserUID
                delivery_date
                planned_delivery_date
                created_at
                order_total
                order_currencies
                currency {
                  symbol
                }
                company_name
                engine_supplier
                arrival_loc_name
                ship_office_worker_name {
                  name
                }
                imo
                agent
                shipname
                customsStatusByCustomsStatus {
                  customs_status_desc
                  customs_status
                }
                shippingMethodByShippingMethod {
                  method_desc
                  method
                }            
                locations {
                  location
                  id
                  location_full
                }
                ship_infos_status {
                  status_desc
                  status
                }
                delivery_type
                delivery_company
                order_number
                assigned_jobs {
                  uid
                  users {
                    name
                  }
                }
              }
            }
          `,
        })
        .then((result) => {
          setShipInfo(result.data.ship_infos[0]);
        });
    }
  };

  const getOpUsers = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query users {
              users(
                where: { user_type: { _eq: operator }, status: { _eq: true } }
              ) {
                name
                uid
              }
            }
          `,
        })
        .then((result) => {
          var users = [];
          result.data.users.map((user) =>
            users.push({ value: user.uid, name: user.name })
          );
          setStaffList(users);
        });
    }
  };

  const getlocs = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query locations {
              locations(order_by: { location: asc }) {
                location
                location_full
                id
              }
            }
          `,
        })
        .then((result) => {
          var locs = [];
          result.data.locations.map((loc) =>
            locs.push({
              value: loc.id,
              name: loc.location_full,
            })
          );
          setLocList(locs);
        });
    }
  };

  const getShippingMethod = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query shipping_method {
              shipping_method {
                method
                method_desc
              }
            }
          `,
        })
        .then((result) => {
          var sm = [];
          result.data.shipping_method.map((data) =>
            sm.push({ value: data.method, name: data.method_desc })
          );
          setShippingMethodList(sm);
        });
    }
  };

  const getOrderStatusList = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos_status {
              ship_infos_status {
                status
                status_desc
              }
            }
          `,
        })
        .then((result) => {
          var sm = [];
          result.data.ship_infos_status.map((data) =>
            sm.push({ value: data.status, label: data.status_desc })
          );
          setOrderStatusList(sm);
        });
    }
  };

  const getCustomsStatus = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query customs_status {
              customs_status {
                customs_status
                customs_status_desc
              }
            }
          `,
        })
        .then((result) => {
          var sm = [];
          result.data.customs_status.map((data) =>
            sm.push({
              value: data.customs_status,
              label: data.customs_status_desc,
            })
          );
          setCustomsStatusList(sm);
        });
    }
  };

  useEffect(() => {
    setStaffListLabel(
      staffList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
  }, [staffList]);

  useEffect(() => {
    setAssignedListLabel(
      assignedJobs.map(({ users, uid: value, ...rest }) => ({
        label: users.name,
        value,
        ...rest,
      }))
    );
  }, [assignedJobs]);

  useEffect(() => {
    setLocListLabel(
      locList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
  }, [locList]);

  useEffect(() => {
    setShippingMethodListLabel(
      shippingMethodList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
  }, [shippingMethodList]);

  const sendUpdate = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_ship_infos_by_pk {          
            update_ship_infos_by_pk(pk_columns: {id: ${id}}, _set: {
              imo: ${JSON.stringify(imo)}, 
              shipname: ${JSON.stringify(shipname)},
              order_number: ${JSON.stringify(refNumber)}, 
              delivery_company: ${JSON.stringify(deliveryLocation)},
              agent: ${agent ? JSON.stringify(agent) : null}, 
              company_name: ${
                companyName ? JSON.stringify(companyName) : null
              }, 
              order_total: ${orderTotal ? JSON.stringify(orderTotal) : null}, 
              order_currencies: ${orderCurrency ? orderCurrency.value : null}, 
              shipping_method: ${shippingMethod ? shippingMethod.value : null}, 
              engine_supplier: ${
                engineSupplier ? JSON.stringify(engineSupplier) : null
              }, 
              location_id: ${arrivalLocName ? arrivalLocName.value : null}, 
              delivery_date : ${
                deliveryDate
                  ? JSON.stringify(moment(deliveryDate).format("YYYY-MM-DD"))
                  : null
              }, 
              ETA: ${
                ETA ? JSON.stringify(moment(ETA).format("YYYY-MM-DD")) : null
              }, 
              ETB: ${
                ETB ? JSON.stringify(moment(ETB).format("YYYY-MM-DD")) : null
              }, 
              ETD: ${
                ETD ? JSON.stringify(moment(ETD).format("YYYY-MM-DD")) : null
              },
              status: ${orderStatus ? orderStatus.value : null},
              customs_status: ${
                customsStatus ? customsStatus.value : "notstarted"
              },
            }) {
                id
            }
            delete_assigned_jobs(where: {job_id: {_eq: ${id}}, job_type: {_eq: "order"}}) {
              affected_rows
            }          
          }
          `,
        })
        .then((result) => {
          if (shipInfo) {
            if (
              (shipInfo.ship_infos_status &&
                orderStatus &&
                orderStatus.value !== shipInfo.ship_infos_status.status) ||
              (shipInfo.ship_infos_status == null && orderStatus !== null)
            ) {
              let notfDesc = "";
              switch (shipInfo.delivery_type) {
                case "ship":
                  notfDesc =
                    imo +
                    " Imo numaralı ve " +
                    shipname +
                    " gemi isimli iş " +
                    orderStatus.value +
                    " durumuna geçti";
                  break;
                case "land":
                  notfDesc =
                    refNumber +
                    " sipariş numaralı ve " +
                    deliveryLocation +
                    "lokasyon isimli iş " +
                    orderStatus.value +
                    " durumuna geçti";
                  break;
              }
              client.mutate({
                mutation: gql`
                  mutation insert_logs_one {
                    insert_logs_one(object: {log_type: "order", log_status: ${
                      orderStatus ? orderStatus.value : null
                    }, job_id: ${id}, user_uid: ${JSON.stringify(uid)}}) {
                      id
                    }
                  }
                  `,
              });
              if (assignedListLabel) {
                assignedListLabel.map((assigned) => {
                  client
                    .mutate({
                      mutation: gql`
                      mutation insert_fcm_notifications_one {
                      insert_fcm_notifications_one(object: {useruid: ${JSON.stringify(
                        assigned.value
                      )}, title: "İş güncellendi", desc: "${notfDesc}", status: ${
                        assigned ? "waiting" : "nouser"
                      }, type: ${assigned ? "user" : "nouser"}}) {
                        id
                      }
                    }
                    `,
                    })
                    .then((result) => {
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
               NotificationUsers(orderStatus.value);
              }
            }
            if (
              (shipInfo.customsStatusByCustomsStatus &&
                customsStatus &&
                customsStatus.value !==
                  shipInfo.customsStatusByCustomsStatus.customs_status &&
                customsStatus.value != null) ||
              (shipInfo.customsStatusByCustomsStatus == null &&
                customsStatus !== null &&
                customsStatus.value != null)
            ) {
              client.mutate({
                mutation: gql`
                  mutation insert_logs_one {
                    insert_logs_one(object: {log_type: "customs", log_status: ${
                      customsStatus ? customsStatus.value : "notstarted"
                    }, job_id: ${id}, user_uid: ${JSON.stringify(uid)}}) {
                      id
                    }
                  }
                  `,
              });
            }
          }
          assignedListLabel &&
            assignedListLabel.map((assigned) => {
              client
                .mutate({
                  mutation: gql`
                mutation insert_assigned_jobs {
                  insert_assigned_jobs(objects:
                    {uid: ${JSON.stringify(
                      assigned.value
                    )}, job_id: ${id}, job_type: "order"}
                  ) { returning {
                    id
                  }
                }
              }
              `,
                })
                .then((result) => {
                })
                .catch((error) => {
                  console.log(error);
                });
            });

          Swal.fire(t("Successfully added!"), "", "success").then((result) => {
            if (result.isConfirmed) {
              history.push("/order/detail/" + id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const NotificationUsers = (status) => {
    const notfDesc =
      imo +
      " Imo numaralı ve " +
      shipname +
      " gemi isimli iş " +
      status +
      " durumuna geçti";
    NotificationUser.map((user) => {
      client
        .mutate({
          mutation: gql`
            mutation insert_fcm_notifications_one {
              insert_fcm_notifications_one(object: {useruid: ${JSON.stringify(
                user
              )}, title: "İş güncellendi", desc: "${notfDesc}", status: ${
            user ? "waiting" : "nouser"
          }, type: ${user ? "user" : "nouser"}}) {
                id
              }
            }
            `,
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <SuiBox>
      <Card className="overflow-visible">
        <SuiBox p={3}>
          <SuiBox display="flex" justifyContent="space-between" mb={2}>
            <SuiTypography
              variant="h6"
              alignSelf="center"
              fontWeight="bold"
              textColor="text"
              gutterBottom
            >
              {t("Order Editing")}
            </SuiTypography>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => sendUpdate()}
            >
              {t("Save")}
            </SuiButton>
          </SuiBox>
          {shipInfo != null ? (
            <SuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="imo"
                    placeholder="ex. 1234567"
                    value={imo}
                    onChange={(e) => setImo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label={t("Ship Name")}
                    placeholder="ex. 365Ship"
                    value={shipname}
                    onChange={(e) => setShipname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label={t("agent")}
                    placeholder="ex. 365Agent"
                    value={agent}
                    onChange={(e) => setAgent(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label={t("Company Name")}
                    placeholder="ex. 365Tech"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
              </Grid>
              <SuiBox mt={2}>
                <SuiBox
                  mb={1}
                  ml={0.5}
                  lineHeight={0}
                  width="100%"
                  display="inline-block"
                >
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Order Date")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox
                  mb={1}
                  ml={0.5}
                  lineHeight={0}
                  width="100%"
                  display="inline-block"
                >
                  <SuiDatePicker value={createdAt} disabled />
                </SuiBox>
              </SuiBox>
            </SuiBox>
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="AVS Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </SuiBox>
      </Card>
      <SuiBox mt={3}>
        <Card className="overflow-visible">
          <SuiBox p={3}>
            <SuiTypography variant="h6" fontWeight="bold" textColor="text">
              {t("Status Informations")}
            </SuiTypography>
            {shipInfo != null ? (
              <SuiBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Order Status")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiSelect
                      placeholder="ex. Ready for supply"
                      options={orderStatusList}
                      value={orderStatus && orderStatus}
                      onChange={(e) => setRealDeliveryDate(e.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Customs Status")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiSelect
                      placeholder="ex. Applied"
                      options={customsStatusList}
                      value={customsStatus && customsStatus}
                      onChange={setCustomsStatus}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            ) : (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={0}
              >
                <img
                  src="/loader.gif"
                  alt="AVS Logo"
                  style={{
                    objectFit: "none",
                    height: "160px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox mt={3}>
        <Card className="overflow-visible">
          <SuiBox p={3}>
            <SuiTypography variant="h6" fontWeight="bold" textColor="text">
              {t("Delivery Informations")}
            </SuiTypography>
            {shipInfo != null ? (
              <SuiBox>
                <SuiBox mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {t("Appointed Personels")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        value={assignedListLabel && assignedListLabel}
                        onChange={setAssignedListLabel}
                        options={staffListLabel}
                        size="large"
                        placeholder="ex. Adnan Kurt"
                        isMulti
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("typeOfOrder")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        placeholder="ex. Tersane"
                        options={shippingMethodListLabel}
                        value={shippingMethod && shippingMethod}
                        onChange={setShippingMethod}
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Actual Delivery Date")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiDatePicker
                          value={deliveryDate}
                          onChange={setDeliveryDate}
                          disabled={
                            getUserClaims() == "trcAdmin" ? false : true
                          }
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <FormField
                        type="text"
                        label={t("Engine Supplier")}
                        placeholder="ex. 365Engine"
                        value={engineSupplier}
                        onChange={(e) => setEngineSupplier(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Location")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        placeholder="ex. 365Tech Warehouse"
                        options={locListLabel}
                        value={arrivalLocName}
                        onChange={setArrivalLocName}
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETA
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiDatePicker value={ETA} onChange={setETA} />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETB
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiDatePicker value={ETB} onChange={setETB} />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETD
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiDatePicker value={ETD} onChange={setETD} />
                      </SuiBox>
                    </Grid>
                  </Grid>
                </SuiBox>
              </SuiBox>
            ) : (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={0}
              >
                <img
                  src="/loader.gif"
                  alt="AVS Logo"
                  style={{
                    objectFit: "none",
                    height: "160px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
    </SuiBox>
  );
}

export default ProductEdit;
