import { auth, SignUpAuth, providerGoogle } from "./firebase";

//sign up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  SignUpAuth.createUserWithEmailAndPassword(email, password);

//sign in
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

//sign out
export const doSignOut = () => auth.signOut();

//password reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

//password change
export const doPasswordChange = password =>
  auth.currentUser.updatePassword(password);

//google auth
export const signInWithGoogle = async () => await auth.signInWithPopup(providerGoogle);
