/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Wizard application components
import FormField from "components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
import React , { useState } from 'react';
import FormFieldError from "components/FormFieldError";
import SuiDatePickerFormik from "components/SuiDatePickerFormik";

function About({formData}) {
  const [isLoading, setLoading] = useState(false);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { educator, trainee, organisation, trainingdate } = formField;
  const {
    educator: educatorV,
    trainee: traineeV,
    organisation: organisationV,
    trainingdate: trainingdateV,
  } = values;
  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">
          Training 
          </SuiTypography>
        </SuiBox>
        <SuiTypography variant="body2" fontWeight="regular" color="text">
        Create your training record...
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>

            <FormFieldError
              type={educator.type}
              label={educator.label}
              name={educator.name}
              value={educatorV}
              placeholder={educator.placeholder}
              error={errors.educator && touched.educator}
              success={educatorV && !errors.educator}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={trainee.type}
              label={trainee.label}
              name={trainee.name}
              value={traineeV}
              placeholder={trainee.placeholder}
              error={errors.trainee && touched.trainee}
              success={traineeV.length > 0 && !errors.trainee}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={organisation.type}
              label={organisation.label}
              name={organisation.name}
              value={organisationV}
              placeholder={organisation.placeholder}
              error={errors.organisation && touched.organisation}
              success={organisationV.length > 0 && !errors.organisation}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {trainingdate.label}
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
              <SuiDatePickerFormik 
                name={trainingdate.name}
                value={trainingdateV}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default About;
