/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Link } from "@mui/material";
import SuiButton from "components/SuiButton";
import { fbstorage } from "firebase/firebase";

const ViewFile = ({ filePath, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [dataSrc, setDataSrc] = useState(null);
  useEffect(() => {
    if (filePath) {
      setLoading(true);
      fbstorage
        .ref(filePath)
        .getDownloadURL()
        .then((url) => {
          setDataSrc({
            src: url,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [filePath]);

  return (
    <>
      {dataSrc && !loading ? (
        <Link href={dataSrc && dataSrc.src} target="_blank">
          <SuiButton
            variant="gradient"
            buttonColor="info"
            size="small"
            iconOnly
            {...rest}
          >
            <Icon className="" fontSize="small">
              visibility
            </Icon>
          </SuiButton>
        </Link>
      ) : (
        <SuiButton
          variant="gradient"
          buttonColor="info"
          size="small"
          iconOnly
          {...rest}
        >
          <Icon className="" fontSize="small">
            downloading
          </Icon>
        </SuiButton>
      )}
    </>
  );
};

// Setting default values for the props of SuiSelect
ViewFile.defaultProps = {};

// Typechecking props for the SuiSelect
ViewFile.propTypes = {
  filePath: PropTypes.number,
};

export default ViewFile;
