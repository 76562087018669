/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect } from "react";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import LocationUpdate from "components/Location/LocationUpdate";
import pageRoutes from "constants/pageRoutes";

function ProductsList(props) {
  const [loading, setLoading] = useState(false);
  const { id } = props.match.params;

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox my={0}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12} lg={8}>
            <Grid item xs={12} lg={12} mb={3}>
              <LocationUpdate id={id} />
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
