/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiDatePickerFormik from "components/SuiDatePickerFormik";
import SuiSelectFormik from "components/SuiSelectFormik";
import SuiTypography from "components/SuiTypography";

// Page components
import FormFieldError from "components/FormFieldError";

function Address({ formData, staffList, locList, shippingMethodList }) {
  const [staffListLabel, setStaffListLabel] = useState([]);
  const [locListLabel, setLocListLabel] = useState([]);
  const [shippingMethodListLabel, setShippingMethodListLabel] = useState([]);

  // const [operatorsV, setOperatorsV] = useState();
  const { formField, values, errors, touched } = formData;
  const {
    operators,
    shippingMethod,
    arrivalLoc,
    plateNumber,
    engineSupplier,
    eta,
    etb,
    etd,
  } = formField;
  const {
    operators: operatorsV,
    shippingMethod: shippingMethodV,
    arrivalLoc: arrivalLocV,
    engineSupplier: engineSupplierV,
    eta: etaV,
    etb: etbV,
    etd: etdV,
    plateNumber: plateNumberV,
  } = values;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setStaffListLabel(
      staffList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
    setLocListLabel(
      locList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
    setShippingMethodListLabel(
      shippingMethodList.map(({ name: label, ...rest }) => ({
        label,
        ...rest,
      }))
    );
  }, []);

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Delivery Informations")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("Enterinformationsaboutyourdelivery")}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t(operators.label)}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={operators.name}
              value={operatorsV}
              placeholder={operators.placeholder}
              options={staffListLabel}
              size="large"
              isMulti
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={plateNumber.type}
              label={t(plateNumber.label)}
              name={plateNumber.name}
              value={plateNumberV}
              placeholder={plateNumber.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={engineSupplier.type}
              label={t(engineSupplier.label)}
              name={engineSupplier.name}
              value={engineSupplierV}
              placeholder={engineSupplier.placeholder}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t(arrivalLoc.label)}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={arrivalLoc.name}
              value={arrivalLocV}
              placeholder={arrivalLoc.placeholder}
              options={locListLabel}
              isClearable
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {eta.label}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiDatePickerFormik name={eta.name} value={etaV} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {etb.label}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiDatePickerFormik name={etb.name} value={etbV} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {etd.label}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiDatePickerFormik name={etd.name} value={etdV} />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
