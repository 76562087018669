/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import checkbox from "assets/theme/components/form/checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelectFormik from "components/SuiSelectFormik";
import SuiDatePickerFormik from "components/SuiDatePickerFormik";

// Page components
import FormFieldError from "components/FormFieldError";
import FormField from "components/FormField";
import SuiInput from "components/SuiInput";
import { Checkbox } from "@mui/material";
import SuiButton from "components/SuiButton";
import DeleteIcon from "@mui/icons-material/Delete";

function ThingsToDo({ formData }) {
  const { t, i18n } = useTranslation();

  const [inputList, setInputList] = useState([{ InputText: "" }]);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { todo } = formField;
  const { todo: todoV } = values;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setFieldValue("todo", list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { InputText: "" }]);
  };

  console.log(inputList)
  return ( 
    <>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Things To Do")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("Enterinformationsaboutyourtodo")}
        </SuiTypography>
      </SuiBox>

      {
        inputList.length===0?
        inputList.concat(todoV):null,
         inputList.map((x, i) => {
        return (
          <SuiBox mt={2}>
            <Grid container spacing={3}>
              <Grid item display={"flex"} xs={12} sm={12}>
                <FormFieldError
                  name="InputText"
                  placeholder="ex. 365Ship"
                  value={x.InputText}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errors.todo && touched.todo}
                />
                <SuiBox marginLeft={1}>
                  <SuiBox>
                    {inputList.length !== 1 && (
                      <SuiButton
                        variant="gradient"
                        size="small"
                        iconOnly
                        onClick={() => handleRemoveClick(i)}
                        buttonColor="error"
                      >
                        {" "}
                        <DeleteIcon />
                      </SuiButton>
                    )}
                  </SuiBox>
                </SuiBox>
              </Grid>
              <SuiBox
                display="flex"
                marginTop={1}
                marginBottom={1}
                marginLeft={3}
              >
                <SuiBox marginLeft={1}>
                  {inputList.length - 1 === i && (
                    <SuiButton
                      variant="gradient"
                      size="small"
                      iconOnly
                      buttonColor="success"
                      onClick={handleAddClick}
                    >
                      {" "}
                      {t("+")}
                    </SuiButton>
                  )}
                </SuiBox>
              </SuiBox>
            </Grid>
          </SuiBox>
        );
      })}
    </>
  );
}

// typechecking props for Address
ThingsToDo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ThingsToDo;
