/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, boxShadows, transitions, functions, borders, breakpoints }) => {
  const { dark, white, text, transparent } = palette;
  const { regular, navbarBoxShadow } = boxShadows;
  const { rgba, pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    navbar: {
      backgroundColor: ({ transparentNavbar, absolute }) =>
        transparentNavbar || absolute ? "transparent.main" : rgba(white.main, 0.0),
        zIndex: 3,
    },

    defaultNavbar: {
      height:"58px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: ({ transparent }) =>
        transparent ? transparent.main : rgba(white.main, 0.8),
      color: ({ light }) => (light ? white.main : dark.main),
      boxShadow: ({ transparent }) => (transparent ? "none" : regular),
      backdropFilter: ({ transparent }) =>
        transparent ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
      borderRadius: borderRadius.section,
      margin: `${pxToRem(16)} ${pxToRem(24)}`,
      width: `calc(100% - ${pxToRem(48)})`,
      left: 0,
    },
  };
});
