/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Avatar from "@mui/material/Avatar";
import FlatList from "flatlist-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import stringAvatar from "assets/theme/functions/stringAvatar";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import SuiButton from "components/SuiButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import pageRoutes from "constants/pageRoutes";
import borders from "assets/theme/base/borders";

function Teams() {
  const { borderWidth, borderColor } = borders;
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState([]);

  const { t, i18n } = useTranslation();
  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query announcement {
              announcement(order_by: { created_at: desc }) {
                message
                subject
                uid
                id
                users {
                  name
                }
                created_at
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(result.data.announcement);
          setLoading(false);
        });
    }
  };

  const renderItem = (item) => (
    <Card style={{ marginBottom: 10, padding: 3 }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={2}
      >
        <Avatar
          {...stringAvatar({
            name: item.users.name,
            width: 40,
            height: 40,
            fontSize: "1rem",
          })}
        />

        <SuiBox display="flex">
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            px={2}
          >
            <SuiBox lineHeight={1}>
              <SuiTypography
                textTransform="uppercase"
                variant="button"
                textGradient={true}
                textColor="primary"
                fontWeight="medium"
              >
                {item.subject}
              </SuiTypography>
              <SuiBox display="flex" alignItems="center">
                <Icon className="" color="info">
                  timer
                </Icon>
                <SuiBox ml={1}>
                  <SuiTypography
                    textTransform="uppercase"
                    variant="overline"
                    textColor="text"
                    fontWeight="light"
                  >
                    {moment(item.created_at).fromNow()}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" flex="1" position="absolute" right="1.5rem">
            <SuiTypography
              textTransform="capitalize"
              variant="button"
              fontWeight="medium"
            >
              {item.users.name}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}></SuiBox>
      </SuiBox>
      <SuiBox py={2} px={2}>
        <SuiTypography variant="button" textColor="text" fontWeight="medium">
          {item.message}
        </SuiTypography>
      </SuiBox>
    </Card>
  );

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox my={2}>
        <Card>
          <SuiBox
            display={"flex"}
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SuiBox lineHeight={0}>
              <SuiTypography
                variant="h6"
                alignSelf="center"
                fontWeight="bold"
                gutterBottom
                textColor="text"
              >
                {" "}
                {t("Announcement")}
              </SuiTypography>
              <SuiTypography variant="caption" textColor="text">
                {t("You can follow your announcement in here.")}
              </SuiTypography>
            </SuiBox>
            <SuiBox textAlign="center" lineHeight={1}>
              <Link to="/announcement/add" className="decoration-none">
                <SuiButton variant="outlined" buttonColor="info" size="small">
                  <Icon className="">add</Icon>
                  {t("Add Message")}
                </SuiButton>
              </Link>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <FlatList
              list={dataSet}
              renderItem={renderItem}
              renderWhenEmpty={() =>
                loading ? (
                  <Card style={{ marginBottom: "10px" }}>
                    <img
                      src="/loader.gif"
                      alt="TRC Logo"
                      style={{
                        objectFit: "none",
                        height: "160px",
                        width: "100%",
                        borderRadius: "50px",
                      }}
                    />
                  </Card>
                ) : (
                  <Card style={{ marginBottom: "10px", padding: "18px" }}>
                    {t("Announcement not found.")}
                  </Card>
                )
              }
            />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Teams;
