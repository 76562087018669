/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormFieldError from "components/FormFieldError";

function UserInfo({ formData }) {
  const { t, i18n } = useTranslation();

  const { formField, values, errors, touched } = formData;
  const {
    portState,
    name,
    imo,
    agent,
    callSign,
    owner,
    mmsi,
    flag,
    grossTonage,
    kellLand,
  } = formField;
  const {
    name: nameV,
    imo: imoV,
    agent: agentV,
    callSign: callSignV,
    owner: ownerV,
    mmsi: mmsiV,
    flag: flagV,
    // classNo:classNoV,
    grossTonage: grossTonageV,
    kellLand: kellLandV,
    portState: portStateV,
  } = values;

  return (
    <SuiBox>
      <SuiBox lineHeight={0} mb={1.625}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Add Ship")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("EnterNewShipInformation")}
        </SuiTypography>
      </SuiBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormFieldError
            type={name.type}
            label={t(name.label)}
            name={name.name}
            value={nameV}
            placeholder={name.placeholder}
            error={errors.name && touched.name}
            success={nameV.length > 0 && !errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldError
            type={agent.type}
            label={t(agent.label)}
            name={agent.name}
            value={agentV}
            placeholder={agent.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={mmsi.type}
            label={t(mmsi.label)}
            name={mmsi.name}
            value={mmsiV}
            placeholder={mmsi.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={grossTonage.type}
            label={t(grossTonage.label)}
            name={grossTonage.name}
            value={grossTonageV}
            placeholder={grossTonage.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={owner.type}
            label={t(owner.label)}
            name={owner.name}
            value={ownerV}
            placeholder={owner.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={imo.type}
            label={t(imo.label)}
            name={imo.name}
            value={imoV}
            placeholder={imo.placeholder}
            error={errors.imo && touched.imo}
            success={imoV.length > 0 && !errors.imo}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={callSign.type}
            label={t(callSign.label)}
            name={callSign.name}
            value={callSignV}
            placeholder={callSign.placeholder}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={flag.type}
            label={t(flag.label)}
            name={flag.name}
            value={flagV}
            placeholder={flag.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={portState.type}
            label={t(portState.label)}
            name={portState.name}
            value={portStateV}
            placeholder={portState.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormFieldError
            type={kellLand.type}
            label={t(kellLand.label)}
            name={kellLand.name}
            value={kellLandV}
            placeholder={kellLand.placeholder}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
