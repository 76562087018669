/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    locationName: {
      name: "locationName",
      label: "Location Name",
      type: "text",
      placeholder: "ex. 365Ship Maltepe",
      errorMsg: "Location Name Is Required.",
    },
  },
};
