/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components

// @mui material components
import React, { useState, useEffect, Component } from 'react';
import Grid from "@mui/material/Grid";
import { getAccessToken } from "constants/valueStorage";
import { auth } from 'firebase/firebase';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import FormField from "components/FormField";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

import moment from 'moment';


function WorkDetail({ id }) {
  const [loading, setLoading] = useState(true)
  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  }
  const client = new ApolloClient({
    uri: 'https://hasura-trc.365tech.dev/v1/graphql',
    headers,
    cache: new InMemoryCache()
  });

  const [dataSet, setDataSet] = useState(null)
  const history = useHistory();
  const [educator, setEducator] = useState('')
  const [trainee, setTrainee] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [trainingdate, setTrainingDate] = useState('')


  useEffect(() => {
    getDetail()
  }, [auth.currentUser]);

  const getDetail = () => {
    setLoading(true)
    if (auth.currentUser) {
      client
        .query({
          query: gql`
              query vessels_by_pk {
              training_by_pk(id:${id} ) {
                educator
                organisation
                trainee
                training_date
              }
            }
            `
        })
        .then(result => {
          setDataSet(result.data.training_by_pk)
          setEducator(result.data.training_by_pk.educator);
          setTrainee(result.data.training_by_pk.organisation);
          setOrganisation(result.data.training_by_pk.trainee);
          setTrainingDate(result.data.training_by_pk.training_date);
          setLoading(false)
        });
    }
  }




  const sentUpdate = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_vessels_by_pk {
            update_training_by_pk(
              pk_columns: {id: ${id}}, _set: {
                educator: ${JSON.stringify(educator)}, 
                organisation:${JSON.stringify(organisation)}, 
                trainee: ${JSON.stringify(trainee)}, 
                training_date:  ${trainingdate?JSON.stringify(moment(trainingdate).format('YYYY-MM-DD')):null}
              }) {
                id 
              }
            }
          `
        })
        .then(result => {
         
          history.push("/training");
        }).catch(err => {
          console.log(err)
        })
    }
  }



  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h5"
            alignSelf="center"
            fontWeight="bold"
          >
            Training Information
          </SuiTypography>
          <SuiButton onClick={() => sentUpdate()} variant="gradient" buttonColor="info" size="small">
            Save
          </SuiButton>
        </SuiBox>
        {
          (dataSet && !loading) ? (
            <Grid container mt={1} spacing={3}>
              <Grid item xs={12} sm={3}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
                  <FormField type="text" label="Educator" value={educator} onChange={e => setEducator(e.target.value)} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
                  <FormField type="text" label="Trainee" value={trainee} onChange={e => setTrainee(e.target.value)} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SuiBox mb={0.5} ml={0.5} lineHeight={0} width="100%" display="inline-block">
                  <FormField type="text" label="Organisation" value={organisation} onChange={e => setOrganisation(e.target.value)} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SuiBox mb={0.5} ml={0.5} lineHeight={0} width="100%" display="inline-block">
                  <FormField type="date" label="Training Date" value={trainingdate} onChange={e => setTrainingDate(e.target.value)} />
                </SuiBox>
              </Grid>

            </Grid>
          ) : (
            <SuiBox display="flex" justifyContent="center" alignItems="center" p={0}>
              <img src="/loader.gif" alt="TRC Logo" style={{ objectFit: "none", height: "160px", width: "100%", borderRadius: "50px" }} />
            </SuiBox>
          )
        }
      </SuiBox>
    </Card>
  );
}

export default WorkDetail;
