/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "components/OrderSchemas/formAnnouncement";
import { Message } from "@mui/icons-material";

const {
  formField: { message },
} = checkout;

export default [
  Yup.object().shape({
    [message.name]: Yup.string().required(message.errorMsg),
    // [shipName.name]: Yup.string().required(shipName.errorMsg),
    // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    // [repeatPassword.name]: Yup.string().required(repeatPassword.errorMsg).oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
    // [address1.name]: Yup.string().required(address1.errorMsg),
    // [city.name]: Yup.string().required(city.errorMsg),
    // [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
  }),
  Yup.object().shape({
    // [todo]: Yup.array().required(todo.errorMsg)
    // [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];
