/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";

function PastTransactions({ id }) {
  console.log(id, "id");
  const moment = extendMoment(Moment);
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  const getData = () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessels_by_pk($id: Int!) {
              vessels_by_pk(id: $id) {
                vessel_past(where: { status: { _eq: delivered } }) {
                  id
                  created_at
                }
              }
            }
          `,
          variables: {
            id: id,
          },
        })
        .then((result) => {
          setDataSet(result.data.vessels_by_pk.vessel_past);
          setLoading(false);
        });
    }
    setLoading(false);
  };
  
  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Past Transactions")}
          </SuiTypography>
        </SuiBox>
        {dataSet.length > 0 && !loading ? (
          <SuiBox>
            <SuiBox>
              {dataSet.length > 0 && !loading ? (
                dataSet.map((item, key) => (
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <SuiBox mt={1} lineHeight={0}>
                        <SuiTypography
                          component="label"
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {key + 1}.
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mt={1} lineHeight={0}>
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          TRC-{item.id && item.id}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mt={1} lineHeight={0}>
                        <SuiTypography
                          component="label"
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {item.created_at &&
                            moment(item.created_at, "YYYY-MM-DD").format(
                              "DD.MM.YY"
                            )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={0}
                >
                  <img
                    src="/loader.gif"
                    alt="TRC Logo"
                    style={{
                      objectFit: "none",
                      height: "160px",
                      width: "100%",
                      borderRadius: "50px",
                    }}
                  />
                </SuiBox>
              )}
            </SuiBox>
          </SuiBox>
        ) : dataSet.length == 0 && !loading ? (
          <SuiBox
            display="flex"
            mt={2}
            mb={2}
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Past Transactions not found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default PastTransactions;
