/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SuiBadge from "components/SuiBadge";
import PropTypes from "prop-types";
import ActionCellCertificate from "components/ActionCell/certificate";
import Moment from "moment";
import { extendMoment } from "moment-range";

const remainingCertError = (value) => (
  <SuiBadge
    variant="contained"
    color="success"
    size="extra-small"
    badgeContent={value + " Certificate"}
    container
  />
);

const remainingCert = (value) => (
  <SuiBadge
    variant="contained"
    color="error"
    size="extra-small"
    badgeContent={value + " Certificate"}
    container
  />
);
const remainingBattError = (value) => (
  <SuiBadge
    variant="contained"
    color="success"
    size="extra-small"
    badgeContent={value + " Certificate "}
    container
  />
);

const remainingBatt = (value) => (
  <SuiBadge
    variant="contained"
    color="error"
    size="extra-small"
    badgeContent={value + " Certificate"}
    container
  />
);
function certificateList({ dataset, dataset2 }) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item, index) => {
      data.push({
        vesselName: item.name,
        cert45: item.vessel_certificates_aggregate.aggregate.count,
        batt45: dataset2[index].vessel_certificates_aggregate.aggregate.count,
        action: <ActionCellCertificate id={item.id} />,
      });
    });
  }

  return {
    columns: [
      {
        Header: "Vessel Name",
        name: "vesselName",
        width: "30%",
        accessor: "vesselName",
      },
      {
        Header: "CERTIFICATE: 0-45 Day",
        width: "35%",
        maxWidth: "120px",
        name: "cert45",
        accessor: "cert45",
        isSorted: true,
        useSortBy: false,
        Cell: ({ value }) =>
          value <= 0
            ? remainingCertError(value)
            : value !== "null"
            ? remainingCert(value)
            : null,
      },
      {
        Header: "BATTERY : 0-45 Day",
        width: "35%",
        maxWidth: "120px",
        name: "batt45",
        accessor: "batt45",
        isSorted: true,
        useSortBy: false,
        Cell: ({ value }) =>
          value <= 0
            ? remainingBattError(value)
            : value !== "null"
            ? remainingBatt(value)
            : null,
      },
      { Header: "Process", name: "action", width: "100px", accessor: "action" },
    ],

    rows: [...data],
  };
}
// Setting default values for the props of DashboardNavbar
certificateList.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
certificateList.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default certificateList;
