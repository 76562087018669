/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "ex. sefa umut",
      errorMsg: "Please enter your name",
    },
    nationality: {
      name: "nationality",
      label: "Nationality",
      type: "text",
      placeholder: "ex. Turkey",
    },
    profession: {
      name: "profession",
      label: "Profession",
      type: "text",
      placeholder: "ex. software developer",
    },
    email: {
      name: "email",
      label: "email",
      type: "text",
      placeholder: "ex. sefaumut@gmail.com",
      errorMsg: "Please enter your email",
      invalidMsg: "Please enter a valid email",
    },
    hesCode: {
      name: "hesCode",
      label: "Hes Code",
      type: "text",
      invalidMsg: "Please enter a valid Hes Code",
      placeholder: "ex. A1B2-3245-12",
    },
    userType: {
      name: "userType",
      label: "User Type",
      type: "text",
      placeholder: "ex. Office Worker",
      errorMsg: "Please enter your user type",
      invalidMsg: "Please enter a valid user type",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "ex. *******",
      errorMsg: "Please enter your password",
      invalidMsg: "Password must be at least 6 characters",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      placeholder: "ex. *******",
      errorMsg: "Please re-enter your password",
      invalidMsg: "Passwords did not match",
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date Of Birth",
      errorMsg: "Wrong date format",
      invalidMsg: "Please enter a valid date",
    },
  },
};
