/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelectFormik from "components/SuiSelectFormik";

// Page components
import FormFieldError from "components/FormFieldError";

function UserInfo({ formData }) {
  const { t, i18n } = useTranslation();

  const { formField, values, errors, touched } = formData;
  const { locationName } = formField;
  const {
    locationName: locationNameV,
  } = values;

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
      <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Add Location")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("EnterNewLocationInformation")}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={locationName.type}
              label={t(locationName.label)}
              name={locationName.name}
              value={locationNameV}
              placeholder={locationName.placeholder}
              error={errors.locationName && touched.locationName}
              success={locationNameV.length > 0 && !errors.locationName}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
