import React from "react";
import { Link } from "react-router-dom";

const MainBanner = () => (
    <div style={{display:"flex",alignSelf: "center"}}>
        
            <Link to="/">
                <img src="/Katman.png" alt="AVS Logo" height={"45px"}  />
            </Link>
       
    </div>
);

export default MainBanner;
