/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    trainee: {
      name: "trainee",
      label: "trainee",
      type: "text",
      placeholder: "ex. trainee",
      errorMsg: "Trainee  is required.",
    },
    educator: {
      name: "educator",
      label: "educator",
      type: "text",
      placeholder: "ex. educator",
      errorMsg: "Educator is required.",
    },
    organisation: {
      name: "organisation",
      label: "organisation",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "educator is required.",
    },
    trainingdate: {
      name: "trainingdate",
      label: "trainingdate",
      type: "date",
      placeholder: "ex. dd.mm.yyyy",
      errorMsg: "Training date is required.",
    },
  
  },
};
