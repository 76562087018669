/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// formik components
import { ErrorMessage, useField } from "formik";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for SuiSelectFormik
import { styles, animation } from "../SuiSelectFormik/styles";

const SuiSelectFormik = forwardRef(
  ({ size, error, success, name, label, ...rest }, ref) => {
    const classes = animation();
    const [field, meta, { setValue, setTouched }] = useField(name);

    const onChange = (value) => {
      setValue(value);
    };

    return (
      <>
        {label && (
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {label}
            </SuiTypography>
          </SuiBox>
        )}
        <Select
          {...rest}
          ref={ref}
          name={name}
          onChange={onChange}
          styles={styles(size, error, success)}
          className={classes.menu}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.light.main,
              primary: colors.light.main,
            },
          })}
        />
        <SuiBox mt={0.75}>
          <SuiTypography component="div" variant="caption" textColor="error">
            <ErrorMessage name={name} />
          </SuiTypography>
        </SuiBox>
      </>
    );
  }
);

// Setting default values for the props of SuiSelectFormik
SuiSelectFormik.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  label: "",
};

// Typechecking props for the SuiSelectFormik
SuiSelectFormik.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  label: PropTypes.string,
};

export default SuiSelectFormik;
