/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import moment from "moment";
import { useTranslation } from "react-i18next";
import pageRoutes from "constants/pageRoutes";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiDateRangePicker from "components/SuiDateRangePicker";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import ComplexReportsDoughnutChart from "components/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import complexReportsDoughnutChartData from "components/data/complexReportsDoughnutChartData";
import reportsBarChartData from "components/data/reportsBarChartData";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Social from "components/dashboard/Social";
import Pages from "components/dashboard/Pages";

function Analytics() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [countTotal, setCountTotal] = useState(0);
  const [cancelWork, setCancelWork] = useState(0);
  const [barChartData, setBarChartData] = useState(null);
  const [complexData, setComplexData] = useState(null);
  const [social, setSocial] = useState(null);
  const [logs, setLogs] = useState([]);
  const [logsWeek, setLogsWeek] = useState([]);
  const current = new Date();
  const startDate = moment().add(-30, "days");
  const currentDate = moment();
  const [date, setDate] = useState([startDate, currentDate]);
  const handleSetStartDate = (newDate) => {
    setDate(newDate);
  };

  console.log(date, "date");

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser && date[1] && date[0]) {
      client
        .query({
          query: gql`
            query ship_infos {
              ship_infos(where: {created_at: {_gte: ${JSON.stringify(
                date[0]
              )}, _lte: ${JSON.stringify(date[1])}}, status: {_eq: canceled}}){
                    ship_infos_status {
                      status_desc
                    }
                  }
                ship_infos_aggregate(where: {created_at: {_gte: ${JSON.stringify(
                  date[0]
                )}, _lte: ${JSON.stringify(
            date[1]
          )}}, status: {_eq: delivered}}){
                  aggregate {
                    max { order_total }
                    sum { order_total } 
                    avg { order_total }
                    count
                    }
                  }
                }
          `,
        })
        .then((result) => {
          result.data.ship_infos_aggregate.aggregate.count
            ? setCountTotal(result.data.ship_infos_aggregate.aggregate.count)
            : setCountTotal(0);
          result.data.ship_infos.length
            ? setCancelWork(result.data.ship_infos.length)
            : setCancelWork(0);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth.currentUser, date]);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser && date[1] && date[0]) {
      client
        .query({
          query: gql`
          query ship_infos {
              ship_infos_aggregate(where: {delivery_date: {_gte: ${JSON.stringify(
                moment().startOf("month")
              )}, _lte: ${JSON.stringify(
            moment().endOf("month")
          )}} ,order_currencies: {_eq: USD}, status: {_eq: delivered}}){
                aggregate {
                  sum { order_total } 
                  }
                }
              }
        `,
        })
        .then((result1) => {
          client
            .query({
              query: gql`
          query ship_infos {
              ship_infos_aggregate(where: {delivery_date: {_gte: ${JSON.stringify(
                moment().add(-1, "month").startOf("month")
              )}, _lte: ${JSON.stringify(
                moment().add(-1, "month").endOf("month")
              )}}  ,order_currencies: {_eq: USD}, status: {_eq: delivered}}){
                aggregate {
                  sum { order_total } 
                  }
                }
              }
        `,
            })
            .then((result2) => {
              client
                .query({
                  query: gql`
            query ship_infos {
                ship_infos_aggregate(where: {delivery_date: {_gte: ${JSON.stringify(
                  moment().add(-2, "month").startOf("month")
                )}, _lte: ${JSON.stringify(
                    moment().add(-2, "month").endOf("month")
                  )}} ,order_currencies: {_eq: USD}, status: {_eq: delivered}}){
                  aggregate {
                    sum { order_total } 
                    }
                  }
                }
          `,
                })
                .then((result3) => {
                  client
                    .query({
                      query: gql`
              query ship_infos {
                  ship_infos_aggregate(where: {delivery_date: {_gte: ${JSON.stringify(
                    moment().add(-3, "month").startOf("month")
                  )}, _lte: ${JSON.stringify(
                        moment().add(-3, "month").endOf("month")
                      )}} ,order_currencies: {_eq: USD}, status: {_eq: delivered}}){
                    aggregate {
                      sum { order_total } 
                      }
                    }
                  }
            `,
                    })
                    .then((result4) => {
                      setBarChartData(
                        reportsBarChartData({
                          dataset1:
                            result1.data.ship_infos_aggregate.aggregate.sum,
                          dataset2:
                            result2.data.ship_infos_aggregate.aggregate.sum,
                          dataset3:
                            result3.data.ship_infos_aggregate.aggregate.sum,
                          dataset4:
                            result4.data.ship_infos_aggregate.aggregate.sum,
                        })
                      );
                    });
                });
            });
        });
    }
    setLoading(false);
  }, [auth.currentUser, date]);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos_aggregate {
              ship_infos_aggregate(
                order_by: { arrival_loc_name: desc }
                where: {
                  locationByArrivalLocName: { location: { _is_null: false } }
                }
              ) {
                nodes {
                  arrival_loc_name
                  locationByArrivalLocName {
                    location_full
                  }
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.ship_infos_aggregate.nodes.reduce(
            (a, c) => (
              (a[c.locationByArrivalLocName.location_full] =
                (a[c.locationByArrivalLocName.location_full] || 0) + 1),
              a
            ),
            Object.create(null)
          );
          const mostPorts = result.data.ship_infos_aggregate.nodes.reduce(
            (a, c) => (
              (a[c.locationByArrivalLocName.location_full] =
                (a[c.locationByArrivalLocName.location_full] || 0) + 1),
              a
            ),
            Object.create(null)
          );
          var sortable = [];
          for (var data in mostPorts) {
            sortable.push([data, mostPorts[data]]);
          }
          sortable.sort(function (a, b) {
            return a[1] - b[1];
          });
          const reversedPorts = sortable.reverse();

          setComplexData(
            complexReportsDoughnutChartData({
              reversedPorts: reversedPorts,
            })
          );
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      // ship_infos(where: {assigned_jobs: {users: {name: {_is_null: false}}}, created_at: {_gte: ${JSON.stringify(moment().startOf('month'))}, _lte: ${JSON.stringify(moment().endOf('month'))}}}) {
      client
        .query({
          query: gql`
            query ship_infos {
              ship_infos(
                where: {
                  assigned_jobs: { users: { name: { _is_null: false } } }
                }
              ) {
                assigned_jobs {
                  users {
                    name
                  }
                }
              }
            }
          `,
        })
        .then((result) => {
          // const datalist
          // çalışan versiyon const mostPerson=result.data.ship_infos.reduce((a, c) => (a[c.assigned_jobs[0].users.name] = (a[c.assigned_jobs[0].users.name] || 0) + 1, a), Object.create(null))
          var personels = [];
          result.data.ship_infos.forEach((element) => {
            element.assigned_jobs.forEach((elementTwo) => {
              personels.push(elementTwo);
            });
          });
          const mostPerson = personels.reduce(
            (a, c) => ((a[c.users.name] = (a[c.users.name] || 0) + 1), a),
            Object.create(null)
          );
          var sortable = [];
          for (var data in mostPerson) {
            sortable.push([data, mostPerson[data]]);
          }
          sortable.sort(function (a, b) {
            return a[1] - b[1];
          });
          const reversedPerson = sortable.reverse();
          setSocial(reversedPerson);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query logs {
              logs(limit: 10, order_by: { created_at: desc }) {
                user_name {
                  name
                }
                order_status {
                  status_desc
                }
                customs_status {
                  customs_status_desc
                }
                job_id
                log_type
                log_title
                log_desc
                log_status
                created_at
              }
            }
          `,
        })
        .then((result) => {
          result.data.logs && setLogs(result.data.logs);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query logs {
              logs(
                where: {_or: [
                  { created_at: {
                    _gte: ${JSON.stringify(
                      moment()
                        .add(-1, "week")
                        .isoWeekday(6)
                        .format("YYYY-MM-DD")
                    )},
                    _lte: ${JSON.stringify(
                      moment()
                        .add(-1, "week")
                        .isoWeekday(7)
                        .format("YYYY-MM-DD")
                    )},
                  }
                },
                {
                  created_at: {
                    _gte: ${JSON.stringify(
                      moment()
                        .add(-2, "week")
                        .isoWeekday(6)
                        .format("YYYY-MM-DD")
                    )},
                    _lte: ${JSON.stringify(
                      moment()
                        .add(-2, "week")
                        .isoWeekday(7)
                        .format("YYYY-MM-DD")
                    )},
                  }
                },
                {
                  created_at: {
                    _gte: ${JSON.stringify(
                      moment()
                        .add(-3, "week")
                        .isoWeekday(6)
                        .format("YYYY-MM-DD")
                    )},
                    _lte: ${JSON.stringify(
                      moment()
                        .add(-3, "week")
                        .isoWeekday(7)
                        .format("YYYY-MM-DD")
                    )},
                  }
                },
                {
                  created_at: {
                    _gte: ${JSON.stringify(
                      moment()
                        .add(-4, "week")
                        .isoWeekday(6)
                        .format("YYYY-MM-DD")
                    )},
                    _lte: ${JSON.stringify(
                      moment()
                        .add(-4, "week")
                        .isoWeekday(7)
                        .format("YYYY-MM-DD")
                    )},
                  }
                }
              ]}
              ) {
                job_id
                log_status
                log_title
                  created_at
                user_name {
                  name
                  uid
                }
              }
            }
          `,
        })
        .then((result) => {
          var personels = [];
          result.data.logs.forEach((element) => {
            personels.push(element);
          });

          const mostPerson = personels.reduce(
            (a, c) => (
              (a[c.user_name.name] = (a[c.user_name.name] || 0) + 1), a
            ),
            Object.create(null)
          );

          var sortable = [];
          for (var data in mostPerson) {
            sortable.push([data, mostPerson[data]]);
          }

          sortable.sort(function (a, b) {
            return a[1] - b[1];
          });
          const reversedPerson = sortable.reverse();

          setLogsWeek(reversedPerson);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox py={3}>
        <SuiBox display="flex" justifyContent="flex-end" mb={3}>
          <Card>
            <SuiBox p={3}>
              <SuiDateRangePicker value={date} onChange={handleSetStartDate} />
            </SuiBox>
          </Card>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              <MiniStatisticsCard
                title={{
                  text: t("TotalNumberofDeliveries"),
                  fontWeight: "medium",
                }}
                count={countTotal}
                percentage={{ color: "success" }}
                icon={{ color: "dark", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <MiniStatisticsCard
                title={{ text: t("Canceled Orders"), fontWeight: "medium" }}
                count={cancelWork}
                percentage={{ color: "success" }}
                icon={{ color: "dark", component: "delete" }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              {logsWeek && (
                <Social
                  title={t("Operator Weekend Number of Orders")}
                  dataSocial={logsWeek}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              {complexData && (
                <ComplexReportsDoughnutChart
                  title={t("Top Business Ports")}
                  chart={complexData}
                  tooltip={t("import1")}
                  action={{
                    type: "internal",
                    route: "/order/active",
                    color: "secondary",
                    label: t("See All"),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              {social && (
                <Social title={t("Operatorordercount")} dataSocial={social} />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Pages chart={logs} />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
