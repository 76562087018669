/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    message: {
      name: "message",
      label: "Message",
      placeholder: "N/A",
      errorMsg: "Message is required.",
    },
    subject: {
      name: "subject",
      label: "Subject",
      placeholder: "N/A",
      //errorMsg: "Todo is required.",
    },
  },
};
