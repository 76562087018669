import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './index.css';
import "./i18n";
import { getAccessToken, setAccessToken } from "constants/valueStorage";
import reportWebVitals from './reportWebVitals';
// import * as Sentry from "@sentry/react";
// import { Integrations as TracingIntegrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://051929d368ab4404b6be328ad7e3b02d@o1036107.ingest.sentry.io/6106185",
//   release: process.env.REACT_APP_VERSION,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
import { SoftUIControllerProvider } from "context";

ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
