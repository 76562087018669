/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import moment from "moment";
import { t } from "i18next";

function dataTableData({ dataset }) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item) => {
      data.push({
        plateNumber: item.vehicles.plate_number,
        startDate: moment(item.start_date).format("LLLL"),
        endDate: moment(item.end_date).format("LLLL"),
        userName: item.vehicle_name.name,
        // action: <ActionCellLocation id={item.id} />,
      });
    });
  }

  return {
    columns: [
      {
        Header: t("PlateNumber"),
        name: "plateNumber",
        accessor: "plateNumber",
      },
      {
        Header: t("StartDate"),
        name: "startDate",
        accessor: "startDate",
      },
      {
        Header: t("EndDate"),
        name: "endDate",
        accessor: "endDate",
      },
      {
        Header: t("UserName"),
        name: "userName",
        accessor: "userName",
      },
    ],

    rows: [...data],
  };
}
// Setting default values for the props of DashboardNavbar
dataTableData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
dataTableData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default dataTableData;
