/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import {
  ApolloClient, gql, InMemoryCache
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in

// @mui material components
import Check from "@mui/icons-material/Check";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
// Soft UI Dashboard PRO React components
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import Footer from "components/FooterTheme";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Swal from "sweetalert2";
// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "constants/pageRoutes";

// NewUser page components
import DeliveryInfo from "components/FormPage/DeliveryInfo";
import Note from "components/FormPage/Note";
import OrderInfo from "components/FormPage/OrderInfo";
import ThingsTodo from "components/FormPage/ThingsTodo";
import SuiTypography from "components/SuiTypography";
// NewUser layout schemas for form and form feilds
import Dropzone from "components/Dropzone";
import form from "components/OrderSchemas/form";
import initialValues from "components/OrderSchemas/initialValues";
import validations from "components/OrderSchemas/validations";
import { useTranslation } from "react-i18next";

function getSteps() {
  return [
    "Order Informations",
    "Delivery Informations",
    "Things To Do",
    "Notes",
  ];
}

function ProductsList() {
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [locList, setLocList] = useState([]);
  const [shippingMethodList, setShippingMethodList] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const anilBey = "ML6DuCNBhGhISeWb5t9ABeFyS9r2";

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <OrderInfo formData={formData} />;
      case 1:
        return (
          <DeliveryInfo
            formData={formData}
            staffList={staffList}
            locList={locList}
            shippingMethodList={shippingMethodList}
          />
        );
      case 2:
        return <ThingsTodo formData={formData} />;
      case 3:
        return <Note formData={formData} />;
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [file, setFile] = useState(null);
  const [savedId, setSavedId] = useState(null);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    getOpUsers();
    getlocs();
    getShippingMethod();
  }, []);

  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.flypxl.com/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  const getOpUsers = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query users {
              users(
                where: { user_type: { _eq: operator }, status: { _eq: true } }
              ) {
                name
                uid
              }
            }
          `,
        })
        .then((result) => {
          var users = [];
          result.data.users.map((user) =>
            users.push({ value: user.uid, name: user.name })
          );
          setStaffList(users);
        });
    }
  };

  const getlocs = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query locations {
              locations(order_by: { location: asc }) {
                location
                location_full
              }
            }
          `,
        })
        .then((result) => {
          var locs = [];
          result.data.locations.map((loc) =>
            locs.push({ value: loc.location, name: loc.location_full })
          );
          setLocList(locs);
        });
    }
  };

  const getShippingMethod = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query shipping_method {
              shipping_method {
                method
                method_desc
              }
            }
          `,
        })
        .then((result) => {
          var sm = [];
          result.data.shipping_method.map((data) =>
            sm.push({ value: data.method, name: data.method_desc })
          );
          setShippingMethodList(sm);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    Swal.fire(t("Successfully added!"), " ", "success");
    setSavedId(null);
  };

  const submitForm = async (values, actions) => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
        mutation insert_ship_infos_one {
          insert_ship_infos_one(object: {
            imo: ${JSON.stringify(values.imo.toString())},
            shipname: ${JSON.stringify(values.shipName)},
            agent: ${values.agent ? JSON.stringify(values.agent) : null},
            company_name: ${
              values.company ? JSON.stringify(values.company) : null
            },
            engine_supplier: ${
              values.engineSupplier
                ? JSON.stringify(values.engineSupplier)
                : null
            },
            order_total: ${values.price ? JSON.stringify(values.price) : null},
            order_currencies: ${values.currency ? values.currency.value : null},
            arrival_loc_name: ${
              values.arrivalLoc ? values.arrivalLoc.value : null
            },
            shipping_method: ${
              values.shippingMethod ? values.shippingMethod.value : null
            },
            ETD: ${values.etd ? JSON.stringify(values.etd) : null},
            ETA: ${values.eta ? JSON.stringify(values.eta) : null},
            ETB: ${values.etb ? JSON.stringify(values.etb) : null},
            plate_number:${
              values.plateNumber ? JSON.stringify(values.plateNumber) : null
            },
            UserUID: ${JSON.stringify(uid)}
            status: waiting
            customs_status: notstarted
            }) {
            id
          }
        }
      `,
        })
        .then((result) => {
          if (values.note) {
            client
              .mutate({
                mutation: gql`
                mutation insert_notes_one {
                  insert_notes_one(object: { UserID: ${JSON.stringify(
                    uid
                  )}, note: ${JSON.stringify(values.note)}, infoID: ${
                  result.data.insert_ship_infos_one.id
                } }) {
                    id
                  }
                  insert_logs_one(object: {log_type: "order", log_status: waiting, job_id: ${
                    result.data.insert_ship_infos_one.id
                  }, user_uid: ${JSON.stringify(uid)}}) {
                    id
                  }
                }
              `,
              })
              .then((resultNote) => {})
              .catch((err) => {
                console.log(err);
              });
          } else {
            client
              .mutate({
                mutation: gql`
                mutation insert_logs_one {
                  insert_logs_one(object: {log_type: "order", log_status: waiting, job_id: ${
                    result.data.insert_ship_infos_one.id
                  }, user_uid: ${JSON.stringify(uid)}}) {
                    id
                  }
                }
              `,
              })
              .then((resultNote) => {
                setOpen(true);
                setSavedId(result.data.insert_ship_infos_one.id);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          values.operators &&
            values.operators.map((operator) => {
              const notfDesc =
                values.shipName +
                " named ship has been assigned to you with Imo Number " +
                values.imo;
              client
                .mutate({
                  mutation: gql`
              mutation insert_assigned_jobs {
                insert_assigned_jobs(objects:
                  {uid: ${JSON.stringify(operator.value)}, job_id: ${
                    result.data.insert_ship_infos_one.id
                  }, job_type: "order"}
                ) { returning {
                  id
                }
              }
              insert_fcm_notifications_one(object: {title: "Yeni iş atandı", desc: "${notfDesc}", 
              useruid: ${JSON.stringify(operator.value)}
                  , status: ${operator ? "waiting" : "nouser"}, type: ${
                    operator ? "user" : "nouser"
                  }}) {
                id
              }
            }
            `,
                })
                .then((result) => {
                //  console.log(result.data.insert_ship_infos_one.id);
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          const notfDesc =
            values.shipName +
            " named ship has been assigned to you with Imo Number " +
            values.imo;
          client
            .mutate({
              mutation: gql`
            mutation insert_fcm_notifications_one {
              insert_fcm_notifications_one(object: {title: "Yeni iş atandı", desc: "${notfDesc}",
              useruid: ${JSON.stringify(anilBey)}
                , status: waiting, type: user}) { 
                id
              }
            }
            `,
            })
            .then((result) => {
            })
            .catch((error) => {
              console.log(error);
            });

          values.todo &&
            values.todo.map((todoV) => {
              client
                .mutate({
                  mutation: gql`
              mutation insert_todo_list_one {
                insert_todo_list_one(object:
                  {title: ${JSON.stringify(todoV.InputText)}, ship_info_id: ${
                    result.data.insert_ship_infos_one.id
                  }}
                ) {
                id
              }
            }
            `,
                })
                .then((result) => {
                  history.push(
                    "/order/detail/" + result.data.insert_ship_infos_one.id
                  );
                })
                .catch((error) => {
                  console.log(error);
                });
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .then((result) => {
          if (values.note) {
            client
              .mutate({
                mutation: gql`
                mutation insert_notes_one {
                  insert_notes_one(object: { UserID: ${JSON.stringify(
                    uid
                  )}, note: ${JSON.stringify(values.note)}, infoID: ${
                  result.data.insert_ship_infos_one.id
                } }) {
                    id
                  }
                  insert_logs_one(object: {log_type: "order", log_status: waiting, job_id: ${
                    result.data.insert_ship_infos_one.id
                  }, user_uid: ${JSON.stringify(uid)}}) {
                    id
                  }
                }
              `,
              })
              .then((resultNote) => {
                history.push(
                  "/order/detail/" + result.data.insert_ship_infos_one.id
                );
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            client
              .mutate({
                mutation: gql`
            mutation insert_logs_one {
              insert_logs_one(object: {log_type: "order", log_status: waiting, job_id: ${
                result.data.insert_ship_infos_one.id
              }, user_uid: ${JSON.stringify(uid)}}) {
                id
              }
            }
          `,
              })
              .then((resultNote) => {
                history.push(
                  "/order/detail/" + result.data.insert_ship_infos_one.id
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
          values.operators &&
            values.operators.map((operator) => {
              const notfDesc =
                values.shipName +
                t(" named ship has been assigned to you with Imo Number ") +
                values.imo;
              client
                .mutate({
                  mutation: gql`
            mutation insert_assigned_jobs {
              insert_assigned_jobs(objects:
                {uid: ${JSON.stringify(operator.value)}, job_id: ${
                    result.data.insert_ship_infos_one.id
                  }, job_type: "order"}
              ) { returning {
                id
              }
            }
            insert_fcm_notifications_one(object: {title: "Yeni iş atandı", desc: "${notfDesc}", useruid: ${JSON.stringify(
                    operator.value
                  )}, status: ${operator ? "waiting" : "nouser"}, type: ${
                    operator ? "user" : "nouser"
                  }}) {
              id
            }
          }
          `,
                })
                .then((result) => {
                })
                .catch((error) => {
                  console.log(error);
                });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setLoading(false);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);

    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 6px)",
      right: "calc(50% + 6px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    zIndex: 2,
    ...(ownerState.active && {
      color: theme.palette.success.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#ffffff",
      zIndex: 2,
      fontSize: 18,
      backgroundColor: theme.palette.success.main,
      borderRadius: "50%",
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Stepper
              activeStep={activeStep}
              connector={<QontoConnector />}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="overflow-visible h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          setFieldValue,
                          errors,
                        })}
                        <SuiBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <SuiBox />
                          ) : (
                            <SuiButton
                              variant="gradient"
                              buttonColor="light"
                              onClick={handleBack}
                            >
                              {t("Back")}
                            </SuiButton>
                          )}
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            buttonColor="dark"
                          >
                            {isLastStep ? t("add") : t("next")}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          spacing={3}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          }}
        >
          <Card>
            <SuiBox p={3}>
              <Grid container display={"flex"} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SuiBox ml={3} display="flex" justifyContent="space-between">
                    {/* <SuiTypography variant="h5" fontWeight="bold">
                      {t("File Add")}
                    </SuiTypography> */}
                    <SuiTypography variant="h5" fontWeight="bold">
                      {t("Dosya Eklemek İster misiniz?")}
                    </SuiTypography>
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="small"
                      circular
                      iconOnly
                      onClick={handleClose}
                    >
                      <Icon className="material-icons-round" fontSize="large">
                        close
                      </Icon>
                    </SuiButton>
                  </SuiBox>
                  {savedId && (
                    <Dropzone
                      jobId={savedId}
                      type={"orderother"}
                      returnValue={setFile}
                    />
                  )}
                </Grid>
                <SuiBox mt={3} ml={3} display="flex">
                  <SuiBox mr={2}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={handleClose}
                    >
                      {t("Save")}
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="warning"
                    onClick={handleClose}
                  >
                    {t("Cancel")}
                  </SuiButton>
                </SuiBox>
              </Grid>
            </SuiBox>
          </Card>
        </Grid>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
