/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FormFieldError from "components/FormFieldError";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { auth } from "../../../firebase/firebase";
import { getAccessToken } from "constants/valueStorage";
import { useTranslation } from "react-i18next";

function UserInfo({ formData }) {
  const { t, i18n } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { imo, shipName, agent, company, price, currency } = formField;
  const {
    imo: imoV,
    shipName: shipNameV,
    agent: agentV,
    company: companyV,
    price: priceV,
    currency: currencyV,
  } = values;

  const getImo = () => {
    setLoading(true);

    const userToken = getAccessToken();
    const uid = auth.currentUser.uid;
    {
    }
    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const client = new ApolloClient({
      uri: "https://hasura-trc.365tech.dev/v1/graphql",
      headers,
      cache: new InMemoryCache(),
    });

    client
      .query({
        query: gql`
          query vessels {
            vessels(where: {imo: {_eq: ${imoV}}}, limit: 1) {
              id
              imo
              agent
              owner
              name
            }
          }
        `,
      })
      .then((result) => {
        result.data.vessels[0].name &&
          setFieldValue("shipName", result.data.vessels[0].name);
        result.data.vessels[0].agent &&
          setFieldValue("agent", result.data.vessels[0].agent);
        result.data.vessels[0].owner &&
          setFieldValue("company", result.data.vessels[0].owner);
      })
      .catch((e) => {
        console.log(e);
        alert("Failed to fetch data.");
      });
    setLoading(false);
  };

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("OrderInformations")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("Enterinformationsaboutyourorder")}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={imo.type}
              label={imo.label}
              name={t(imo.name)}
              value={imoV}
              placeholder={imo.placeholder}
            />
            <SuiButton
              variant="gradient"
              buttonColor="info"
              onClick={imoV && !errors.imo && !isLoading ? getImo : () => {}}
              disabled={isLoading}
            >
              <Icon className="material-icons-round">search</Icon>
              {t("Fetch")}
            </SuiButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={shipName.type}
              label={t(shipName.label)}
              name={shipName.name}
              value={shipNameV}
              placeholder={shipName.placeholder}
              error={errors.shipName && touched.shipName}
              success={shipNameV.length > 0 && !errors.shipName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={agent.type}
              label={t(agent.label)}
              name={agent.name}
              value={agentV}
              placeholder={agent.placeholder}
              success={agentV.length > 0 && !errors.agent}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={company.type}
              label={t(company.label)}
              name={company.name}
              value={companyV}
              placeholder={company.placeholder}
              success={companyV.length > 0 && !errors.company}
            />
          </Grid>
        </Grid>
        {/*  <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={price.type}
              label={t(price.label)}
              name={price.name}
              value={priceV}
              placeholder={price.placeholder}
              success={priceV && !errors.price}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t(currency.label)}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={currency.name}
              value={currencyV}
              placeholder={currency.placeholder}
              options={[
                { value: "USD", label: "$ USD" },
                { value: "Euro", label: "€ Euro" },
                { value: "TL", label: "₺ TL" },
                { value: "Pound", label: "£ Pound" },
              ]}
              isClearable
            />
          </Grid> 
        </Grid>*/}
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
