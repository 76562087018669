/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components

import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import Equipments from "components/data/Equipments";
import DataTable from "components/Tables/DataTable";
import { useTranslation } from "react-i18next";

function Equipment({ id }) {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
            query vessel_equipments {
              vessel_equipments(where: {vessel: {id: {_eq: ${id}}}}) {
                name
                manufacter
                type
                serial_no
                id
                battery_expiry_date
                vessel {
                  id
                }
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(
            Equipments({
              dataset: result.data.vessel_equipments,
            })
          );

          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser, dataSet]);

  const DataTableStyle = {
    overflowY: "scroll",
    width: "auto",
    height: "550px",
    position: "relative",
  };

  return (
    <Card>
      <Grid item ml={3} mr={3} marginTop={2}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Equipment List")}
          </SuiTypography>
        </SuiBox>
        {dataSet?.rows?.length > 0 ? (
          <SuiBox height={600}>
            <SuiBox mt={1} style={DataTableStyle}>
              {dataSet && !loading ? (
                <DataTable
                  table={dataSet}
                  entriesPerPage={{
                    defaultValue: 16,
                    entries: [16, 32, 64],
                  }}
                  canSearch
                />
              ) : (
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={0}
                >
                  <img
                    src="/loader.gif"
                    alt="TRC Logo"
                    style={{
                      objectFit: "none",
                      height: "160px",
                      width: "100%",
                      borderRadius: "50px",
                    }}
                  />
                </SuiBox>
              )}
            </SuiBox>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            mt={2}
            mb={2}
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("No Equipment Found.")}
            </SuiTypography>
          </SuiBox>
        )}
      </Grid>
    </Card>
  );
}

export default Equipment;
