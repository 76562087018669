/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import PagesHeaderCell from "components/dashboard/PagesHeaderCell";
import PagesBodyCell from "components/dashboard/PagesBodyCell";
import { useTranslation } from "react-i18next";

function PastTransactions({ id }) {
  const moment = extendMoment(Moment);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState("");
  const { t } = useTranslation();
  const userToken = getAccessToken();

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
        query users_by_pk {
          users_by_pk(id: ${id}) {
            uid
          }
        }
        `,
        })
        .then((result) => {
          setUid(result.data.users_by_pk.uid);
        });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    client
      .query({
        query: gql`
        query MyQuery {
          logs(where: {user_uid: {_eq: "${uid}"}}, limit: 10, order_by: {created_at: desc}) {
            user_name {
              name
            }
            order_status {
              status_desc
            }
            job_id
            log_title
            log_desc
            log_type
            log_status
            created_at
          }
        }
        
        `,
      })
      .then((result) => {
        result.data.logs && setLogs(result.data.logs);
        setLoading(false);
      });
  }, [uid]);

  return (
    <Card>
      {logs && !loading ? (
        <SuiBox>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
              {t("Latest Jobs")}
            </SuiTypography>
          </SuiBox>
                {
                  logs.length > 0 ?
          <SuiBox py={1} px={2}>
            <TableContainer className="shadow-none">
              <Table>
                <SuiBox component="thead">
                  <TableRow>
                    <PagesHeaderCell>{t("Order")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Status")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Operator")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Time")}</PagesHeaderCell>
                  </TableRow>
                </SuiBox>
                  <TableBody>
                    {logs.map((item, key) => (
                      <PagesBodyCell
                        key={key}
                        rows={[
                          (item.log_type === "order" ? "Order status" : "Customs status") + " of #" + item.job_id + " has changed",
                          item.order_status
                            ? item.order_status.status_desc
                            : item.customs_status && item.customs_status.customs_status_desc,
                          item.user_name && item.user_name.name,
                          item.created_at && moment(item.created_at, "YYYY-MM-DD").format("DD.MM.YYYY"),
                        ]}
                        noBorder={logs.length === key + 1 ? false : null}
                      />
                    ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </SuiBox>:<SuiBox
            display="flex"
            mt={2}
            mb={2}
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Jobs not found.")}
            </SuiTypography>
          </SuiBox>
                }
        </SuiBox>
      ) : (
        <SuiBox display="flex" justifyContent="center" alignItems="center" p={0}>
          <img
            src="/loader.gif"
            alt="TRC Logo"
            style={{
              objectFit: "none",
              height: "160px",
              width: "100%",
              borderRadius: "50px",
            }}
          />
        </SuiBox>
      )}
    </Card>
  );
}

export default PastTransactions;
