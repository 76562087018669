import { DirectionsCarFilled, ElectricCar } from "@mui/icons-material";
import { Box, CardActions, Grid, Icon, Modal, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import vehicleList from "components/data/vehicleList";
import DataTable from "components/Tables/DataTable";
export default function MultiActionAreaCard({ vehicle, user }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [users, setUser] = useState(user);
  const [dataset, setDataset] = useState([]);
  const [openData, setOpenData] = useState(false);

  const { t } = useTranslation();

  const assignStaffOpen = () => {
    setOpen(true);
  };
  const assignStaffClose = () => {
    setOpen(false);
  };

  const updateStatusClick = ({ userList, id, status }) => {
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .mutate({
          mutation: gql`
            mutation update_vehicles_by_pk(
              $id: Int!
              $status: vehicles_status_enum!
              $uid: String!
              $start_date: timestamptz!
            ) {
              update_vehicles_by_pk(
                pk_columns: { id: $id }
                _set: { status: $status, uid: $uid, start_date: $start_date }
              ) {
                status
                uid
              }
            }
          `,
          variables: {
            id: id,
            uid: userList,
            status: status,
            start_date: new Date(),
          },
        })
        .then((result) => {
          setVehicles(result.data.update_vehicles_by_pk);
          setOpen(false);
          Swal.fire({
            name: "Success",
            text: t("Vehicle updated successfully"),
            icon: "success",
            confirmButtonText: t("Ok"),
          });
        });
    }
  };

  const updateStatusDrop = ({ userList, id, status, start_date, end_date }) => {
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .mutate({
          mutation: gql`
            mutation insert_vehicle_logs(
              $vehicle_id: Int!
              $user_uid: String!
              $start_date: timestamptz!
              $end_date: timestamptz!
              $created_by: String!
            ) {
              insert_vehicle_logs(
                objects: {
                  vehicle_id: $vehicle_id
                  user_uid: $user_uid
                  start_date: $start_date
                  created_by: $created_by
                  end_date: $end_date
                }
              ) {
                returning {
                  id
                }
              }
            }
          `,
          variables: {
            vehicle_id: id,
            user_uid: userList,
            created_by: auth.currentUser.uid,
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((result) => {
          client
            .mutate({
              mutation: gql`
                mutation update_vehicles_by_pk(
                  $id: Int!
                  $status: vehicles_status_enum!
                  $uid: String!
                  $start_date: timestamptz!
                ) {
                  update_vehicles_by_pk(
                    pk_columns: { id: $id }
                    _set: {
                      status: $status
                      uid: $uid
                      start_date: $start_date
                    }
                  ) {
                    status
                    uid
                  }
                }
              `,
              variables: {
                id: id,
                uid: null,
                status: status,
                start_date: null,
              },
            })
            .then((result) => {
              setVehicles(result.data.update_vehicles_by_pk);
              setOpen(false);
              Swal.fire({
                name: "Success",
                text: t("Vehicle updated successfully"),
                icon: "success",
                confirmButtonText: t("Ok"),
              });
            });
        });
    }
  };

  useEffect(() => {
    if (vehicle) {
      setVehicles(vehicle);
    }
  }, []);

  const dataTables = ({ id }) => {
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
            query vehicle_logs($vehicle_id: Int!) {
              vehicle_logs(
                where: { vehicle_id: { _eq: $vehicle_id } }
                order_by: { id: desc }
              ) {
                start_date
                end_date
                vehicles {
                  plate_number
                }
                vehicle_name {
                  name
                }
              }
            }
          `,
          variables: {
            vehicle_id: id,
          },
        })
        .then((result) => {
          setDataset(vehicleList({ dataset: result.data.vehicle_logs }));
          setLoading(false);
        });
    }
  };

  const deleteStatus = ({ id }) => {
    Swal.fire({
      text: t("You want to delete the car?"),
      icon: "warning",
      showCloseButton: true,
      confirmButtonText: t("Yes"),
    }).then((result) => {
      if (result.value) {
        if (auth.currentUser) {
          const userToken = getAccessToken();
          const headers = {
            Authorization: `Bearer ${userToken}`,
          };
          const client = new ApolloClient({
            uri: "https://hasura-trc.365tech.dev/v1/graphql",
            headers,
            cache: new InMemoryCache(),
          });
          client
            .mutate({
              mutation: gql`
                mutation update_vehicles_by_pk($id: Int!) {
                  update_vehicles_by_pk(
                    pk_columns: { id: $id }
                    _set: { delete_status: true }
                  ) {
                    status
                    uid
                  }
                }
              `,
              variables: {
                id: id,
              },
            })
            .then((result) => {
              setVehicles(result.data.update_vehicles_by_pk);
              setOpen(false);
              Swal.fire({
                name: "Success",
                text: t("Vehicle deleted successfully"),
                icon: "success",
                confirmButtonText: t("Ok"),
              });
            });
        }
      }
    });
  };

  return (
    <Card
      sx={{
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        bgcolor: vehicle.status !== "busy" ? `grey.400` : `dark.main`,
        pt: "4%",
      }}
    >
      <SuiBox display="flex" sx={{ position: "absolute", right: 10, top: 10 }}>
        <SuiButton
          variant="gradient"
          buttonColor="success"
          size="small"
          iconOnly
          onClick={() =>
            deleteStatus({
              id: vehicle.id,
            })
          }
        >
          <Icon color="danger">close</Icon>
        </SuiButton>
      </SuiBox>
      <SuiBox display="flex" sx={{ position: "absolute", left: 10, top: 10 }}>
        <SuiButton
          variant="gradient"
          buttonColor="info"
          size="small"
          iconOnly
          onClick={() => {
            setOpenData(true);
            dataTables({
              id: vehicle.id,
            });
          }}
        >
          <Icon color="white">details</Icon>
        </SuiButton>
      </SuiBox>
      <CardContent>
        {vehicle.status !== "busy" ? (
          <DirectionsCarFilled fontSize="large" color={"dark"} />
        ) : (
          <ElectricCar fontSize="large" color={"success"} />
        )}
      </CardContent>
      <CardContent>
        <SuiButton
          disabled
          variant="outlined"
          buttonColor={vehicle.status !== "busy" ? "dark" : "success"}
          size="small"
        >
          {vehicle.plate_number}
        </SuiButton>
        <SuiBox display="flex" justifyContent="center">
          <SuiTypography mt="10%" textColor="white" fontSize="small">
            {vehicle.status === "busy" ? t(vehicle.user["name"]) : null}
          </SuiTypography>
        </SuiBox>
      </CardContent>
      <CardActions>
        <SuiButton
          dropdown
          onClick={() => {
            vehicle.status !== "busy"
              ? assignStaffOpen()
              : Swal.fire({
                  text: t("You want to drop the car?"),
                  icon: "warning",
                  showCloseButton: true,
                  confirmButtonText: t("Yes"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    updateStatusDrop({
                      id: vehicle.id,
                      userList: vehicle.uid,
                      status: "available",
                      start_date: vehicle.start_date,
                      end_date: new Date(),
                    });
                  }
                });
          }}
          sx={{ mb: "1rem" }}
          variant="gradient"
          size="small"
          buttonColor={vehicle.status !== "busy" ? "dark" : "success"}
        >
          <SuiTypography
            variant="caption"
            textColor="white"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {vehicle.status !== "busy" ? t("available") : t("busy")}
          </SuiTypography>
        </SuiButton>
        <SuiBox>
          <Card>
            <Modal
              open={open}
              onClose={() => assignStaffClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Grid
                container
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                }}
              >
                <Card
                  sx={{
                    overflow: "visible",
                    width: "100%",
                  }}
                >
                  <SuiBox p={3}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          textTransform="uppercase"
                        >
                          {t("Assigned Staff")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiButton
                        buttonColor="error"
                        onClick={() => setOpen(false)}
                        iconOnly
                        size="small"
                      >
                        <Icon color="dark">close</Icon>
                      </SuiButton>
                    </SuiBox>
                    <SuiSelect
                      name="user"
                      placeholder={t("N/A")}
                      defaultValue={t("N/A")}
                      options={user.map((item) => ({
                        value: item.uid,
                        label: item.name,
                      }))}
                      value={users}
                      onChange={(e) => {
                        setUser({ value: e.value, label: e.label });
                      }}
                    />
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      mb={1}
                    ></SuiBox>
                    <SuiBox mt={6} display="flex" justifyContent="center">
                      <SuiButton
                        variant="gradient"
                        buttonColor="success"
                        size="small"
                        onClick={() =>
                          updateStatusClick({
                            userList: users.value,
                            id: vehicle.id,
                            status: "busy",
                          })
                        }
                      >
                        {t("Save")}
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            </Modal>
          </Card>
        </SuiBox>
        <SuiBox>
          <Card>
            <Modal
              open={openData}
              onClose={() => setOpenData(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                display: "flex",
              }}
            >
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    overflow: "visible",
                  }}
                >
                  <SuiBox p={3}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="uppercase"
                        >
                          {t("Vehicle Paste")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiButton
                        buttonColor="error"
                        onClick={() => setOpenData(false)}
                        iconOnly
                        size="small"
                      >
                        <Icon color="dark">close</Icon>
                      </SuiButton>
                    </SuiBox>

                    {dataset && !loading ? (
                      <DataTable
                        table={dataset}
                        entriesPerPage={{
                          defaultValue: 16,
                          entries: [16, 32, 64],
                        }}
                        canSearch
                      />
                    ) : (
                      <SuiBox display="flex" justifyContent="center" mb={3}>
                        <SuiTypography variant="caption">
                          {t("Vehicles is not found")}
                        </SuiTypography>
                      </SuiBox>
                    )}
                  </SuiBox>
                </Card>
              </Grid>
            </Modal>
          </Card>
        </SuiBox>
      </CardActions>
    </Card>
  );
}
