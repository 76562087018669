/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//import { useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useHistory } from "react-router-dom";
import FormField from "components/FormField";
import { useTranslation } from "react-i18next";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "../../../firebase/firebase";

function ProductEdit({ id }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState();
  const [location_full, setLocation_full] = useState("");
  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;
  const history = useHistory();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    if (location) {
      location.location_full && setLocation_full(location.location_full);
    }
  }, [location]);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query  locations_by_pk {
              locations_by_pk(id: ${id}) {
                location_full
              }
            }
          `,
        })
        .then((result) => {
          setLocation(result.data.locations_by_pk);
          setLoading(false);
        });
    }
  };
  const sentDetail = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_locations_by_pk {
          update_locations_by_pk(pk_columns: {id: ${id}}, _set: 
            {
              location_full:  ${JSON.stringify(location_full)}}) {
              id
            }
          }
          `,
        })
        .then((result) => {
          Swal.fire(t("Successfully updated!"), " ", "success");
          history.push("/db/location/list");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const deleteLocation = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_locations_by_pk {
            update_locations_by_pk(pk_columns: {id: ${id}}, _set: { status: false}) {
              id
            }
          }
          `,
        })
        .then((result) => {
          Swal.fire("Location Deleted..");
          history.push("/db/location/list");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <SuiBox>
      <Card className="overflow-visible">
        <SuiBox p={3}>
          <SuiBox display="flex" justifyContent="flex-end">
            <SuiButton
              style={{ marginRight: "10px" }}
              variant="gradient"
              buttonColor="error"
              size="small"
              onClick={() => deleteLocation()}
            >
              {t("Delete")}
            </SuiButton>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => sentDetail()}
            >
              {t("Save")}
            </SuiButton>
          </SuiBox>
          <SuiBox mt={1}>
            <Grid container spacing={3}>
              {!loading ? (
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label={t("Location")}
                    placeholder={`${t("ex")}` + " 365Ship"}
                    value={location_full}
                    onChange={(e) => setLocation_full(e.target.value)}
                    required
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <SuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={0}
                  >
                    <img
                      src="/loader.gif"
                      alt="AVS Logo"
                      style={{
                        objectFit: "none",
                        height: "160px",
                        width: "100%",
                        borderRadius: "50px",
                      }}
                    />
                  </SuiBox>
                </Grid>
              )}
            </Grid>
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ProductEdit;
