/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";
import { getAccessToken, getUserClaims } from "constants/valueStorage";
import { auth } from 'firebase/firebase';
import withAuthorization from "../../withAuthorization"; //redirects to sign in if user not signed in

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";


// Soft UI Dashboard PRO React example components
import PageLayout from "components/LayoutContainers/PageLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import DataTable from "components/Tables/DataTable";

// Data
import listData from "components/data/listData";
import timelineData from "components/data/timelineData";

// EditProduct page components

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "constants/pageRoutes";

// Custom styles for the Baise
import styles from "./styles";
import image from "assets/images/curved-images/curved22.jpg";


function ProductsList() {
  const [loading, setLoading] = useState(true)
  const classes = styles({ image });


  return (
    <PageLayout>
      <Grid container justifyContent="center" className={classes.coverLayout}>
        <Grid item xs={11} sm={8} md={5} xl={3}>
          <SuiBox mt={20}>
            <SuiBox pt={3} px={3}>
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold" textColor="info" textGradient>
                  Create New User
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" fontWeight="regular" textColor="text">
              You can create a new user by entering email and password.
              </SuiTypography>
            </SuiBox>
            <SuiBox p={3}>
            <SuiBox component="form" role="form">
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Name
            </SuiTypography>
          </SuiBox>
          <SuiInput placeholder="Name" />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput type="email" placeholder="Email" />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <SuiInput type="password" placeholder="Password" />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" buttonColor="info" fullWidth>
            Sign Up
          </SuiButton>
        </SuiBox>
      </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <SuiBox
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            customClass={classes.coverLayout_imageBox}
          >
            <SuiBox customClass={classes.coverLayout_image} />
          </SuiBox>
        </Grid>
      </Grid>

      <Footer />
    </PageLayout>
  );
}

const authCondition = authUser =>
  !!authUser && getUserClaims() == "trcAdmin"; //true and false
export default withAuthorization(authCondition)(ProductsList);
