/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import MultiActionAreaCard from "components/Vehicles/MultiActionAreaCard";
import SuiBox from "components/SuiBox";
import { Link } from "react-router-dom";
import SuiButton from "components/SuiButton";
import pageRoutes from "constants/pageRoutes";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import { useEffect, useState } from "react";

function Automotive() {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [user, setUser] = useState([]);
  const free = false;

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
            query vehicles {
              vehicles(where: { delete_status: { _eq: false } }) {
                id
                plate_number
                status
                start_date
                end_date
                delete_status
                uid
                user {
                  name
                }
              }
            }
          `,
        })
        .then((result) => {
          setVehicles(result.data.vehicles);
          // user hasura list
          client
            .query({
              query: gql`
                query users {
                  users {
                    name
                    uid
                  }
                }
              `,
            })
            .then((result) => {
              setUser(result.data.users);
            });

          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser, vehicles]);

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <Grid className="flex-row-reverse">
        <SuiBox mb={2} mx={2} justifyContent="flex-end" display="flex">
          <Link to="/db/vehicle/add" className="decoration-none">
            <SuiButton variant="gradient" buttonColor="info" size="small">
              + {"Add Vehicle"}
            </SuiButton>
          </Link>
        </SuiBox>
      </Grid>
      <Grid container spacing={3}>
        {vehicles.map((vehicle) => (
          <Grid item xs={12} sm={6} md={4}>
            <MultiActionAreaCard vehicle={vehicle} user={user} />
          </Grid>
        ))}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Automotive;
