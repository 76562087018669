/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// react-router components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { useTranslation } from "react-i18next";


function SingleNavbarLink({
  name,
  route,
  light,
  menu,
  openHandler,
  closeHandler,
  collapseStatus,
  ...rest
}) {
  const { t, i18n } = useTranslation();

  return (
      <SuiBox
        {...rest}
        onMouseEnter={openHandler ? openHandler : undefined}
        // onMouseOut={closeHandler ? closeHandler : undefined}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        component={Link}
        customClass="cursor-pointer"
        to={route}
      >
        <SuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          textColor="inherit"
          customClass="w-100"
        >
          {t(name)}
        </SuiTypography>
        <SuiTypography variant="body2" textColor={light ? "white" : "dark"}>
        <Icon className="font-bold vertical-middle">{menu && "keyboard_arrow_down"}</Icon>
        </SuiTypography>
      </SuiBox>
  );
}

// Setting default values for the props of SingleNavbarLink
SingleNavbarLink.defaultProps = {
  route: "#",
  light: false,
  menu: false,
  openHandler: false,
  closeHandler: false,
  collapseStatus: false,
};

// Typechecking props for the SingleNavbarLink
SingleNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  light: PropTypes.bool,
  menu: PropTypes.bool,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  collapseStatus: PropTypes.bool,
};

export default SingleNavbarLink;
