/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db, auth } from 'firebase/firebase';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import { useHistory } from "react-router-dom";

function FolderInfo({ id }) {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    db.ref('users/' + auth.currentUser.uid).once('value').then((snapshot) => {
      if (snapshot) {
        setName(snapshot.val().name)
        setEmail(snapshot.val().email)
      }
    }).catch(e => {
      console.log(e.message)
    })
  }, [])
  const sendUpdate = () => {
  }

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography variant="h5" alignSelf="center" fontWeight="bold">Folders</SuiTypography>
          <SuiButton variant="gradient" buttonColor="info" size="small" onClick={() => history.push("/account/edit/" + id)}>
            Edit
          </SuiButton>
        </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Criminal Records
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Passport
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >Health Report
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Diploma
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > PPD
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > EK-2
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Driving Licence
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Diploma 2
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Identity
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Education Sertificate
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Covid Vaccine Card
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Place Of Residance
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              > Admission To Employment
              </SuiTypography>
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize"
                > Empty
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>

        </Grid>
      </SuiBox>
    </Card >
  );
}

export default FolderInfo;
