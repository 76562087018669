/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import moment from "moment";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import PagesHeaderCell from "components/dashboard/PagesHeaderCell";
import PagesBodyCell from "components/dashboard/PagesBodyCell";

function Pages({ chart }) {
  const { t } = useTranslation();

  return (
    <Card>
      {chart && (
        <SuiBox>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
            px={2}
          >
            <SuiTypography variant="h6">{t("Recent Records")}</SuiTypography>
            <Tooltip
              title={t("Shows the order status instantly.")}
              placement="left"
            >
              <SuiButton
                variant="outlined"
                buttonColor="success"
                size="small"
                circular
                iconOnly
              >
                <Icon className=" font-bold">done</Icon>
              </SuiButton>
            </Tooltip>
          </SuiBox>
          <SuiBox py={1} px={2}>
            <TableContainer className="shadow-none">
              <Table>
                <SuiBox component="thead">
                  <TableRow>
                    <PagesHeaderCell>{t("Order")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Status")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Operator")}</PagesHeaderCell>
                    <PagesHeaderCell>{t("Time")}</PagesHeaderCell>
                  </TableRow>
                </SuiBox>
                <TableBody>
                  {chart.map((item, key) => (
                    <PagesBodyCell
                      key={key}
                      rows={[
                        (item.log_type === "order"
                          ? "Order status"
                          : "Customs status") +
                          " of #" +
                          item.job_id +
                          " has changed",
                        item.order_status
                          ? item.order_status.status_desc
                          : item.customs_status &&
                            item.customs_status.customs_status_desc,
                        item.user_name && item.user_name.name,
                        item.created_at &&
                          moment(item.created_at, "YYYY-MM-DD").format(
                            "DD.MM.YYYY"
                          ),
                      ]}
                      noBorder={chart.length === key + 1 ? false : null}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SuiBox>
        </SuiBox>
      )}
    </Card>
  );
}

export default Pages;
