/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import TimelineList from "components/Timeline/TimelineList";
import TimelineItem from "components/Timeline/TimelineItem";
import timelineData from "components/data/timelineData";
import { useTranslation } from "react-i18next";
import { auth, fbstorage } from "../../../firebase/firebase";
import { getAccessToken } from "constants/valueStorage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

function StatusTimeline({ id }) {
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const userToken = getAccessToken();
    const uid = auth.currentUser.uid;

    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const client = new ApolloClient({
      uri: "https://hasura-trc.365tech.dev/v1/graphql",
      headers,
      cache: new InMemoryCache(),
    });

    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query logs {
              logs(where: {job_id: {_eq: ${id}}, log_type: {_eq: "order"}}, order_by: {created_at: desc}) {
                user_name {
                  name
                }
                order_status {
                  status_desc
                }
                customs_status {
                  customs_status_desc
                }            
                log_type
                log_title
                log_desc
                log_status
                created_at
              }
            }
          `,
        })
        .then((result) => {
          result.data.logs &&
            result.data.logs.length > 0 &&
            setDatas(result.data.logs);
          setLoading(false);
        });
    }
  }, []);

  const renderTimelineItems = timelineData({ dataset: datas }).map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <SuiBox mb={3}>
      <Card className="overflow-visible">
        {!loading && datas.length > 0 ? (
          <TimelineList title={t("Process")}>
            {renderTimelineItems}
          </TimelineList>
        ) : !loading && datas.length === 0 ? (
          <SuiBox p={3}>
            <SuiTypography variant="h5" fontWeight="bold">
              {t("Process Information Not Found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="AVS Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </Card>
    </SuiBox>
  );
}

export default StatusTimeline;
