/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { auth } from "firebase/firebase";
import { getAccessToken } from "constants/valueStorage";
import { useTranslation } from "react-i18next";
import moment from "moment";
const detail = true;

function Notes({ id, detail }) {
  const [notes, setNotes] = useState([]);
  const [assignedUser, setAssignedUser] = useState([]);
  const [note, setNote] = useState("");

  const NotificationUser = [
    "ML6DuCNBhGhISeWb5t9ABeFyS9r2",
    "BZFSqkEZZvclVvILuYyJtdtB52n2",
    "aVfBC20bkpYKMGBUdU4U77GCF3T2",
    "UtOsXeh0gcdnfjMzOwFeWFKaLob2",
    "KLW6ev9wRsRODZ9LnOGhKUYQ9xj1",
  ];

  const { t } = useTranslation();

  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                notes ( where: { status: {_eq: true} }, order_by: { created_at: asc }) {
                  id
                  note
                  created_at
                  noteUser {
                    name
                  }
                }
                assigned_jobs {
                  uid
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.ship_infos_by_pk && setNotes(result.data.ship_infos_by_pk.notes);
          setAssignedUser(result.data.ship_infos_by_pk.assigned_jobs.map((item) => item.uid));
        });
    }
  };

  const sentNote = (e) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
            mutation insert_notes_one {
              insert_notes_one(object: { UserID: ${JSON.stringify(uid)}, note: ${JSON.stringify(note)}, infoID: ${id} }) {
                id
              }
            }
          `,
        })
        .then((result) => {
          setNote("");
          getNotes();
          const notfDesc = id + " : " + note;
          assignedUser.map((assigned) => {
            client
              .mutate({
                mutation: gql`
                mutation insert_fcm_notifications_one {
                insert_fcm_notifications_one(object: {
                  useruid: ${JSON.stringify(assigned)}, 
                  title: "Yeni not eklendi", 
                  desc: "${notfDesc}", 
                  status: ${assigned ? "waiting" : "nouser"}, 
                  type: ${assigned ? "user" : "nouser"}}) {
                  id
                }
              }
              `,
              })
              .then((result) => {
                // console.log("result", result);
              })
              .catch((error) => {
                console.log(error);
              });
          });
          NotificationUsers(notfDesc);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const NotificationUsers = (notfDesc) => {
    NotificationUser.map((user) => {
      client
        .mutate({
          mutation: gql`
            mutation insert_fcm_notifications_one {
              insert_fcm_notifications_one(object: {
                useruid: ${JSON.stringify(user)}, 
                title: "Yeni not eklendi", 
                desc: "${notfDesc}", 
                status: ${user ? "waiting" : "nouser"}, 
                type: ${user ? "user" : "nouser"}
              }) {
                id
              }
            }
            `,
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const deleteNotes = (idDelete) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
            mutation update_notes_by_pk($id: Int!, $status: Boolean!) {
              update_notes_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
                id
              }
            }
          `,
          variables: {
            id: idDelete,
            status: false,
          },
        })
        .then((result) => {
          Swal.fire(t("Deleted Note!"), " ", "success");
          getNotes();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card className="overflow-visible">
      <SuiBox p={3}>
        <SuiTypography variant="h6" fontWeight="bold" mb={2} textColor="text">
          {t("Notes")}
        </SuiTypography>
        {notes != null && notes.length > 0 ? (
          <SuiBox>
            {notes.map(({ id, note, created_at, noteUser }, key) => (
              <SuiBox key={id} display="flex" mt={key === 0 ? 0 : 2}>
                <SuiBox flexGrow={1} ml={0}>
                  <SuiBox display="flex">
                    <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
                      {noteUser && noteUser.name}
                    </SuiTypography>
                    <SuiTypography variant="caption" fontWeight="regular" textColor="text" alignSelf="center" ml={1}>
                      {created_at && moment(created_at).format("DD.MM.YYYY HH:mm")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox lineHeight={0.75}>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text">
                      {note}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                {detail === true ? (
                  <SuiBox>
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="small"
                      iconOnly
                      onClick={() => {
                        deleteNotes(id);
                      }}
                    >
                      <Icon>delete</Icon>
                    </SuiButton>
                  </SuiBox>
                ) : null}
              </SuiBox>
            ))}
            <SuiBox display="flex" alignItems="center" justifyContent="space-between" mt={4}>
              <SuiBox flexGrow={1} pr={1}>
                <SuiInput placeholder={t("Enter Your Note")} multiline value={note} onChange={(event) => setNote(event.target.value)} />
              </SuiBox>
              <SuiButton variant="gradient" buttonColor="info" iconOnly onClick={() => sentNote()}>
                <Icon className="material-icons-round">send</Icon>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        ) : notes.length == 0 ? (
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="space-between" mt={2}>
              <SuiBox flexGrow={1} pr={1}>
                <SuiInput
                  placeholder={t("Enter Your First Note")}
                  multiline
                  value={note}
                  onChange={(event) => setNote(event.target.value)}
                />
              </SuiBox>
              <SuiButton variant="gradient" buttonColor="info" iconOnly onClick={() => sentNote()}>
                <Icon className="material-icons-round">send</Icon>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        ) : (
          <SuiBox display="flex" justifyContent="center" alignItems="center" p={0}>
            <img
              src="/loader.gif"
              alt="AVS Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default Notes;
