/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import DataTable from "components/Tables/DataTable";
import listData from "components/data/listData";
import pageRoutes from "constants/pageRoutes";

function ProductsList() {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });

      client
        .query({
          query: gql`
            query ship_infos {
              ship_infos(
                order_by: { planned_delivery_date: asc }
                where: { status: { _in: [inwarehouse, prepared, waiting] } }
              ) {
                id
                imo
                shipname
                ship_oparator_name {
                  name
                }
                ship_office_worker_name {
                  name
                }
                planned_delivery_date
                ETA
                locationByArrivalLocName {
                  location_full
                }
                ship_infos_status {
                  status_desc
                }
                delivery_type
                delivery_company
                order_number
                assigned_jobs {
                  users {
                    name
                  }
                }
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(
            listData({ dataset: result.data.ship_infos, type: "active" })
          );
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox my={0}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="bold" textColor="text">
                {t("Active Orders")}
              </SuiTypography>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textColor="text"
              >
                {t("Youcanfollowyouractiveordersinthispage")}
              </SuiTypography>
            </SuiBox>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              <Link to="/order/add" className="decoration-none">
                <SuiButton variant="gradient" buttonColor="info" size="small">
                  + {t("Add Service")}
                </SuiButton>
              </Link>
              <Link to="/order/history" className="decoration-none">
                <SuiButton
                  variant="gradient"
                  buttonColor="warning"
                  size="small"
                >
                  {t("Past Orders")}
                </SuiButton>
              </Link>
              {/* <SuiButton variant="gradient" buttonColor="success" size="small">
                <FileUploadIcon /> {t("Export To Excel")}
              </SuiButton> */}
            </Stack>
          </SuiBox>
          {dataSet && !loading ? (
            <DataTable
              table={dataSet}
              entriesPerPage={{
                defaultValue: 16,
                entries: [16, 32, 64],
              }}
              canSearch
            />
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="AVS Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
