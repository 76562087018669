import React from "react";
import { firebase, auth } from "../firebase";
import { db } from "../firebase/firebase";
import AuthUserContext from "../components/AuthUserContext";
import { getAccessToken, setAccessToken, setUserClaims } from "constants/valueStorage";
// firebase auth

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    state = {
      authUser: null
    };

    componentDidMount() {
      this.getData()
      setInterval(this.getData, 3600000) // 3600000 == 1 hour
    }

    getData = () => {
      firebase.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          const token = await authUser.getIdToken(true)
          setAccessToken(token)
          const idTokenResult = await authUser.getIdTokenResult()
          const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims']

          if (hasuraClaim) {
            this.setState({
              authUser,
              token
            })
            setAccessToken(token);
          } else {
            // Check if refresh is required.
            const metadataRef = firebase.db
              .ref('users/' + authUser.uid + '/refreshTime')

            metadataRef.on('value', async data => {
              if (!data.exists) {
                return
              }
              // Force refresh to pick up the latest custom claims changes.
              // Note this is always triggered on first call. Further optimization could be
              // added to avoid the initial trigger when the token is issued and already contains
              // the latest claims.
              const token = await authUser.getIdToken(true)
              this.setState({
                authUser,
                token
              })
              setAccessToken(token);
            })
          }
          db.ref('users/' + authUser.uid).once('value').then((snapshot) => {
            if (snapshot && (snapshot.val().claims==="officeWorker" || snapshot.val().claims==="trcAdmin")) {
                setUserClaims(snapshot.val().claims)
            } else {
                auth.doSignOut()
                alert("This user does not have access privileges.")
              }
          }).catch( e => {
            alert(e.message);
          })
        } else {
          this.setState({
            authUser: null,
            token: null
          })
          setAccessToken("");
          setUserClaims("");
        }
      });      
    }

    render() {
      const { authUser } = this.state;
      return (
        //   passing down the authUser value, so other components can consume it
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
