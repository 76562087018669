/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import FlatList from 'flatlist-react';
import { getAccessToken } from "constants/valueStorage";
import { auth, fbstorage } from '../../firebase/firebase';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

import { useTranslation } from "react-i18next";
import image1 from "assets/images/pdf.png";

function Attachments({ id }) {
  const [loading, setLoading] = useState(true);
  const [filesCustoms, setFilesCustoms] = useState([]);
  const [filesSrcCustoms, setFilesSrcCustoms] = useState([]);
  const [filesInvoice, setFilesInvoice] = useState([]);
  const [filesSrcInvoice, setFilesSrcInvoice] = useState([]);
  const [filesOther, setFilesOther] = useState([]);
  const [filesSrcOther, setFilesSrcOther] = useState([]);
  const { t, i18n } = useTranslation(); 
  useEffect(() => {
    const userToken = getAccessToken();
    const uid = auth.currentUser.uid
  
    const headers = { 
      Authorization: `Bearer ${userToken}`,
    }
    const client = new ApolloClient({
      uri: 'https://hasura-trc.365tech.dev/v1/graphql',
      headers,
      cache: new InMemoryCache()
    });
  
    if(auth.currentUser){
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                files(where: {status: {_eq: true}, type: {_eq: ordercustoms}}) {
                  file_path
                  name
                }
              }
            }
          `
        })
        .then(result => {
          (result.data.ship_infos_by_pk && result.data.ship_infos_by_pk.files && result.data.ship_infos_by_pk.files.length > 0 ) && setFilesCustoms(result.data.ship_infos_by_pk.files)
        })
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                files(where: {status: {_eq: true}, type: {_eq: orderinvoice}}) {
                  file_path
                  name
                }
              }
            }
          `
        })
        .then(result => {
          (result.data.ship_infos_by_pk && result.data.ship_infos_by_pk.files && result.data.ship_infos_by_pk.files.length > 0 ) && setFilesInvoice(result.data.ship_infos_by_pk.files)
        })
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                files(where: {status: {_eq: true}, type: {_eq: orderother}}) {
                  file_path
                  name
                }
              }
            }
          `
        })
        .then(result => {
          (result.data.ship_infos_by_pk && result.data.ship_infos_by_pk.files && result.data.ship_infos_by_pk.files.length > 0 ) && setFilesOther(result.data.ship_infos_by_pk.files)
        })
    }
  }, [auth.currentUser]);

  useEffect(() => {
    var data = []
    if (filesCustoms && filesCustoms.length > 0) {
      setLoading(true);
      filesCustoms.map(image => {
        fbstorage.ref(image.file_path).getDownloadURL().then(url => {
          data.push({
            src: url,
            name: image.name
          });
        }).catch(error => {
          console.log(error)
        })
      })
      setFilesSrcCustoms(data)
      setLoading(false)
    }
  }, [filesCustoms]);

  useEffect(() => {
    var data = []
    if (filesInvoice && filesInvoice.length > 0) {
      setLoading(true);
      filesInvoice.map(image => {
        fbstorage.ref(image.file_path).getDownloadURL().then(url => {
          data.push({
            src: url,
            name: image.name
          });
        }).catch(error => {
          console.log(error)
        })
      })
      setFilesSrcInvoice(data)
      setLoading(false)
    }
  }, [filesInvoice]);

  useEffect(() => {
    var data = []
    if (filesOther && filesOther.length > 0) {
      setLoading(true);
      filesOther.map(image => {
        fbstorage.ref(image.file_path).getDownloadURL().then(url => {
          data.push({
            src: url,
            name: image.name
          });
        }).catch(error => {
          console.log(error)
        })
      })
      setFilesSrcOther(data)
      setLoading(false)
    }
  }, [filesOther]);
  
  const renderList = (item, idx) => {
    return (
      <SuiBox display="grid" mr={3} key={idx}>
        <SuiBox
          lineHeight={0} 
          component="img"
          id={0}
          src={image1}
          justifySelf="center"
          width="64px"
          customClass="cursor-pointer object-cover"
          onClick={()=> window.open(item.src, "_blank")}
        />
        <SuiTypography
          component="label"
          variant="caption"
          textTransform="capitalize"
          textAlign="center"
        >
          {item.name}
        </SuiTypography>
      </SuiBox>
    )
  }
  const renderNullList = () => {
    return (
      <SuiTypography
      component="label"
      variant="caption"
      textTransform="capitalize"
      textAlign="center"
      ml={0.5}
      mt={1}
    >
      Empty
    </SuiTypography>
    )
  }

  return (
    <Card className="overflow-visible">
      <SuiBox p={3}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Additional Files")}
        </SuiTypography>
        <SuiBox mt={1} pt={1}>
            <Grid item xs={12} lg={12}>
              <SuiBox display="flex">
                <FlatList
                  list={filesSrcOther}
                  renderItem={renderList}
                  renderWhenEmpty={renderNullList}
                />
              </SuiBox>
            </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Attachments;
