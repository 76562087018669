/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState } from "react";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import Attachments from "components/Attachments";
import ProductImages from "components/FormPage/ProductImages";
import ProductInfo from "components/FormPage/ProductInfo";
import Notes from "components/FormPage/Notes";
import StatusTimeline from "components/FormPage/StatusTimeline";
import LastUpdate from "components/FormPage/LastUpdate";
import pageRoutes from "constants/pageRoutes";

function ProductsList(props) {
  const [loading, setLoading] = useState(true);
  const { id } = props.match.params;
  const detail=false;

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox my={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Grid item xs={12} lg={12}>
              <ProductImages id={id} />
            </Grid>
            <Grid item xs={12} lg={12} mb={3}>
              <StatusTimeline id={id} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid item xs={12} lg={12} mb={3}>
              <ProductInfo id={id} />
            </Grid>
            <Grid item xs={12} lg={12} mb={3}>
              <LastUpdate id={id} />
            </Grid>
            <Grid item xs={12} lg={12} mb={3}>
              <Attachments id={id} />
            </Grid>
            <Grid item xs={12} lg={12} mb={3}>
              <Notes detail={detail} id={id} />
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
