import React, { Component } from 'react';
import { db } from '../firebase/firebase';
import { Button, Form , InputGroup } from 'react-bootstrap';

class Footer extends Component {
    state = {feedback: ""}

    onSubmit = event => {
        const { feedback } = this.state;
            db.ref('feedbacks').push().set({ feedback: feedback })
            .then(()=> {
                alert("Thank you so much for taking the time to send us your valuable feedback!");
                this.setState({ feedback: "" });
            })
            .catch(e =>{
                alert(e.message);
            });
            event.preventDefault();
    }

    render() {
        const { feedback } = this.state;
        const Invalid = feedback === "";
        return (<div id="ourfooter">
            <br/>
            <footer>
                <div className="container-foot">
                    <section className="ft-legal">
                        <ul className="ft-legal-list">
                        <li style={{color: "white"}}>&copy; 2021 365 Technology</li>
                        <li style={{color: "white"}}>v{process.env.REACT_APP_VERSION}</li>
                        </ul>
                    </section>
                </div>
            </footer>
        </div>);
    }
    
}

export default Footer;