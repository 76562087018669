/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "components/schemas/formVehicle";

const {
  formField: { vehiclePlate },
} = checkout;

export default [
  Yup.object().shape({
    [vehiclePlate.name]: Yup.string().required(vehiclePlate.errorMsg),
  }),
];
