/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import ActionCellTraining from "components/ActionCell/training";
function trainingList({ dataset, dataset2 }) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item, index) => {
      data.push({
        educator: item.educator,
        trainee: item.trainee,
        organisation: item.organisation,
        trainingdate: item.training_date,
        action: <ActionCellTraining id={item.id} />,
      });
    });
  }

  return {
    columns: [
      { Header: "EDUCATOR", name: "educator", accessor: "educator" },
      { Header: "TRAINEE", name: "trainee", accessor: "trainee" },
      {
        Header: "ORGANISATION",
        name: "organisation",
        accessor: "organisation",
      },
      {
        Header: "TRAINING DATE",
        name: "trainingdate",
        accessor: "trainingdate",
      },
      { Header: "Process", name: "action", accessor: "action" },
    ],

    rows: [...data],
  };
}
// Setting default values for the props of DashboardNavbar
trainingList.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
trainingList.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default trainingList;
