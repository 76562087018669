/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "components/OrderSchemas/formLand";

const {
  formField: {
    refnumber,
    deliveryCompany,
    company,
    price,
    currency,
    operators,
    shippingMethod,
    arrivalLoc,
    note,
  },
} = checkout;

export default {
  [refnumber.name]: "",
  [deliveryCompany.name]: "",
  [company.name]: "",
  [price.name]: "",
  [currency.name]: "",
  [operators.name]: "",
  [shippingMethod.name]: "",
  [arrivalLoc.name]: "",
  [note.name]: "",
};
