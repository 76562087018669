/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "components/OrderSchemas/trainingForm";

const {
  formField: { educator, trainee, organisation, trainingdate },
} = checkout;

export default [
  Yup.object().shape({
    [educator.name]: Yup.string().required(educator.errorMsg),
    [trainee.name]: Yup.string().required(trainee.errorMsg),
    [organisation.name]: Yup.string().required(organisation.errorMsg),
    [trainingdate.name]: Yup.date().required(trainingdate.errorMsg),
    
    // [repeatPassword.name]: Yup.string().required(repeatPassword.errorMsg).oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
];
