/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    imo: {
      name: "imo",
      label: "IMO",
      type: "number",
      placeholder: "ex. 1234567",
    //  errorMsg: "The 7-digit IMO number is required.",
    },
    shipName: {
      name: "shipName",
      label: "Ship Name",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "Ship Name is required.",
    },
    agent: {
      name: "agent",
      label: "Agent",
      type: "text",
      placeholder: "ex. 365Agent",
    },
    company: {
      name: "company",
      label: "Company Name",
      type: "text",
      placeholder: "ex. 365Tech",
    },
    price: {
      name: "price",
      label: "Order Amount",
      type: "number",
      placeholder: "ex. 1907",
    },
    currency: {
      name: "currency",
      label: "Currency",
      type: "text",
      placeholder: "ex. $ USD",
    },
    operators: {
      name: "operators",
      label: "Operator",
      type: "text",
      placeholder: "ex. Adnan Kurt",
    },
    shippingMethod: {
      name: "shippingMethod",
      label: "Shipping Method",
      type: "text",
      placeholder: "ex. Tersane",
    },
    arrivalLoc: {
      name: "arrivalLoc",
      label: "Location",
      type: "number",
      placeholder: "ex. 365Tech Warehouse",
    },
    engineSupplier: {
      name: "engineSupplier",
      label: "Engine Supplier",
      type: "text",
      placeholder: "ex. 365Engine",
    },
    plateNumber: {
      name: "plateNumber",
      label: "Plate Number",
      type: "text",
      placeholder: "ex. 34BN6176",
    },
    eta: {
      name: "eta",
      label: "ETA",
    },
    etb: {
      name: "etb",
      label: "ETB",
    },
    etd: {
      name: "etd",
      label: "ETD",
    },
    note: {
      name: "note",
      label: "Notes",
      placeholder: "Note for Delivery. *optional",
    },
    todo: {
      name: "todo",
      label: "Todo List",
      placeholder: "Todo",
      errorMsg: "Todo is required.",
    },
  },
};
