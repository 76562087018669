/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";

function WorkDetail({ id }) {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const [agent, setAgent] = useState(null);
  const [callSign, setCallSign] = useState(null);
  const [flag, setFlag] = useState(null);
  const [grosstonnage, setGrosstonnage] = useState(null);
  const [kelLand, setKelLand] = useState(null);
  const [mmsi, setMmsi] = useState(null);
  const [imo, setImo] = useState(null);
  const [name, setName] = useState(null);
  const [owner, setOwner] = useState(null);
  const [portState, setPortStete] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
        query vessels_by_pk {
            vessels_by_pk(id: ${id}) {
                agent
                call_sign
                flag
                grosstonnage
                id
                imo
                kell_land
                mmsi
                name
                owner
                port_state
            }
        }
        `,
        })
        .then((result) => {
          setDataSet(result.data.vessels_by_pk);
          setAgent(result.data.vessels_by_pk.agent);
          setCallSign(result.data.vessels_by_pk.call_sign);
          setFlag(result.data.vessels_by_pk.flag);
          setGrosstonnage(result.data.vessels_by_pk.grosstonnage);
          setImo(result.data.vessels_by_pk.imo);
          setKelLand(result.data.vessels_by_pk.kell_land);
          setMmsi(result.data.vessels_by_pk.mmsi);
          setName(result.data.vessels_by_pk.name);
          setOwner(result.data.vessels_by_pk.owner);
          setPortStete(result.data.vessels_by_pk.port_state);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Vessel Informations")}
          </SuiTypography>
          <SuiButton
            variant="gradient"
            buttonColor="info"
            size="small"
            onClick={() => history.push("/db/vessel/edit/" + id)}
          >
            {t("Edit")}
          </SuiButton>
        </SuiBox>
        {dataSet && !loading ? (
          <Grid container mt={1} spacing={3}>
            {name && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Ship Name")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                  >
                    {name}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {imo && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    IMO
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {imo}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {callSign && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("callSign")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {callSign}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {mmsi && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    MMSI
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {mmsi}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {owner && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Owner")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {owner}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {grosstonnage && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Gross Tonnage")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {grosstonnage}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {agent && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Agent")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {agent}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {flag && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Flag")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                  >
                    {flag}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {portState && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Port
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {portState}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
            {kelLand && (
              <Grid item xs={12} sm={3}>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("KelLand")}
                  </SuiTypography>
                </SuiBox>
                <SuiBox width="100%" display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {kelLand}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default WorkDetail;
