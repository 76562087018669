/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TimelineItem from "components/TimelineVessel/TimelineItem";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Swal from "sweetalert2";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
import Modal from "@mui/material/Modal";
import Dropzone from "components/Dropzone";
import SuiSelect from "components/SuiSelect";
import ViewFile from "components/ViewFile";
import { useTranslation } from "react-i18next";

function CertificateInformation({ id }) {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [batteryExpiryDate, setBatteryExpiryDate] = useState("");
  const [batteryStartDate, setBatteryStartDate] = useState("");
  const [open, setOpen] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [report, setReport] = useState({});
  const [record, setRecord] = useState({});
  const [gyro, setGyro] = useState({});
  const [ais, setAis] = useState({});
  const [magneticCompass, setMagneticCompass] = useState({});
  const [gmdsSbm, setGmdsSbm] = useState({});
  const [bnwas, setBnwas] = useState({});
  const [ssas, setSsas] = useState({});
  const [epirbSbm, setEpirbSbm] = useState({});
  const [twoWay, setTwoWay] = useState({});
  const [epirb, setEpirb] = useState({});
  const [sart, setSart] = useState({});
  const [date, setDate] = useState("");
  const { t, i18n } = useTranslation();
  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(false);
  const handleSetStartDate = (newDate) => {
    setDate(newDate);
  };

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const deleteFile = (itemId) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation vessel_certificates_by_pk {
            update_vessel_certificates_by_pk(pk_columns: {id: ${itemId}}, _set: {status: false}) {
              id
              status
            }
          }    
          `,
        })
        .then((result) => {
          getData();
        });
    }
  };

  const getData = async () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessel_certificates {
              vessel_certificates(where: {vessel_id: {_eq: ${id}}, status: {_eq: true}}, order_by: {created_at: asc}) {
                certificate_start_date
                certificate_expiry_date
                battery_start_date
                battery_expiry_date
                battery
                certificate_type
                id
                file {
                  file_path
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.vessel_certificates.length >= 0
            ? setDataSet(result.data.vessel_certificates)
            : setLoading(false);
        });
    }
  };

  useEffect(() => {
    setReport({});
    setRecord({});
    setGyro({});
    setAis({});
    setMagneticCompass({});
    setGmdsSbm({});
    setBnwas({});
    setSsas({});
    setEpirbSbm({});
    setTwoWay({});
    setEpirb({});
    setSart({});

    if (dataSet.length > 0) {
      dataSet.map((data) => {
        switch (data.certificate_type) {
          case "report":
            setReport({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "record":
            setRecord({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "gyro":
            setGyro({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "ais":
            setAis({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "magnetic_compass":
            setMagneticCompass({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "gmds_sbm":
            setGmdsSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "bnwas":
            setBnwas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "ssas":
            setSsas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "epirb_sbm":
            setEpirbSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "two_way":
            setTwoWay({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "epirb":
            setEpirb({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          case "sart":
            setSart({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
              id: data.id,
            });
            break;
          default:
            break;
        }
      });
    }
    setLoading(false);
  }, [dataSet]);

  const sentUpdate = () => {
    setOpen(false);
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation insert_vessel_certificates_one {
            insert_vessel_certificates_one(object:
              {
                certificate_expiry_date: ${
                  expiryDate ? JSON.stringify(expiryDate) : null
                }, 
                certificate_start_date:  ${
                  startDate ? JSON.stringify(startDate) : null
                }, 
                battery_start_date: ${
                  batteryExpiryDate ? JSON.stringify(batteryExpiryDate) : null
                }, 
                battery_expiry_date:  ${
                  batteryStartDate ? JSON.stringify(batteryStartDate) : null
                }, 
                vessel_id: ${id},
                battery: ${
                  batteryExpiryDate && batteryStartDate ? "true" : "false"
                },
                certificate_type: ${
                  certificate ? JSON.stringify(certificate.value) : null
                }, 
                file_id: ${file ? file : null},
              }) {
              vessel_id
              id
            }
          }
          `,
        })
        .then((result) => {
          getData();
          Swal.fire(t("Successfully added!"), " ", "success");
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between" mb={2}>
          <SuiTypography
            component="label"
            fontWeight="bold"
            textTransform="capitalize"
            variant="h6"
            gutterBottom
            textColor="text"
          >
            {t("Certificate")}
          </SuiTypography>
          <SuiButton
            variant="gradient"
            size="medium"
            iconOnly
            buttonColor="success"
            onClick={() => setOpen(true)}
          >
            <Icon className="" fontSize="small">
              add
            </Icon>
          </SuiButton>
        </SuiBox>
        {dataSet.length > 0 && !loading ? (
          <SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={
                    <UploadFileIcon
                      color={report && report.file_path ? "success" : "info"}
                    />
                  }
                  title="Report"
                  dateTime={
                    report
                      ? (report.certificate_start_date
                          ? report.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (report.certificate_expiry_date
                          ? report.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {report && report.file_path && (
                    <>
                      <ViewFile
                        filePath={report.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(report.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={
                    <UploadFileIcon
                      color={record && record.file_path ? "success" : "info"}
                    />
                  }
                  title="Record"
                  dateTime={
                    record
                      ? (record.certificate_start_date
                          ? record.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (record.certificate_expiry_date
                          ? record.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {record && record.file_path && (
                    <>
                      <ViewFile
                        filePath={record.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(record.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    gyro && gyro.file_path ? "success" : "info"
                   }
                    />}
                  title="Gyro"
                  dateTime={
                    gyro
                      ? (gyro.certificate_start_date
                          ? gyro.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (gyro.certificate_expiry_date
                          ? gyro.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {gyro && gyro.file_path && (
                    <>
                      <ViewFile
                        filePath={gyro.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(gyro.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    ais && ais.file_path ? "success" : "info"
                   }
                    />}
                  title="Ais"
                  dateTime={
                    ais
                      ? (ais.certificate_start_date
                          ? ais.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (ais.certificate_expiry_date
                          ? ais.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {ais && ais.file_path && (
                    <>
                      <ViewFile
                        filePath={ais.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(ais.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    magneticCompass && magneticCompass.file_path ? "success" : "info"
                   }
                    />}
                  title="Magnetic Compass"
                  dateTime={
                    magneticCompass
                      ? (magneticCompass.certificate_start_date
                          ? magneticCompass.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (magneticCompass.certificate_expiry_date
                          ? magneticCompass.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {magneticCompass && magneticCompass.file_path && (
                    <>
                      <ViewFile
                        filePath={magneticCompass.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(magneticCompass.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    gmdsSbm && gmdsSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Gmds Sbm"
                  dateTime={
                    gmdsSbm
                      ? (gmdsSbm.certificate_start_date
                          ? gmdsSbm.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (gmdsSbm.certificate_expiry_date
                          ? gmdsSbm.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {gmdsSbm && gmdsSbm.file_path && (
                    <>
                      <ViewFile
                        filePath={gmdsSbm.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(gmdsSbm.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    bnwas && bnwas.file_path ? "success" : "info"
                   }
                    />}
                  title="Bnwas"
                  dateTime={
                    bnwas
                      ? (bnwas.certificate_start_date
                          ? bnwas.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (bnwas.certificate_expiry_date
                          ? bnwas.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {bnwas && bnwas.file_path && (
                    <>
                      <ViewFile
                        filePath={bnwas.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(bnwas.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    ssas && ssas.file_path ? "success" : "info"
                   }
                    />}
                  title="Ssas"
                  dateTime={
                    ssas
                      ? (ssas.certificate_start_date
                          ? ssas.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (ssas.certificate_expiry_date
                          ? ssas.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {ssas && ssas.file_path && (
                    <>
                      <ViewFile
                        filePath={ssas.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(ssas.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirbSbm && epirbSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb Sbm"
                  dateTime={
                    epirbSbm
                      ? (epirbSbm.certificate_start_date
                          ? epirbSbm.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (epirbSbm.certificate_expiry_date
                          ? epirbSbm.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirbSbm && epirbSbm.file_path && (
                    <>
                      <ViewFile
                        filePath={epirbSbm.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(epirbSbm.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
               <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    twoWay && twoWay.file_path ? "success" : "info"
                   }
                    />}
                  title="Two Way"
                  dateTime={
                    twoWay
                      ? (twoWay.certificate_start_date
                          ? twoWay.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (twoWay.certificate_expiry_date
                          ? twoWay.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {twoWay && twoWay.file_path && (
                    <>
                      <ViewFile
                        filePath={twoWay.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(twoWay.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirb && epirb.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb"
                  dateTime={
                    epirb
                      ? (epirb.certificate_start_date
                          ? epirb.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (epirb.certificate_expiry_date
                          ? epirb.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirb && epirb.file_path && (
                    <>
                      <ViewFile
                        filePath={epirb.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(epirb.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    sart && sart.file_path ? "success" : "info"
                   }
                    />}
                  title="Sart"
                  dateTime={
                    sart
                      ? (sart.certificate_start_date
                          ? sart.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (sart.certificate_expiry_date
                          ? sart.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {sart && sart.file_path && (
                    <>
                      <ViewFile
                        filePath={sart.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(sart.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <SuiBox mb={2}>
              <SuiTypography
                component="label"
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                textColor="text"
              >
                {t("Battery")}
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirbSbm && epirbSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb Sbm"
                  dateTime={
                    epirbSbm
                      ? (epirbSbm.battery_start_date
                          ? epirbSbm.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (epirbSbm.battery_expiry_date
                          ? epirbSbm.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirbSbm && epirbSbm.file_path && (
                    <>
                      <ViewFile
                        filePath={epirbSbm.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(epirbSbm.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    twoWay && twoWay.file_path ? "success" : "info"
                   }
                    />}
                  title="Two Day"
                  dateTime={
                    twoWay
                      ? (twoWay.battery_start_date
                          ? twoWay.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (twoWay.battery_expiry_date
                          ? twoWay.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {twoWay && twoWay.file_path && (
                    <>
                      <ViewFile
                        filePath={twoWay.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(twoWay.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirb && epirb.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb"
                  dateTime={
                    epirb
                      ? (epirb.battery_start_date
                          ? epirb.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (epirb.battery_expiry_date
                          ? epirb.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirb && epirb.file_path && (
                    <>
                      <ViewFile
                        filePath={epirb.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(epirb.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
              <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    sart && sart.file_path ? "success" : "info"
                   }
                    />}
                  title="Sart"
                  dateTime={
                    sart
                      ? (sart.battery_start_date
                          ? sart.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (sart.battery_expiry_date
                          ? sart.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {sart && sart.file_path && (
                    <>
                      <ViewFile
                        filePath={sart.file_path}
                        style={{ marginRight: "8px" }}
                      />
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          deleteFile(sart.id);
                        }}
                      >
                        <Icon className="" fontSize="small">
                          delete
                        </Icon>
                      </SuiButton>
                    </>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        ) : dataSet.length == 0 && !loading ? (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Certificates not found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setCertificate(null);
          setExpiryDate("");
          setStartDate("");
          setBatteryExpiryDate("");
          setBatteryStartDate("");
          setFile(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
          }}
        >
          <Card>
            <SuiBox p={3}>
              <Grid container display={"flex"} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SuiBox display="flex" justifyContent="space-between">
                    <SuiTypography
                      variant="h6"
                      fontWeight="bold"
                      gutterBottom
                      textColor="text"
                    >
                      {t("Certificate Add")}
                    </SuiTypography>
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="small"
                      circular
                      iconOnly
                      onClick={handleClose}
                    >
                      <Icon className="material-icons-round" fontSize="large">
                        close
                      </Icon>
                    </SuiButton>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    justifyContent="center"
                    display="flex"
                    gutterBottom
                    textTransform="capitalize"
                  >
                    {t("Type")}
                  </SuiTypography>
                  <SuiSelect
                    label={t("Certificate Type")}
                    placeholder={t("Select Certificate")}
                    position="relative"
                    options={[
                      { value: "report", label: "REPORT" },
                      { value: "record", label: "RECORD" },
                      { value: "bnwas", label: "BNWAS" },
                      { value: "gmds_sbm", label: "GMDS SBM" },
                      { value: "gyro", label: "GYRO" },
                      { value: "ssas", label: "SSAS" },
                      { value: "two_way", label: "TWO WAY" },
                      { value: "epirb", label: " EPIRB" },
                      { value: "sart", label: "SART" },
                      { value: "ais", label: "AIS" },
                      {
                        value: "magnetic_compass",
                        label: " MAGNETIC COMPASS",
                      },
                      { value: "epirb_sbm", label: " EPIRB SBM" },
                    ]}
                    value={certificate}
                    onChange={setCertificate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Starting Date")}
                  </SuiTypography>
                  <SuiDatePicker
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiBox>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("End Date")}
                    </SuiTypography>
                    <SuiDatePicker
                      value={expiryDate}
                      onChange={setExpiryDate}
                    />
                  </SuiBox>
                </Grid>
                <Grid item width={400} ml={"auto"} mr={"auto"}>
                  <Dropzone
                    id={id}
                    type={"vesselcertificate"}
                    returnValue={setFile}
                  />
                </Grid>
                {certificate &&
                  (certificate.value === "epirb" ||
                    certificate.value === "sart" ||
                    certificate.value === "epirb_sbm" ||
                    certificate.value === "two_way") && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <SuiTypography
                          variant="h6"
                          fontWeight="bold"
                          gutterBottom
                          textColor="text"
                        >
                          {t("BatteryAdd")}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SuiBox>
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Starting Date")}
                          </SuiTypography>
                          <SuiDatePicker
                            value={batteryStartDate}
                            onChange={setBatteryStartDate}
                          />
                        </SuiBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SuiBox>
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("End Date")}
                          </SuiTypography>
                          <SuiDatePicker
                            value={batteryExpiryDate}
                            onChange={setBatteryExpiryDate}
                          />
                        </SuiBox>
                      </Grid>
                    </>
                  )}
                <Grid item xs={12} sm={12}>
                  <SuiBox display={"flex"} justifyContent={"center"}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      size="small"
                      onClick={() => sentUpdate()}
                      circular
                      disabled={
                        !certificate ||
                        !expiryDate ||
                        !startDate ||
                        ((certificate.value === "epirb" ||
                          certificate.value === "sart" ||
                          certificate.value === "epirb_sbm" ||
                          certificate.value === "two_way") &&
                          (!batteryExpiryDate || !batteryStartDate)) ||
                        !file
                      }
                    >
                      {t("Save")}
                    </SuiButton>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </Grid>
      </Modal>
    </Card>
  );
}

export default CertificateInformation;
