/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { auth } from '../../../firebase/firebase';
import SignOutLink from 'components/SignOut';
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import {Link , NavLink} from "react-router-dom";
import SuiButton from "components/SuiButton";
import { useSoftUIController } from "context";



// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "components/Navbars/DefaultNavbar/DefaultNavbarLink";

// DefaultNavbar dropdown menus
// import PagesMenu from "components/Navbars/DefaultNavbar/Menus/PagesMenu";
// import AuthenticationMenu from "components/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
// import ApplicationsMenu from "components/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import DropdownMenuDB from "components/Navbars/DefaultNavbar/Menus/DropdownMenuDB";
import DropdownMenuOrderAdd from "components/Navbars/DefaultNavbar/Menus/DropdownMenuOrderAdd";
import SingleNavbarLink from "components/Navbars/DefaultNavbar/SingleNavbarLink";
// import DocsMenu from "components/Navbars/DefaultNavbar/Menus/DocsMenu";
import { useTranslation } from "react-i18next";

function DefaultNavbarMobile({ routes,light, open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const { t, i18n } = useTranslation();

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(100vw - 13rem)` } }}
    >
      <SuiBox px={0.5}>
        {/* <DefaultNavbarLink
          name="pages"
          collapseStatus={openCollapse === "pages"}
          onClick={() => handleSepOpenCollapse("pages")}
        >
          <SuiBox maxHeight="16rem" overflow="auto">
            <PagesMenu routes={routes} mobileMenu />
          </SuiBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="authentication"
          collapseStatus={openCollapse === "authentication"}
          onClick={() => handleSepOpenCollapse("authentication")}
        >
          <SuiBox maxHeight="16rem" overflow="auto">
            <AuthenticationMenu routes={routes} mobileMenu />
          </SuiBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="applications"
          collapseStatus={openCollapse === "applications"}
          onClick={() => handleSepOpenCollapse("applications")}
        >
          <SuiBox maxHeight="16rem" overflow="auto">
            <ApplicationsMenu routes={routes} mobileMenu />
          </SuiBox>
        </DefaultNavbarLink> */}
         <SingleNavbarLink
            name={t("Dashboard")}
            route="/dashboard"
            light={light}
          />
           <SingleNavbarLink
            name={t("Certificate")}
            route="/certificate"
            light={light}
          />
          {/* <SingleNavbarLink
            name="Kanban"
            route="/kanbanpage"
            light={light}
          /> */}
           {/* <SingleNavbarLink
            name="Vehicles"
            route="/db/add"
            light={light}
          /> */}
          <SingleNavbarLink
            name="Active Orders"
            route="/order/active"
            light={light}
          />
          <DefaultNavbarLink
            name="database"
            collapseStatus={openCollapse === "ecommerce"}
            onClick={() => handleSepOpenCollapse("ecommerce")}
          >
            <SuiBox maxHeight="16rem" overflow="auto">
              <DropdownMenuDB routes={routes} mobileMenu />
            </SuiBox>
          </DefaultNavbarLink>  
          <SingleNavbarLink
            name={"Human Resources"}
            route="/human/resources"
            light={light}
          /> 
          <SingleNavbarLink
            name={t("Announcement")}
            route="/announcement"
            light={light}
          />    
        {/* <DefaultNavbarLink
          name="docs"
          collapseStatus={openCollapse === "docs"}
          onClick={() => handleSepOpenCollapse("docs")}
        >
          <SuiBox maxHeight="16rem" overflow="auto">
            <DocsMenu routes={routes} mobileMenu />
          </SuiBox>
        </DefaultNavbarLink> */}
      </SuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  // placement: PropTypes.string,
};

export default DefaultNavbarMobile;
