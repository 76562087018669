/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Page components
import FormFieldError from "components/FormFieldError";
import { useTranslation } from "react-i18next";

function About({ formData }) {
  const { t, i18n } = useTranslation();

  const { formField, values, errors, touched } = formData;
  const { message, subject } = formField;
  const { message: messageV, subject: subjectV } = values;

  return (
    <SuiBox>
      <SuiBox lineHeight={0} mb={1.625}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Announcement")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("AnnouncementDescription")}
        </SuiTypography>
      </SuiBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormFieldError
            type={subject.type}
            label={t(subject.label)}
            name={subject.name}
            value={subjectV}
            placeholder={t(subject.placeholder)}
            // error={errors.subject && touched.subject}
            // success={subjectV.length > 0 && !errors.subject}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormFieldError
            type={message.type}
            label={t(message.label)}
            name={message.name}
            value={messageV}
            placeholder={t(message.placeholder)}
            rows={4}
            multiline
            error={errors.message && touched.message}
            success={messageV.length > 0 && !errors.message}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default About;
