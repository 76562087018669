/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Dropzone from "components/Dropzone";
import { useTranslation } from "react-i18next";

function AttachmentsEdit({ id }) {
  const [file, setFile] = useState(null);

  const { t } = useTranslation();

  return (
    <Card className="overflow-visible">
      <SuiBox p={3}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Upload Additional Files")}
        </SuiTypography>
        <SuiBox mt={1} pt={1}>
          <Grid item xs={12} lg={12}>
            <SuiBox ml={0.5} lineHeight={0} width="100%" display="inline-block">
              <Dropzone jobId={id} type={"orderother"} returnValue={setFile} />
            </SuiBox>
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default AttachmentsEdit;
