/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import ActionCell from "components/ActionCell/vesselDetail";
function dataTableData({ dataset}) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item) => {
      data.push({
        name: item.name,
        manufacter: item.manufacter,
        userType: item.type,
        serialNo: item.serial_no,
        BattExp: item.battery_expiry_date,
        action: <ActionCell id={item.id} />,
      });
    });
  }
  return {
    columns: [
      { Header: "Name", accessor: "name", width: "auto" },
      { Header: "Manufacter", accessor: "manufacter", width: "auto" },
      { Header: "Type", accessor: "userType", width: "auto" },
      { Header: "Serial No", accessor: "serialNo", width: "auto" },
      { Header: "Batt. Exp.", accessor: "BattExp", width: "auto" },
      { Header: "Process", accessor: "action", width: "auto" },
    ],

    rows: [...data],
  };
}

dataTableData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
dataTableData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default dataTableData;
