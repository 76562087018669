import React from "react";
import { Link } from "react-router-dom";

const MainBanner = () => (
    <div style={{display:"flex" , position:"absolute",top:0,left:0,alignSelf: "center"}}>
        
            <Link to="/">
                <img src="/logo_avs.png" alt="AVS Logo" height={"57px"}  />
            </Link>
       
    </div>
);

export default MainBanner;
