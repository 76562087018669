/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ActionCellLocation({id}) {
  const { t, i18n } = useTranslation();
  return (
    <SuiBox ml={2}>
      <SuiBox component={Link} to={"/db/location/edit/"+id} >
        <SuiTypography
          variant="body1"
          textColor="secondary"
          customClass="cursor-pointer line-height-0"
        >
          <Tooltip title={t("Edit")} placement="top">
            <Icon className="">edit</Icon>
          </Tooltip>
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default ActionCellLocation;