import SignUpPage from "pages/Auth/SignUp";
import SignInPage from "pages/Auth/SignIn";
import PasswordForgetPage from "pages/Auth/PasswordForget";
import AccountPage from "pages/Auth/Account";
import OrderHistory from "pages/Orders/history";
import OrderAdd from "pages/Orders/add";
import OrderAddLand from "pages/Orders/addLand";
import OrderEdit from "pages/Orders/edit";
import OrderDetail from "pages/Orders/detail";
import OrderActive from "pages/Orders/active";
import KanbanPage from "pages/Kanban";
import DashboardPage from "pages/Dashboard";
import Error404 from "pages/Error/404";
import Error500 from "pages/Error/500";
import LocationPage from "pages/Location";
import LocationAdd from "pages/Location/add";
import LocationEdit from "pages/Location/edit";
import VesselPage from "pages/Vessel";
import Vehicles from "pages/Vehicles";
import VesselAdd from "pages/Vessel/add";
import Certificate from "pages/Certificate";
import Announcement from "pages/Announcement";
import AnnouncementAdd from "pages/Announcement/add";
import HumanResources from "pages/HumanResources";
import HumanResourcesEdit from "pages/HumanResources/edit";
import HumanResourcesDetail from "pages/HumanResources/detail";
import CertificateEdit from "pages/Certificate/edit";
import VesselDetail from "pages/Vessel/detail";
import EditVessel from "pages/Vessel/edit";
import PersonAdd from "pages/PersonAdd";
import Training from "pages/Training";
import TrainingAdd from "pages/Training/TrainingAdd"
import TrainingEdit from "pages/Training/TrainingEdit"
import VehicleAdd from "pages/Vehicles/add";

const routes = [

  {
    name: "Sign Up",
    key: "signup",
    route: "/signup",
    component: SignUpPage,
    noCollapse: true,
  },
  {
    name: "Training",
    key: "training",
    route: "/training",
    component: Training,
    noCollapse: true,
  },
  {
    name: "Training Add",
    key: "addtraining",
    route: "/training/add",
    component: TrainingAdd,
    noCollapse: true,
  },
  {
    name: "Training Edit",
    key: "edittraining",
    route: "/training/edit/:id",
    component: TrainingEdit,
    noCollapse: true,
  },
  {
    name: "Sign In",
    key: "signin",
    route: "/signin",
    component: SignInPage,
    noCollapse: true,
  },
  // {
  //   name: "Account",
  //   key: "account",
  //   route: "/account",
  //   component: AccountPage,
  //   noCollapse: true,
  // },
  // {
  //   name: "Account",
  //   key: "account",
  //   route: "/account/edit/:id",
  //   component: AccountEdit,
  //   noCollapse: true,
  // },
  {
    name: "Main Page",
    key: "landingpage",
    route: "/",
    component: OrderActive,
    noCollapse: true,
  },
  {
    name: "Active Orders",
    key: "orderactive",
    route: "/order/active",
    component: OrderActive,
    noCollapse: true,
  },
  {
    name: "Certificate",
    key: "certificate",
    route: "/certificate",
    component: Certificate,
    noCollapse: true,
  },
  {
    name: "Announcement",
    key: "announcement",
    route: "/announcement",
    component: Announcement,
    noCollapse: true,
  },
  {
    name: "Add Announcement",
    key: "addannouncement",
    route: "/announcement/add",
    component: AnnouncementAdd,
    noCollapse: true,
  },
  {
    name: "Add Order",
    key: "orderadd",
    route: "/order/add",
    component: OrderAdd,
    noCollapse: true,
  },

  {
    name: "Order History",
    key: "orderhistory",
    route: "/order/history",
    component: OrderHistory,
    noCollapse: true,
  },
  {
    name: "Order Detail",
    key: "orderdetail",
    route: "/order/detail/:id",
    component: OrderDetail,
    noCollapse: true,
  },
  {
    name: "Human Resources Edit",
    key: "humanResourcesedit",
    route:"/human/resources/edit/:id",
    component: HumanResourcesEdit,
    noCollapse: true,
  },
  {
    name: "Human Resources Detail",
    key: "HumanResourcesDetail",
    route: "/human/resources/detail/:id",
    component: HumanResourcesDetail,
    noCollapse: true,
  },
  {
    name: "Vessel Detail",
    key: "vesseldetail",
    route: "/db/Vessel/detail/:id",
    component: VesselDetail,
    noCollapse: true,
  },
  {
    name: "Vessel Edit",
    key: "vesseledit",
    route: "/db/vessel/edit/:id",
    component: EditVessel,
    noCollapse: true,
  },
  {
    name: "Certificate Edit",
    key: "certificateEdit",
    route: "/db/certificate/edit/:id",
    component: CertificateEdit,
    noCollapse: true,
  },
  {
    name: "Edit Order",
    key: "orderedit",
    route: "/order/edit/:id",
    component: OrderEdit,
    noCollapse: true,
  },

  {
    name: "Add Order",
    key: "orderadd",
    route: "/order/add",
    component: OrderAdd,
    noCollapse: true,
  },
  {
    name: "Add Person",
    key: "personadd",
    route: "/person/add",
    component: PersonAdd,
    noCollapse: true,
  },
  {
    name: "Add Land Order",
    key: "orderaddland",
    route: "/order/add-land",
    component: OrderAddLand,
    noCollapse: true,
  },
  {
    name: "Forgot Password",
    key: "passwordforget",
    route: "/pw-forget",
    component: PasswordForgetPage,
    noCollapse: true,
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/404",
    component: Error404,
    noCollapse: true,
  },
  {
    name: "Kanban Page",
    key: "kanbanpage",
    route: "/kanbanpage",
    component: KanbanPage,
    noCollapse: true,
  },

  {
    name: "Human Resources",
    key: "humanresources",
    route: "/human/resources",
    component: HumanResources,
    noCollapse: true,
  },
  {
    name: "Dashboard Page",
    key: "dashboardpage",
    route: "/dashboard",
    component: DashboardPage,
    noCollapse: true,
  },
  {
    name: "Error 500",
    key: "error-500",
    route: "/500",
    component: Error500,
    noCollapse: true,
  },
  {
    name: "List Locations",
    key: "locationlist",
    route: "/db/location/list",
    component: LocationPage,
    noCollapse: true,
  },
  {
    name: "Add Locations",
    key: "locationadd",
    route: "/db/location/add",
    component: LocationAdd,
    noCollapse: true,
  },
  {
    name: "Add Vehicle",
    key: "vehicleadd",
    route: "/db/vehicle/add",
    component: VehicleAdd,
    noCollapse: true,
  },
  {
    name: "Edit Locations",
    key: "editlocations",
    route: "/db/location/edit/:id",
    component: LocationEdit,
    noCollapse: true,
  },
  {
    name: "List Vessels",
    key: "vessellist",
    route: "/db/vessel/list",
    component: VesselPage,
    noCollapse: true,
  },
  {
    name: "Vehicles",
    key: "automotive",
    route: "/db/vehicles",
    component: Vehicles,
    noCollapse: true,
  },
  
  {
    name: "Add Vessel",
    key: "vesseladd",
    route: "/db/vessel/add",
    component: VesselAdd,
    noCollapse: true,
  },
];

export default routes;
