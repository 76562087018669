/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import TrcMarine from "../../../components/TrcMarine";
import IconButton from "@mui/material/IconButton";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SingleNavbarLink from "components/Navbars/DefaultNavbar/SingleNavbarLink";
import DefaultNavbarMobile from "components/Navbars/DefaultNavbar/DefaultNavbarMobile";
import NotificationItem from "components/Items/NotificationItem";
import moment from "moment";
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import styles from "components/Navbars/DefaultNavbar/styles/defaultNavbar";
import PagesMenu from "components/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "components/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import DropdownMenuDB from "components/Navbars/DefaultNavbar/Menus/DropdownMenuDB";
import DropdownMenuOrderAdd from "components/Navbars/DefaultNavbar/Menus/DropdownMenuOrderAdd";
import ApplicationsMenu from "components/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import DocsMenu from "components/Navbars/DefaultNavbar/Menus/DocsMenu";
import { useSoftUIController } from "context";
import { auth } from "../../../firebase/firebase";
import SignOutLink from "components/SignOut";
import { useTranslation } from "react-i18next";
import Document from "assets/Icons/Document";
import { useHistory } from "react-router-dom";

function DefaultNavbar({ routes, transparent, light, absolute, action }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    handleCloseLangMenu();
    // i18 language switcher for moment.js
    moment.locale(lng);
    i18n.changeLanguage(lng);
  };
  const [dataSet, setDataSet] = useState([]);
  const classes = styles({ transparent, absolute, light });
  const [pagesMenu, setPagesMenu] = useState(false);
  const [authenticationMenu, setAuthenticationMenu] = useState(false);
  const [ecommerceMenu, setEcommerceMenu] = useState(false);
  const [dropdownMenuDB, setDropdownMenuDB] = useState(false);
  const [dropdownMenuOrderAdd, setDropdownMenuOrderAdd] = useState(false);
  // const [dropdownMenuOrders, setDropdownMenuOrders] = useState(false);
  const [applicationsMenu, setApplicationsMenu] = useState(false);
  const [docsMenu, setDocsMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [navbarType, setNavbarType] = useState("sticky");
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(null);
  const [openMenuNotification, setOpenMenuNotification] = useState(null);
  const [openLangMenu, setOpenLangMenu] = useState(null);

  const openPagesMenu = ({ currentTarget }) => setPagesMenu(currentTarget);
  const closePagesMenu = () => setPagesMenu(false);
  const openAuthenticationMenu = ({ currentTarget }) =>
    setAuthenticationMenu(currentTarget);
  const closeAuthenticationMenu = () => setAuthenticationMenu(false);
  const openEcommerceMenu = ({ currentTarget }) =>
    setEcommerceMenu(currentTarget);
  const closeEcommerceMenu = () => setEcommerceMenu(false);
  const openDropdownMenuDB = ({ currentTarget }) =>
    setDropdownMenuDB(currentTarget);
  const closeDropdownMenuDB = () => setDropdownMenuDB(false);
  const openDropdownMenuOrderAdd = ({ currentTarget }) =>
    setDropdownMenuOrderAdd(currentTarget);
  const closeDropdownMenuOrderAdd = () => setDropdownMenuOrderAdd(false);
  // const openDropdownMenuOrders = ({ currentTarget }) => setDropdownMenuOrders(currentTarget);
  // const closeDropdownMenuOrders = () => setDropdownMenuOrders(false);
  const openApplicationsMenu = ({ currentTarget }) =>
    setApplicationsMenu(currentTarget);
  const closeApplicationsMenu = () => setApplicationsMenu(false);
  const openDocsMenu = ({ currentTarget }) => setDocsMenu(currentTarget);
  const closeDocsMenu = () => setDocsMenu(false);
  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleOpenMenuNotification = ({ currentTarget }) =>
    setOpenMenuNotification(currentTarget);
  const handleOpenLangMenu = ({ currentTarget }) =>
    setOpenLangMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleCloseMenuNotification = () => setOpenMenuNotification(null);
  const handleCloseLangMenu = () => setOpenLangMenu(null);

  useEffect(() => {
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query announcement {
              announcement(limit: 3, order_by: { created_at: desc }) {
                message
                subject
                uid
                id
                users {
                  name
                }
                created_at
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(result.data.announcement);
        });
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenuNotification}
      anchorReference={openMenuNotification ? "anchorEl" : "anchorPosition"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuNotification)}
      onClose={handleCloseMenuNotification}
      sx={{ marginTop: "1rem" }}
    >
      <NotificationItem
        color="info"
        image={<Document color="white" />}
        title={[
          dataSet[0] && dataSet[0].subject.slice(0, 20),
          "...".replace(/\s/g, "&nbsp;"),
        ]}
        date={dataSet[0] && moment(dataSet[0].created_at).fromNow()}
        onClick={() => history.push("/announcement")}
      />

      <NotificationItem
        color="info"
        image={<Document color="white" />}
        title={[
          dataSet[1] && dataSet[1].subject.slice(0, 20),
          "...".replace(/\s/g, "&nbsp;"),
        ]}
        date={dataSet[1] && moment(dataSet[1].created_at).fromNow()}
        onClick={() => history.push("/announcement")}
      />
      <NotificationItem
        color="info"
        image={<Document color="white" />}
        title={[
          dataSet[2] && dataSet[2].subject.slice(0, 20),
          "...".replace(/\s/g, "&nbsp;"),
        ]}
        date={dataSet[2] && moment(dataSet[2].created_at).fromNow()}
        onClick={() => history.push("/announcement")}
      />
    </Menu>
  );

  return (
    <AppBar position={navbarType} color="inherit" className={classes.navbar}>
      <SuiBox
        customClass={classes.defaultNavbar}
        py={1}
        px={{
          xs: transparent ? 4 : 5,
          sm: transparent ? 2 : 5,
          lg: transparent ? 0 : 5,
        }}
      >
        <SuiBox sx={{ display: "flex" }}>
          <TrcMarine />
        </SuiBox>
        <SuiBox
          color="inherit"
          display={{ xs: "none", md: "none", lg: "flex" }}
          m={0}
          p={0}
        >
          <SingleNavbarLink
            name={"Dashboard"}
            route="/dashboard"
            light={light}
          />
          <SingleNavbarLink
            name={"Vehicle"}
            route="/db/vehicles"
            light={light}
          />
          <SingleNavbarLink
            name={"Certificate"}
            route="/certificate"
            light={light}
          />
          <SingleNavbarLink name="Orders" route="/order/active" light={light} />
          {/* 
          <SingleNavbarLink
            name="Past Orders"
            route="/order/history"
            light={light}
          />
 */}

          {/* <SingleNavbarLink
            name="Training Records"
            route="/training"
            light={light}
          /> */}
          {/* 
          <DefaultNavbarLink
            name={"Orders"}
            openHandler={openDropdownMenuOrders}
            closeHandler={closeDropdownMenuOrders}
            light={light}
          /> */}
          {/* <SingleNavbarLink
            name="Add Order"
            route="/order/add"
            light={light}
          />  */}
          <SingleNavbarLink
            name={"database"}
            onClick={handleOpenMenu}
            closeHandler={handleCloseMenu}
            light={light}
            menu
          />
          <Menu
            anchorEl={openMenu}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={handleCloseMenu}
              component={Link}
              to="/db/vessel/list"
              size="small"
              circular="true"
            >
              <ListItemIcon>
                <DirectionsBoatFilledIcon  fontSize="small" />
              </ListItemIcon>{" "}
              {t("List Vessels")}
            </MenuItem>
            <MenuItem
              onClick={handleCloseMenu}
              component={Link}
              to="/db/location/list"
              size="small"
              circular="true"
            >
              <ListItemIcon>
                <LocationOnIcon fontSize="small" />
              </ListItemIcon>{" "}
              {t("List Locations")}
            </MenuItem>
          </Menu>
          {/* <SingleNavbarLink
            name={"Human Resources"}
            route="/human/resources"
            light={light}
          />
          <SingleNavbarLink
            name={"Announcement"}
            route="/announcement"
            light={light}
          /> */}
        </SuiBox>
        <SuiBox display="flex">
          <SuiBox mr={2} alignSelf="center">
            <IconButton
              size="small"
              color="inherit"
              className={classes.navbar_icon_button}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenuNotification}
            >
              <Icon className={light ? "text-white" : "text-dark"}>
                notifications
              </Icon>
            </IconButton>
            {renderMenu()}
          </SuiBox>
          <SuiBox sx={{ display: "flex" }}>
            <SuiBox
              sx={{ alignSelf: "center", paddingRight: 0 }}
              display={{ lg: "inline-block" }}
            >
              <SuiButton
                id="lang-button"
                aria-controls="lang-menu"
                variant="outlined"
                buttonColor="info"
                size="small"
                onClick={handleOpenLangMenu}
                circular
                iconOnly
              >
                <Icon>translate</Icon>
              </SuiButton>
              <Menu
                id="lang-menu"
                aria-labelledby="lang-button"
                anchorEl={openLangMenu}
                open={Boolean(openLangMenu)}
                onClose={handleCloseLangMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => changeLanguage("en")}>
                  English
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("tr")}>Türkçe</MenuItem>
              </Menu>
            </SuiBox>
            <SuiBox
              sx={{ alignSelf: "center" }}
              display={{ lg: "inline-block" }}
            >
              <SignOutLink user={auth.currentUser} />
            </SuiBox>
            <SuiBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color="inherit"
              customClass="cursor-pointer"
              onClick={openMobileNavbar}
            >
              <Icon className="" fontSize="default">
                {mobileNavbar ? "close" : "menu"}
              </Icon>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <PagesMenu routes={routes} open={pagesMenu} close={closePagesMenu} />
      <AuthenticationMenu
        routes={routes}
        open={authenticationMenu}
        close={closeAuthenticationMenu}
      />
      <DropdownMenuDB
        routes={routes}
        open={dropdownMenuDB}
        close={closeDropdownMenuDB}
      />
      {/* <DropdownMenuOrders routes={routes} open={dropdownMenuOrders} close={closeDropdownMenuOrders} /> */}
      <DropdownMenuOrderAdd
        routes={routes}
        open={dropdownMenuOrderAdd}
        close={closeDropdownMenuOrderAdd}
      />
      <ApplicationsMenu
        routes={routes}
        open={applicationsMenu}
        close={closeApplicationsMenu}
      />
      <DocsMenu routes={routes} open={docsMenu} close={closeDocsMenu} />
      {mobileView && (
        <DefaultNavbarMobile
          routes={routes}
          open={mobileNavbar}
          close={closeMobileNavbar}
        >
          Hello
        </DefaultNavbarMobile>
      )}
    </AppBar>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
