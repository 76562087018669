/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components

import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";
import EquipmentList from "components/data/EquipmentList";
import DataTable from "components/Tables/DataTable";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function EquipmentEdit({ id }) {
  const history = useHistory();
  const [inputList, setInputList] = useState([{ InputText: "" }]);
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const [name, setName] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [manufacter, setManufacter] = useState(null);
  const [type, setType] = useState(null);
  const [battExp, setBattExp] = useState("");
  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => {
    setOpen(false);
    setName(null);
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { InputText: "" }]);
  };
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const DataTableStyle = {
    overflowY: "auto",
    width: "auto",
    height: "550px",
    position: "relative",
  };

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser, dataSet]);

  const getData = async () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessel_equipments {
              vessel_equipments(where: {vessel_id: {_eq: ${id}}}) {
                id
                name
                manufacter
                type
                serial_no
                battery_expiry_date
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(EquipmentList({ dataset: result.data.vessel_equipments }));
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const sentUpdate = () => {
    handleClose();
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation insert_vessel_equipments_one {
            insert_vessel_equipments_one(object: {
              name: ${name ? JSON.stringify(name) : null}, 
              manufacter: ${manufacter ? JSON.stringify(manufacter) : null}, 
              serial_no: ${serialNo ? JSON.stringify(serialNo) : null}, 
              type: ${type ? JSON.stringify(type) : null}, 
              battery_expiry_date: ${battExp ? JSON.stringify(battExp) : null}, 
              vessel_id: ${id},
            }) {
              id
            }
          }
          `,
        })
        .then((result) => {
          getData();
          Swal.fire(t("Successfully added!"), " ", "success");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            margin: "5%",
          }}
        >
          <Card>
            <SuiBox p={3}>
              <Grid container display={"flex"} spacing={1}>
                <Grid item xs={12} sm={12}>
                  <SuiBox display="flex" justifyContent="space-between">
                    <SuiTypography
                      variant="h6"
                      fontWeight="bold"
                      gutterBottom
                      textColor="text"
                    >
                      {t("Equipment Add")}
                    </SuiTypography>
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="small"
                      circular
                      iconOnly
                      onClick={handleClose}
                    >
                      <Icon className="material-icons-round" fontSize="large">
                        close
                      </Icon>
                    </SuiButton>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Name")}
                  </SuiTypography>
                  <SuiInput
                    name="InputText"
                    placeholder={t("ex. 365Tech.")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.2}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                    marginRight={1}
                  >
                    {t("Manufacter")}
                  </SuiTypography>
                  <SuiInput
                    name="InputText"
                    placeholder="ex. 365Tech."
                    value={manufacter}
                    onChange={(e) => setManufacter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                    marginRight={1}
                  >
                    {t("Type")}
                  </SuiTypography>
                  <SuiInput
                    name="InputText"
                    placeholder="ex. 365Tech."
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                    marginRight={1}
                  >
                    {t("Serial No")}
                  </SuiTypography>
                  <SuiInput
                    name="InputText"
                    placeholder="ex. 365Tech."
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.3}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("Batt. Exp.")}
                  </SuiTypography>
                  <SuiDatePicker
                    //name={eta.name}
                    value={battExp}
                    onChange={setBattExp}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SuiBox mt={2} display={"flex"} justifyContent={"center"}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="info"
                        size="small"
                        onClick={() => sentUpdate()}
                        disabled = {name ? false : true}
                      >
                        {t("Save")}
                      </SuiButton>
                   
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </Grid>
      </Modal>
      <SuiBox display="flex" justifyContent="space-between" p={3}>
        <SuiTypography
          variant="h6"
          fontWeight="bold"
          gutterBottom
          textColor="text"
        >
          {t("Equipment List")}
        </SuiTypography>
        <SuiBox marginLeft={1}>
          <SuiButton
            variant="gradient"
            size="medium"
            iconOnly
            buttonColor="success"
            onClick={() => setOpen(true)}
          >
            {" "}
            +
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox height={600}>
        <SuiBox mt={1} style={DataTableStyle}>
          {dataSet && !loading ? (
            <DataTable
              table={dataSet}
              entriesPerPage={{
                defaultValue: 16,
                entries: [16, 32, 64],
              }}
              canSearch
            />
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="AVS Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default EquipmentEdit;
