/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelectFormik from "components/SuiSelectFormik";
import SuiDatePickerFormik from "components/SuiDatePickerFormik";

// Page components
import FormFieldError from "components/FormFieldError";
import FormField from "components/FormField";
import { useTranslation } from "react-i18next";
function Address({ formData, staffList, locList, shippingMethodList}) {
  const [staffListLabel, setStaffListLabel] = useState([]);
  const [locListLabel, setLocListLabel] = useState([]);
  const [shippingMethodListLabel, setShippingMethodListLabel] = useState([]);
  // const [operatorsV, setOperatorsV] = useState();
  const { formField, values, errors, touched } = formData;
  const { operators, shippingMethod, arrivalLoc, plateNumber ,engineSupplier, eta, etb, etd } = formField;
  const { operators: operatorsV, shippingMethod: shippingMethodV, arrivalLoc: arrivalLocV, engineSupplier: engineSupplierV, eta: etaV, etb: etbV, etd: etdV, plateNumber: plateNumberV  } = values;
  const { t, i18n } = useTranslation(); 
  useEffect(() => {
    setStaffListLabel(staffList.map(({
      name: label,
      ...rest
    }) => ({
      label,
      ...rest
    })));
    setLocListLabel(locList.map(({
      name: label,
      ...rest
    }) => ({
      label,
      ...rest
    }),
    ));
    setShippingMethodListLabel(shippingMethodList.map(({
      name: label,
      ...rest
    }) => ({
      label,
      ...rest
    })));
  }, []);

  return (
    <SuiBox>
      <SuiTypography variant="h6" fontWeight="bold" textColor="text">
        {t("Delivery Informations")}
      </SuiTypography>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                {operators.label}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={operators.name}
              value={operatorsV}
              placeholder={operators.placeholder}
              options={staffListLabel}
              size="large"
              isMulti
            />
          </Grid>
          <Grid container display={"flex"} marginLeft={0} spacing={3}>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {shippingMethod.label}
                </SuiTypography>
              </SuiBox>
              <SuiSelectFormik
                name={shippingMethod.name}
                value={shippingMethodV}
                placeholder={shippingMethod.placeholder}
                options={shippingMethodListLabel}
                isClearable
              />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormFieldError
                  type={plateNumber.type}
                  label={plateNumber.label}
                  name={plateNumber.name}
                  value={plateNumberV}
                  placeholder={plateNumber.placeholder}
                />
            
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {arrivalLoc.label}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={arrivalLoc.name}
              value={arrivalLocV}
              placeholder={arrivalLoc.placeholder}
              options={locListLabel}
              isClearable
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
