/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PropTypes from "prop-types";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import trLocale from "date-fns/locale/tr";
import SuiInput from "../SuiInput";
import SuiBox from "../SuiBox";
import SuiTypography from "components/SuiTypography";

// function SuiDatePicker({ input, ...rest }) {
//   return (
//     <Flatpickr
//       {...rest}
//       render={({ defaultValue }, ref) => (
//         <SuiInput {...input} defaultValue={defaultValue} inputRef={ref} />
//       )}
//     />
//   );
// }

function SuiDateRangePicker({ input, value, onChange, ...rest }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
      <DateRangePicker
        startText="Check-in"
        endText="Check-out"
        value={value}
        onChange={onChange}
        mask={"__.__.____"}
        {...rest}
        // renderInput={({ defaultValue }, ref) => (
        //   <SuiBox>
        //     <SuiInput {...input} defaultValue={value} inputRef={ref} />
        //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //       <input ref={inputRef} {...inputProps} />
        //       {InputProps?.endAdornment}
        //     </Box>
        //   </SuiBox>
        // )}
        renderInput={(startProps, endProps) => (
          <>
            <SuiInput {...startProps} />
            <SuiBox sx={{ mx: 2 }}>
              <SuiTypography
                variant="caption"
                fontWeight="regular"
                textColor="info"
                alignSelf="center"
              >
                <MoreTimeIcon />
              </SuiTypography>
            </SuiBox>
            <SuiInput {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}

// Setting default values for the props of SuiDatePicker
SuiDateRangePicker.defaultProps = {
  input: {},
};

// Typechecking props for the SuiDatePicker
SuiDateRangePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDateRangePicker;
