/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Formik, Form } from "formik";


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import initialValues from "components/OrderSchemas/initialValues";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/FooterTheme";
import form from "components/OrderSchemas/form";

// Wizard page components
import About from "components/Training/About";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Account from "components/Training/Account";
import Address from "components/Training/Address";
import pageRoutes from "constants/pageRoutes";

import trainingValidation from "components/OrderSchemas/trainingValidation";
import trainingForm from "components/OrderSchemas/trainingForm";
import TrainingInitial from "components/OrderSchemas/TrainingInitial";


import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import withAuthorization from "../../withAuthorization"; //redirects to sign in if user not signed in
import { auth } from '../../../firebase/firebase';

function getSteps() {
  return ["About"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <About formData={formData} />;
    // case 1:
    //   return <Account />;
    // case 2:
    //   return <Address />;
    default:
      return null;
  }
}

function Wizard() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = trainingForm;
  const currentValidation = trainingValidation[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const history = useHistory();
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  // const [organisation,setOrganisation ] = useState("")
  // const [trainingDate, settrainingDate] = useState("")
  // const [trainee, setBatteryExpiryDate] = useState("")
  //  const [educator, setEducator] = useState("")
  const [open, setOpen] = useState("")

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 6px)',
      right: 'calc(50% + 6px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    zIndex: 1,
    ...(ownerState.active && {
      color: theme.palette.success.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: "#ffffff",
      zIndex: 1,
      fontSize: 18,
      backgroundColor: theme.palette.success.main,
      borderRadius: '50%',
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    setOpen(false)
    if (auth.currentUser) {

      const userToken = getAccessToken();
      const uid = auth.currentUser.uid

      const headers = {
        Authorization: `Bearer ${userToken}`,
      }
      const client = new ApolloClient({
        uri: 'https://hasura-trc.365tech.dev/v1/graphql',
        headers,
        cache: new InMemoryCache()
      });

      client
        .mutate({
          mutation: gql`
          mutation insert_vessel_certificates_one {
            insert_training_one(object: {
               organisation: ${values.organisation ? JSON.stringify(values.organisation) : null}, 
               training_date: ${values.trainingdate ? JSON.stringify(values.trainingdate) : null}, 
               trainee: ${values.trainee ? JSON.stringify(values.trainee) : null}, 
               educator:  ${values.educator ? JSON.stringify(values.educator) : null}, 
                }) {
              id
             
            }
          }
          `
        })
        .then(result => {
          alert("Yeni Kayıt Eklendi...")
          
        }).catch(err => {
          console.log(err)
        })
        history.push("/training");
    }
    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };


  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <DashboardLayout>
      <DefaultNavbar
        routes={pageRoutes}
      />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} connector={<QontoConnector />} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={TrainingInitial}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="overflow-visible h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          setFieldValue,
                          errors,
                        })}
                        <SuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SuiBox />
                          ) : (
                            <SuiButton variant="gradient" buttonColor="light" onClick={handleBack}>
                              back
                            </SuiButton>
                          )}
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            buttonColor="dark"
                          >
                            {isLastStep ? "add" : "next"}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Wizard;
