/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import { auth, db } from "../../firebase";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import axios from "axios";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
// NewUser page components
import PersonInfo from "components/FormPage/PersonInfo";
//import PersonPhoto from "components/FormPage/PersonPhoto";

// NewUser layout schemas for form and form feilds
import validationsPerson from "components/OrderSchemas/validationsPerson";
import formPerson from "components/OrderSchemas/formPerson";
import initialValuesPerson from "components/OrderSchemas/initialValuesPerson";
import pageRoutes from "constants/pageRoutes";

function getSteps() {
  return ["Personal Informations"];
}

function ProductsList({ id }) {
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [locList, setLocList] = useState([]);
  const [shippingMethodList, setShippingMethodList] = useState([]);
  const { t, i18n } = useTranslation();

  const history = useHistory();

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <PersonInfo formData={formData} />;
      // case 1:
      //   return <PersonPhoto formData={formData} />;
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = formPerson;
  const currentValidation = validationsPerson[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const userToken = getAccessToken();

    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const client = new ApolloClient({
      uri: "https://hasura-trc.365tech.dev/v1/graphql",
      headers,
      cache: new InMemoryCache(),
    });

    auth
      .doCreateUserWithEmailAndPassword(values.email, values.password)
      .then((authUser) => {
        db.doCreateUser(
          authUser.user.uid,
          values.name,
          values.email,
          values.userType.value
        )
          .then(() => {
            const fetch = async () => {
              try {
                const { data } = await axios.get(
                  `https://europe-west1-trc-marine.cloudfunctions.net/refreshToken?uid=${authUser.user.uid}`
                );
                console.log("claims data",data)
              } catch (err) {
                console.error(err);
              }
            };
            fetch();
            client
              .mutate({
                mutation: gql`
                  mutation insert_users_one {
                    insert_users_one(object: {
                      uid: ${JSON.stringify(authUser.user.uid)}, 
                      mail: ${JSON.stringify(values.email)}, 
                      name: ${JSON.stringify(values.name)},
                      nationality: ${
                        values.nationality
                          ? JSON.stringify(values.nationality)
                          : null
                      },
                      profession: ${
                        values.profession
                          ? JSON.stringify(values.profession)
                          : null
                      },
                      hes_code:  ${
                        values.hesCode ? JSON.stringify(values.hesCode) : null
                      },
                      user_type: ${
                        values.userType ? values.userType.value : null
                      },
                      date_of_birth: ${
                        values.dateOfBirth ? JSON.stringify(values.dateOfBirth) : null
                      },
                    }) {
                      id
                    }
                  }
                `,
              })
              .then((result) => {
                Swal.fire(t("Successfully added!"), " ", "success").then(
                  (result) => {
                    if (result.isConfirmed) {
                      history.push("/human/resources/");
                    }
                  }
                );
              })
              .catch((err) => {
                alert("Hata! CODE:9859");
                console.log(err);
              });
          })
          .catch((err) => {
            alert("Hata! CODE:1565");
            console.log(err);
          });
      })
      .catch((err) => {
        alert("Hata! CODE:6513");
        alert(err.message);
      });

    // client
    //   .mutate({
    //     mutation: gql`
    //   mutation insert_users_one {
    //     insert_users_one(object: {
    //       name: ${JSON.stringify(values.name)},
    //       nationality: ${JSON.stringify(values.nationality)},
    //       profession: ${JSON.stringify(values.profession)},
    //       mail : ${JSON.stringify(values.email)},
    //       hes_code:  ${JSON.stringify(values.hesCode)},
    //       user_type: ${values.userType ? values.userType.value : null},
    //       uid: ${JSON.stringify(uid)},
    //       }) {
    //       id
    //       uid
    //     }
    //   }
    //   `,
    //   })
    //   .then((result) => {
    //     Swal.fire("Successfully added!", " ", "success").then((result) => {
    //       if (result.isConfirmed) {
    //         history.push("/human/resources");
    //       }
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 6px)",
      right: "calc(50% + 6px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    zIndex: 2,
    ...(ownerState.active && {
      color: theme.palette.success.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#ffffff",
      zIndex: 2,
      fontSize: 18,
      backgroundColor: theme.palette.success.main,
      borderRadius: "50%",
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Stepper
              activeStep={activeStep}
              connector={<QontoConnector />}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValuesPerson}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SuiBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <SuiBox />
                          ) : (
                            <SuiButton
                              variant="gradient"
                              buttonColor="light"
                              onClick={handleBack}
                            >
                              {t("Back")}
                            </SuiButton>
                          )}
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            buttonColor="dark"
                          >
                            {isLastStep ? t("add") : t("next")}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
