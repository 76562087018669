/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelectFormik from "components/SuiSelectFormik";
import FormFieldError from "components/FormFieldError";
import { useTranslation } from "react-i18next";

function UserInfo({ formData }) {
  const [isLoading, setLoading] = useState(false);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { refnumber, deliveryCompany, agent, company, price, currency } =
    formField;
  const {
    refnumber: refnumberV,
    deliveryCompany: deliveryCompanyV,
    agent: agentV,
    company: companyV,
    price: priceV,
    currency: currencyV,
  } = values;
  const { t, i18n } = useTranslation();
  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h6" fontWeight="bold">
          {t("Order Informations")}
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" textColor="text">
          Enter informations about your order.
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={refnumber.type}
              label={refnumber.label}
              name={refnumber.name}
              value={refnumberV}
              placeholder={refnumber.placeholder}
              error={errors.refnumber && touched.refnumber}
              success={refnumberV && !errors.refnumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={deliveryCompany.type}
              label={deliveryCompany.label}
              name={deliveryCompany.name}
              value={deliveryCompanyV}
              placeholder={deliveryCompany.placeholder}
              error={errors.deliveryCompany && touched.deliveryCompany}
              success={deliveryCompanyV.length > 0 && !errors.deliveryCompany}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={company.type}
              label={company.label}
              name={company.name}
              value={companyV}
              placeholder={company.placeholder}
              success={companyV.length > 0 && !errors.company}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={price.type}
              label={price.label}
              name={price.name}
              value={priceV}
              placeholder={price.placeholder}
              success={priceV && !errors.price}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox
              mb={1}
              ml={0.5}
              lineHeight={0}
              width="100%"
              display="inline-block"
            >
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {currency.label}
              </SuiTypography>
            </SuiBox>
            <SuiSelectFormik
              name={currency.name}
              value={currencyV}
              placeholder={currency.placeholder}
              options={[
                { value: "USD", label: "$ USD" },
                { value: "Euro", label: "€ Euro" },
                { value: "TL", label: "₺ TL" },
                { value: "Pound", label: "£ Pound" },
              ]}
              isClearable
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
