/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import FlatList from "flatlist-react";
import { auth, fbstorage } from "../../../firebase/firebase";
import { getAccessToken } from "constants/valueStorage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

function ProductImages({ id }) {
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState();
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
  const [images, setImages] = useState([]);
  const [imagesSrc, setImagesSrc] = useState([]);

  useEffect(() => {
    const userToken = getAccessToken();
    const uid = auth.currentUser.uid;

    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const client = new ApolloClient({
      uri: "https://hasura-trc.365tech.dev/v1/graphql",
      headers,
      cache: new InMemoryCache(),
    });

    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                files(where: {type: {_eq: orderimage}}) {
                  file_path
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.ship_infos_by_pk &&
            result.data.ship_infos_by_pk.files &&
            result.data.ship_infos_by_pk.files.length > 0 &&
            setImages(result.data.ship_infos_by_pk.files);
        });
    }
  }, []);

  useEffect(() => {
    var data = [];
    if (images && images.length > 0) {
      setLoading(true);
      images.map((image) => {
        fbstorage
          .ref(image.file_path)
          .getDownloadURL()
          .then((url) => {
            data.push({
              src: url,
            });
            setCurrentImage(url);
          })
          .catch((error) => {
            console.log(error);
          });
      });
      setImagesSrc(data);
      setLoading(false);
    }
  }, [images]);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  const renderList = (item, idx) => {
    return (
      <Grid item xs={2} key={idx}>
        <SuiBox
          component="img"
          id={idx}
          src={item.src}
          alt={"small image " + idx}
          borderRadius="lg"
          boxShadow="regular"
          width="100%"
          customClass="cursor-pointer h-100 object-cover"
          onClick={handleSetCurrentImage}
        />
      </Grid>
    );
  };
  if (images.length > 0) {
    return (
      <SuiBox mb={3}>
        <Card className="overflow-visible">
          {!loading ? (
            <SuiBox p={3}>
              <ImgsViewer
                // imgs={[{ src: "https://firebasestorage.googleapis.com/v0/b/avs-operator.appspot.com/o/jobPhotos%2F16AD516D-3323-4C17-852C-06E0F82B55A2.jpg?alt=media&token=86ebf12c-1e80-43df-b848-29b52e988d40" }, { src: "https://firebasestorage.googleapis.com/v0/b/avs-operator.appspot.com/o/jobPhotos%2F84021cc5-d6a5-4f7e-8372-881c853f4258.jpg?alt=media&token=e1b19bb0-17ee-4457-95b8-e479a8d7e3d3" }]}
                // imgs={[images.map((image, index) => ({ src: image }))]}
                imgs={imagesSrc}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
              />
              {imagesSrc && imagesSrc.length > 0 && (
                <SuiBox
                  component="img"
                  src={currentImage}
                  alt="Product Image"
                  boxShadow="lg"
                  borderRadius="lg"
                  width="100%"
                  onClick={openImgsViewer}
                />
              )}
              <SuiBox mt={2} pt={1}>
                <Grid container spacing={3}>
                  <FlatList
                    list={imagesSrc}
                    renderItem={renderList}
                    header
                    renderWhenEmpty={() => null}
                  />
                </Grid>
              </SuiBox>
            </SuiBox>
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="AVS Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </Card>
      </SuiBox>
    );
  } else {
    return null;
  }
}

export default ProductImages;
