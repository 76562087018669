/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { pink } from "@mui/material/colors";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import SuiInput from "components/SuiInput";
import { Card, Checkbox } from "@mui/material";
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

const JobInfo = ({ id }) => {
  const [jobs, setJobs] = useState([]);
  const [jobs2, setJobs2] = useState([]);
  const [newData, setNewData] = useState("");
  const history = useHistory();
  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    setLoading(true);
    getDetail(true);
  }, []);

  const getDetail = (save) => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query todo_list {
              todo_list(order_by: {id: asc} , where: {ship_info_id: {_eq: ${id}}}) {
                id
                title
                completed
              }
            }
          `,
        })
        .then((result) => {
          // setNote("")
          setJobs(result.data.todo_list);
          save && setJobs2(result.data.todo_list);
          setLoading(false);
        });
    }
  };

  const sentNote = (title, id) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_todo_list {
           update_todo_list(_set: {title: ${JSON.stringify(
             title
           )}}, where: {id: {_eq: ${id}}}) {
            returning {
              title
            }
          }
        }
          `,
        })
        .then((result) => {
          getDetail(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //insert note title
  const insertNote = (title) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation insert_todo_list {
            insert_todo_list(objects: {title: ${JSON.stringify(
              title
            )}, ship_info_id: ${id}}) {
              returning {
                id
                title
                completed
              }
            }
          }
          `,
        })
        .then((result) => {
          getDetail(true);
          newData && setNewData("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //delete note
  const deleteNote = (id) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation delete_todo_list {
            delete_todo_list(where: {id: {_eq: ${id}}}) {
              returning {
                id
              }
            }
          }
          `,
        })
        .then((result) => {
          getDetail(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // click algılama
  const handleChange = (todo_id, completed) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
            mutation update_todo_list {
              update_todo_list(_set: {completed: ${!completed}}, where: {id: {_eq: ${todo_id}}}) {
                returning {
                  status
                }
              }
          }
            `,
        })
        .then((result) => {
          getDetail(true);
          setLoading(false);
          client.mutate({
            mutation: gql`
              mutation insert_logs_one {
                insert_logs_one(object: {
                  job_id: ${id}, 
                  todo_id:${todo_id}, 
                  user_uid: ${JSON.stringify(uid)}, 
                  log_type: "todo",
                  log_status: ${
                    !completed
                      ? JSON.stringify("true")
                      : JSON.stringify("false")
                  }
                  }) {
                  id
                }
              }
            `,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateTitle = (newName, index) => {
    const newJobs = [...jobs];
    const newJob = newJobs[index];

    const source = { title: "ddd dfasd" };
    const returnedTarget = Object.assign({}, newJob, {
      title: newName,
    });

    newJobs.splice(index, 1, returnedTarget);

    setJobs(newJobs);
  };
  const [inputList, setInputList] = useState([{ InputText: "" }]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    // setFieldValue('todo', list)
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { InputText: "" }]);
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox
          mb={4}
          mt={1}
          lineHeight={0}
          fontWeight={700}
          width="100%"
          display="flex"
        >
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            textColor="text"
          >
            {t("Jobs")}
          </SuiTypography>
        </SuiBox>
        {jobs != null && jobs.length > 0 ? (
          <SuiBox>
            {jobs &&
              jobs.map(({ id, title, completed }, key) => (
                <Grid container mb={2} spacing={3} key={key}>
                  <Grid item display={"flex"} xs={12} sm={12}>
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      flex={1}
                      alignItems="center"
                    >
                      <Checkbox
                        name={id}
                        checked={completed}
                        // click fonsiyonuna gönderme
                        onChange={() => handleChange(id, completed)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                      />
                      <SuiInput
                        value={title}
                        multiline
                        onChange={(event) =>
                          updateTitle(event.target.value, key)
                        }
                      />
                    </SuiBox>
                    <SuiBox marginLeft={2}>
                      <SuiBox>
                        <SuiButton
                          variant="gradient"
                          size="small"
                          iconOnly
                          buttonColor="error"
                          onClick={() => deleteNote(id)}
                        >
                          {" "}
                          <DeleteIcon />
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                    {title !== jobs2[key]?.title && (
                      <SuiBox ml={1}>
                        <SuiButton
                          variant="gradient"
                          buttonColor="info"
                          iconOnly
                          onClick={() => sentNote(title, id)}
                        >
                          <Icon className="material-icons-round">save</Icon>
                        </SuiButton>
                      </SuiBox>
                    )}
                  </Grid>
                </Grid>
              ))}
          </SuiBox>
        ) : jobs.length == 0 && !loading ? (
          <SuiBox
            display="flex"
            mt={2}
            mb={2}
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Jobs not found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item display={"flex"} xs={12} sm={12}>
              <SuiInput
                name="InputText"
                placeholder="ex. Yeni iş girebilirsiniz..."
                value={newData}
                onChange={(e) => setNewData(e.target.value)}
              />
            </Grid>
            <SuiBox
              display="flex"
              marginTop={1}
              marginBottom={1}
              marginLeft={2}
            >
              <SuiBox marginLeft={1}>
                <SuiButton
                  variant="gradient"
                  size="small"
                  buttonColor="success"
                  iconOnly
                  onClick={() => insertNote(newData)}
                >
                  <Icon className="material-icons-round">add</Icon>
                  
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

// typechecking props for Address
JobInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default JobInfo;
