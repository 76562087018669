/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import SocialItem from "components/dashboard/SocialItem";

function Social({ dataSocial, title }) {
  const { t } = useTranslation();

  return (
    <Card className="h-100">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SuiTypography variant="h6">{title}</SuiTypography>
        <Tooltip title={t("info2")} placement="bottom">
          <SuiButton
            variant="outlined"
            buttonColor="secondary"
            size="small"
            circular
            iconOnly
          >
            <Icon className="">priority_high</Icon>
          </SuiButton>
        </Tooltip>
      </SuiBox>
      <SuiBox p={2}>
        <SocialItem
          icon={{ color: "facebook", component: <div /> }}
          title={dataSocial[0] && dataSocial[0][0]}
          percentage={dataSocial[0] && dataSocial[0][1]}
        />
        <SocialItem
          icon={{ color: "twitter", component: <div /> }}
          title={dataSocial[1] && dataSocial[1][0]}
          percentage={dataSocial[1] && dataSocial[1][1]}
        />
        <SocialItem
          icon={{ color: "reddit", component: <div /> }}
          title={dataSocial[2] && dataSocial[2][0]}
          percentage={dataSocial[2] && dataSocial[2][1]}
        />
        <SocialItem
          icon={{ color: "youtube", component: <div /> }}
          title={dataSocial[3] && dataSocial[3][0]}
          percentage={dataSocial[3] && dataSocial[3][1]}
        />
        <SocialItem
          icon={{ color: "instagram", component: <div /> }}
          title={dataSocial[4] && dataSocial[4][0]}
          percentage={dataSocial[4] && dataSocial[4][1]}
        />
      </SuiBox>
    </Card>
  );
}
// Setting default values for the props of DashboardNavbar
Social.defaultProps = {};

// Typechecking props for the DashboardNavbar
Social.propTypes = {
  dataSocial: PropTypes.arrayOf(PropTypes.array).isRequired,
};
export default Social;
