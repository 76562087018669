/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "components/OrderSchemas/formPerson";

const {
  formField: {
    name,
    nationality,
    profession,
    email,
    hesCode,
    userType,
    password,
    confirmPassword,
  },
} = checkout;

export default {
  [name.name]: "",
  [nationality.name]: "",
  [profession.name]: "",
  [email.name]: "",
  [hesCode.name]: "",
  userType: null,
  [password.name]: "",
  [confirmPassword.name]: "",
};
