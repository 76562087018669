/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// ui kit components
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import trLocale from 'date-fns/locale/tr';


// Soft UI Dashboard PRO React components
import SuiInput from "../SuiInput";
import SuiBox from "../SuiBox";

// function SuiDatePicker({ input, ...rest }) {
//   return (
//     <Flatpickr
//       {...rest}
//       render={({ defaultValue }, ref) => (
//         <SuiInput {...input} defaultValue={defaultValue} inputRef={ref} />
//       )}
//     />
//   );
// }

function SuiDatePicker({ input, value, onChange, ...rest }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
      <DatePicker
        value={value}
        onChange={onChange}
        mask={"__.__.____"}
        {...rest}
        // renderInput={({ defaultValue }, ref) => (
        //   <SuiBox>
        //     <SuiInput {...input} defaultValue={value} inputRef={ref} />
        //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //       <input ref={inputRef} {...inputProps} />
        //       {InputProps?.endAdornment}
        //     </Box>
        //   </SuiBox>
        // )}
        renderInput={({ inputRef, inputProps, InputProps }) => (
            <SuiInput endAdornment={InputProps?.endAdornment} inputRef={inputRef} {...inputProps} />
        )}
      />
    </LocalizationProvider>
  );
}

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDatePicker;
