/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, Component } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from 'firebase/firebase';
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";
import ComplexProjectCard from "components/Cards/ProjectCards/ComplexProjectCard";
import PlaceholderCard from "components/Cards/PlaceholderCard";
import pageRoutes from "constants/pageRoutes";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import folder from "assets/images/folder.png";
import Swal from 'sweetalert2';


function AllProjects(props) {
  const { id } = props.match.params;

  const [loading, setLoading] = useState(true)
  const [dataSet, setDataSet] = useState([])
  const [report, setReport] = useState({})
  const [record, setRecord] = useState({})
  const [gyro, setGyro] = useState({})
  const [ais, setAis] = useState({})
  const [magneticCompass, setMagneticCompass] = useState({})
  const [gmdsSbm, setGmdsSbm] = useState({})
  const [bnwas, setBnwas] = useState({})
  const [ssas, setSsas] = useState({})
  const [epirbSbm, setEpirbSbm] = useState({})
  const [twoDay, setTwoDay] = useState({})
  const [epirb, setEpirb] = useState({})
  const [sart, setSart] = useState({})

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  }
  const client = new ApolloClient({
    uri: 'https://hasura-trc.365tech.dev/v1/graphql',
    headers,
    cache: new InMemoryCache()
  });

  useEffect(() => {
    getData()
  }, [auth.currentUser]);

  const getData = () => {
    setLoading(true)
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessel_certificates {
              vessel_certificates(where: {vessel_id: {_eq: ${id}}, status: {_eq:true}}, order_by: {created_at: asc}) {
                certificate_start_date
                certificate_expiry_date
                battery_start_date
                battery_expiry_date
                battery
                certificate_type
                created_at
                id
                file {
                  file_path
                }
              }
            }
          `
        })
        .then(result => {
          setDataSet(result.data.vessel_certificates);
        });
    }
  }

  useEffect(() => {
    setReport({})
    setRecord({})
    setGyro({})
    setAis({})
    setMagneticCompass({})
    setGmdsSbm({})
    setBnwas({})
    setSsas({})
    setEpirbSbm({})
    setTwoDay({})
    setEpirb({})
    setSart({})
    if (dataSet.length > 0) {
      dataSet.map(data => {
        switch (data.certificate_type) {
          case "report":
            setReport({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "record":
            setRecord({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "gyro":
            setGyro({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "ais":
            setAis({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "magnetic_compass":
            setMagneticCompass({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "gmds_sbm":
            setGmdsSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "bnwas":
            setBnwas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "ssas":
            setSsas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "epirb_sbm":
            setEpirbSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "two_way":
            setTwoDay({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "epirb":
            setEpirb({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          case "sart":
            setSart({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              id: data.id,
              file_path: data.file.file_path,
            })
            break;
          default:
            break;
        }
      }
      )
      setLoading(false)
    }
  }, [dataSet]);

  const sentUpdate = (batteryStartDate,batteryExpiryDate,expiryDate,startDate,type,certId,fileId) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation insert_vessel_certificates_one {
            insert_vessel_certificates_one(object:
              {
                certificate_expiry_date: ${expiryDate ? JSON.stringify(expiryDate) : null}, 
                certificate_start_date:  ${startDate ? JSON.stringify(startDate) : null}, 
                battery_start_date: ${batteryExpiryDate ? JSON.stringify(batteryExpiryDate) : null}, 
                battery_expiry_date:  ${batteryStartDate ? JSON.stringify(batteryStartDate) : null}, 
                certificate_type: ${type ? JSON.stringify(type) : null}, 
                battery: ${(batteryExpiryDate && batteryStartDate)  ? "true" : "false" },
                file_id: ${fileId ? fileId : null},
                vessel_id:${id},
              }) {
              vessel_id
              id
            }
          }
          `
        })
        .then(result => {
          getData()
          certId && changeDateCert(certId)
          Swal.fire({
            title: "Success", 
            text: "Certificate add successfully",
            icon: "success",
            confirmButtonText: "OK"
          })

        }).catch(err => {
          console.log(err)
        })
    }
  }

  const changeDateCert = (certId) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation update_vessel_certificates_by_pk {
            update_vessel_certificates_by_pk(pk_columns: {id: ${certId}}, _set: {
                change_date: "now()"
                }) {
                id
              }
            }
          `
        })
        .then(result => {
        }).catch(err => {
          console.log(err)
        })
    }
  }

  const deleteFile = (certId) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation vessel_certificates_by_pk {
            update_vessel_certificates_by_pk(pk_columns: {id: ${certId}}, _set: {status: false}) {
              id
              status
            }
          }    
          `,
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "File deleted successfully",
            icon: "success",
            confirmButtonText: "OK"
          })
          getData()
        });
    }
  };


  return (
    <DashboardLayout
    >
      <DefaultNavbar
        routes={pageRoutes}
      />
      <SuiBox>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Report"
                type="report"
                certId={report ? report.id : null}
                id={id}
                filePath={report ? report.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={report.certificate_expiry_date}
                dateTime={report.certificate_start_date || report.certificate_expiry_date ? (report.certificate_start_date ? report.certificate_start_date : "Empty") + " / " + (report.certificate_expiry_date ? report.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Record"
                type="record"
                certId={record ? record.id : null}
                id={id}
                filePath={record ? record.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={record.certificate_expiry_date}
                expiryDate
                dateTime={record.certificate_start_date || record.certificate_expiry_date ? (record.certificate_start_date ? record.certificate_start_date : "Empty") + " / " + (record.certificate_expiry_date ? record.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Gyro"
                type="gyro"
                certId={gyro ? gyro.id : null}
                id={id}
                filePath={gyro ? gyro.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={gyro.certificate_expiry_date}
                dateTime={gyro.certificate_start_date || gyro.certificate_expiry_date ? (gyro.certificate_start_date ? gyro.certificate_start_date : "Empty") + " / " + (gyro.certificate_expiry_date ? gyro.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Ais"
                type="ais"
                certId={ais ? ais.id : null}
                id={id}
                filePath={ais ? ais.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={ais.certificate_expiry_date}
                dateTime={ais.certificate_start_date || ais.certificate_expiry_date ? (ais.certificate_start_date ? ais.certificate_start_date : "Empty") + " / " + (ais.certificate_expiry_date ? ais.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Magnetic Compass"
                type="magnetic_compass"
                certId={magneticCompass ? magneticCompass.id : null}
                id={id}
                filePath={magneticCompass ? magneticCompass.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={magneticCompass.certificate_expiry_date}
                dateTime={magneticCompass.certificate_start_date || magneticCompass.certificate_expiry_date ? (magneticCompass.certificate_start_date ? magneticCompass.certificate_start_date : "Empty") + " / " + (magneticCompass.certificate_expiry_date ? magneticCompass.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Gmds Sbm"
                type="gmds_sbm"
                certId={gmdsSbm ? gmdsSbm.id : null}
                id={id}
                filePath={gmdsSbm ? gmdsSbm.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={gmdsSbm.certificate_expiry_date}
                dateTime={gmdsSbm.certificate_start_date || gmdsSbm.certificate_expiry_date ? (gmdsSbm.certificate_start_date ? gmdsSbm.certificate_start_date : "Empty") + " / " + (gmdsSbm.certificate_expiry_date ? gmdsSbm.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Bnwas"
                type="bnwas"
                certId={bnwas ? bnwas.id : null}
                id={id}
                filePath={bnwas ? bnwas.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={bnwas.certificate_expiry_date}
                dateTime={bnwas.certificate_start_date || bnwas.certificate_expiry_date ? (bnwas.certificate_start_date ? bnwas.certificate_start_date : "Empty") + " / " + (bnwas.certificate_expiry_date ? bnwas.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Ssas"
                type="ssas"
                certId={ssas ? ssas.id : null}
                id={id}
                filePath={ssas ? ssas.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={ssas.certificate_expiry_date}
                dateTime={ssas.certificate_start_date || ssas.certificate_expiry_date ? (ssas.certificate_start_date ? ssas.certificate_start_date : "Empty") + " / " + (ssas.certificate_expiry_date ? ssas.certificate_expiry_date : "Empty") : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Eprib Sbm"
                dateTime={epirbSbm.certificate_start_date || epirbSbm.certificate_expiry_date ? (epirbSbm.certificate_start_date ? epirbSbm.certificate_start_date : "Empty") + " / " + (epirbSbm.certificate_expiry_date ? epirbSbm.certificate_expiry_date : "Empty") : null}
                dateTimeBattery = {epirbSbm.battery_start_date || epirbSbm.battery_expiry_date ? (epirbSbm.battery_start_date ? epirbSbm.battery_start_date : "Empty") + " / " + (epirbSbm.battery_expiry_date ? epirbSbm.battery_expiry_date : "Empty") : null}
                battery={true}
                type="epirb_sbm"
                certId={epirbSbm ? epirbSbm.id : null}
                id={id}
                filePath={epirbSbm ? epirbSbm.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={epirbSbm.certificate_expiry_date}
                dateBatteryExpiry={epirbSbm.battery_expiry_date}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Two Day"
                dateTime={twoDay.certificate_start_date || twoDay.certificate_expiry_date ? (twoDay.certificate_start_date ? twoDay.certificate_start_date : "Empty") + " / " + (twoDay.certificate_expiry_date ? twoDay.certificate_expiry_date : "Empty") : null}
                dateTimeBattery={twoDay.battery_start_date || twoDay.battery_expiry_date ? (twoDay.battery_start_date ? twoDay.battery_start_date : "Empty") + " / " + (twoDay.battery_expiry_date ? twoDay.battery_expiry_date : "Empty") : null}
                battery={true}
                type="two_day"
                certId={twoDay ? twoDay.id : null}
                id={id}
                filePath={twoDay ? twoDay.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={twoDay.certificate_expiry_date}
                dateBatteryExpiry={twoDay.battery_expiry_date}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Eprib"
                dateTime={epirb.certificate_start_date || epirb.certificate_expiry_date ? (epirb.certificate_start_date ? epirb.certificate_start_date : "Empty") + " / " + (epirb.certificate_expiry_date ? epirb.certificate_expiry_date : "Empty") : null}
                dateTimeBattery = {epirb.battery_start_date || epirb.battery_expiry_date ? (epirb.battery_start_date ? epirb.battery_start_date : "Empty") + " / " + (epirb.battery_expiry_date ? epirb.battery_expiry_date : "Empty") : null}
                battery={true}
                type="epirb"
                certId={epirb ? epirb.id : null}
                id={id}
                filePath={epirb ? epirb.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={epirb.certificate_expiry_date}
                dateBatteryExpiry={epirb.battery_expiry_date}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ComplexProjectCard
                image={folder}
                title="Sart"
                dateTime={sart.certificate_start_date || sart.certificate_expiry_date ? (sart.certificate_start_date ? sart.certificate_start_date : "Empty") + " / " + (sart.certificate_expiry_date ? sart.certificate_expiry_date : "Empty") : null}
                dateTimeBattery={sart.battery_start_date || sart.battery_expiry_date ? (sart.battery_start_date ? sart.battery_start_date : "Empty") + " / " + (sart.battery_expiry_date ? sart.battery_expiry_date : "Empty") : null}
                battery={true} 
                type="sart"
                certId={sart ? sart.id : null}
                id={id}
                filePath={sart ? sart.file_path : null}
                onInsert={sentUpdate}
                onDelete={deleteFile}
                dateExpiry={sart.certificate_expiry_date}
                dateBatteryExpiry={sart.battery_expiry_date}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <PlaceholderCard title={{ variant: "h5", text: "New Certificate" }} />
            </Grid> */}
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllProjects;
