/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: { 
    name: {
      name: "name",
      label: "Ship Name",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    imo: {
      name: "imo",
      label: "IMO",
      type: "number",
      placeholder: "ex. 365.",
      errorMsg: "This field is required.",
    },
    agent: {
      name: "agent",
      label: "Agent",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    owner: {
      name: "owner",
      label: "Owner",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    callSign: {
      name: "callSign",
      label: "Call Sign",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    mmsi: {
      name: "mmsi",
      label: "mmsi",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    portState: {
      name: "portState",
      label: "Port",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    flag: {
      name: "flag",
      label: "Flag",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },
    grossTonage: {
      name: "grossTonage",
      label: "Gross Tonnage",
      type: "number",
      placeholder: "ex. 365.",
      errorMsg: "This field is required.",
    },
    kellLand: {
      name: "kellLand",
      label: "kell Land",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "This field is required.",
    },


},
};
