/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ViewFile from "components/ViewFile";
import TimelineItem from "components/TimelineVessel/TimelineItem";
import UploadFileIcon from "@mui/icons-material/UploadFile";

function CertificateInformation({ id }) {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState([]);
  const [report, setReport] = useState({});
  const [record, setRecord] = useState({});
  const [gyro, setGyro] = useState({});
  const [ais, setAis] = useState({});
  const [magneticCompass, setMagneticCompass] = useState({});
  const [gmdsSbm, setGmdsSbm] = useState({});
  const [bnwas, setBnwas] = useState({});
  const [ssas, setSsas] = useState({});
  const [epirbSbm, setEpirbSbm] = useState({});
  const [twoDay, setTwoDay] = useState({});
  const [epirb, setEpirb] = useState({});
  const [sart, setSart] = useState({});
  const { t, i18n } = useTranslation();

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = async () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessel_certificates {
              vessel_certificates(where: {vessel_id: {_eq: ${id}}, status: {_eq: true}}, order_by: {created_at: asc}) {
                certificate_start_date
                certificate_expiry_date
                battery_start_date
                battery_expiry_date
                battery
                certificate_type
                file {
                  file_path
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.vessel_certificates.length > 0
            ? setDataSet(result.data.vessel_certificates)
            : setLoading(false);
          // setLoading(false)
        });
    }
    //   setLoading(false)
  };

  useEffect(() => {
    setReport({});
    setRecord({});
    setGyro({});
    setAis({});
    setMagneticCompass({});
    setGmdsSbm({});
    setBnwas({});
    setSsas({});
    setEpirbSbm({});
    setTwoDay({});
    setEpirb({});
    setSart({});
    console.log(dataSet);
    if (dataSet && dataSet.length > 0) {
      dataSet.map((data) => {
        switch (data.certificate_type) {
          case "report":
            setReport({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "record":
            setRecord({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "gyro":
            setGyro({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "ais":
            setAis({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "magnetic_compass":
            setMagneticCompass({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "gmds_sbm":
            setGmdsSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "bnwas":
            setBnwas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "ssas":
            setSsas({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              file_path: data.file.file_path,
            });
            break;
          case "epirb_sbm":
            setEpirbSbm({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
            });
            break;
          case "two_way":
            setTwoDay({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
            });
            break;
          case "epirb":
            setEpirb({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
            });
            break;
          case "sart":
            setSart({
              certificate_start_date: data.certificate_start_date,
              certificate_expiry_date: data.certificate_expiry_date,
              battery_start_date: data.battery_start_date,
              battery_expiry_date: data.battery_expiry_date,
              battery: data.battery,
              file_path: data.file.file_path,
            });
            break;
          default:
            break;
        }
      });
      setLoading(false);
    }
  }, [dataSet]);

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox>
          <SuiBox display="flex" justifyContent="space-between" mb={2}>
            <SuiTypography
              fontWeight="bold"
              textTransform="capitalize"
              variant="h6"
              gutterBottom
              textColor="text"
            >
              {t("Certificate")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {dataSet.length > 0 && !loading ? (
          <SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    report && report.file_path ? "success" : "info"
                   }
                   />}
                  title="Report"
                  dateTime={
                    report
                      ? (report.certificate_start_date
                          ? report.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (report.certificate_expiry_date
                          ? report.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {report && report.file_path && (
                    <ViewFile
                      filePath={report.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    record && record.file_path ? "success" : "info"
                   }
                    />}
                  title="Record"
                  dateTime={
                    record
                      ? (record.certificate_start_date
                          ? record.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (record.certificate_expiry_date
                          ? record.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {record && record.file_path && (
                    <ViewFile
                      filePath={record.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    gyro && gyro.file_path ? "success" : "info"
                   }
                    />}
                  title="Gyro"
                  dateTime={
                    gyro
                      ? (gyro.certificate_start_date
                          ? gyro.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (gyro.certificate_expiry_date
                          ? gyro.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {gyro && gyro.file_path && (
                    <ViewFile
                      filePath={gyro.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    ais && ais.file_path ? "success" : "info"
                   }
                    />}
                  title="Ais"
                  dateTime={
                    ais
                      ? (ais.certificate_start_date
                          ? ais.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (ais.certificate_expiry_date
                          ? ais.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {ais && ais.file_path && (
                    <ViewFile
                      filePath={ais.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    magneticCompass && magneticCompass.file_path ? "success" : "info"
                   }
                    />}
                  title="Magnetic Compass"
                  dateTime={
                    magneticCompass
                      ? (magneticCompass.certificate_start_date
                          ? magneticCompass.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (magneticCompass.certificate_expiry_date
                          ? magneticCompass.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {magneticCompass && magneticCompass.file_path && (
                    <ViewFile
                      filePath={magneticCompass.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    gmdsSbm && gmdsSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Gmds Sbm"
                  dateTime={
                    gmdsSbm
                      ? (gmdsSbm.certificate_start_date
                          ? gmdsSbm.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (gmdsSbm.certificate_expiry_date
                          ? gmdsSbm.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {gmdsSbm && gmdsSbm.file_path && (
                    <ViewFile
                      filePath={gmdsSbm.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    bnwas && bnwas.file_path ? "success" : "info"
                   }
                    />}
                  title="Bnwas"
                  dateTime={
                    bnwas
                      ? (bnwas.certificate_start_date
                          ? bnwas.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (bnwas.certificate_expiry_date
                          ? bnwas.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {bnwas && bnwas.file_path && (
                    <ViewFile
                      filePath={bnwas.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    ssas && ssas.file_path ? "success" : "info"
                   }
                    />}
                  title="Ssas"
                  dateTime={
                    ssas
                      ? (ssas.certificate_start_date
                          ? ssas.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (ssas.certificate_expiry_date
                          ? ssas.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {ssas && ssas.file_path && (
                    <ViewFile
                      filePath={ssas.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirbSbm && epirbSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb Sbm"
                  dateTime={
                    epirbSbm
                      ? (epirbSbm.certificate_start_date
                          ? epirbSbm.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (epirbSbm.certificate_expiry_date
                          ? epirbSbm.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirbSbm && epirbSbm.file_path && (
                    <ViewFile
                      filePath={epirbSbm.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    twoDay && twoDay.file_path ? "success" : "info"
                   }
                    />}
                  title="Two Day"
                  dateTime={
                    twoDay
                      ? (twoDay.certificate_start_date
                          ? twoDay.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (twoDay.certificate_expiry_date
                          ? twoDay.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {twoDay && twoDay.file_path && (
                    <ViewFile
                      filePath={twoDay.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirb && epirb.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb"
                  dateTime={
                    epirb
                      ? (epirb.certificate_start_date
                          ? epirb.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (epirb.certificate_expiry_date
                          ? epirb.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirb && epirb.file_path && (
                    <ViewFile
                      filePath={epirb.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    sart && sart.file_path ? "success" : "info"
                   }
                    />}
                  title="Sart"
                  dateTime={
                    sart
                      ? (sart.certificate_start_date
                          ? sart.certificate_start_date
                          : t("Empty")) +
                        " / " +
                        (sart.certificate_expiry_date
                          ? sart.certificate_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {sart && sart.file_path && (
                    <ViewFile
                      filePath={sart.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <SuiBox mb={2}>
              <SuiTypography
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                textColor="text"
              >
                {t("Battery")}
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirbSbm && epirbSbm.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb Sbm"
                  dateTime={
                    epirbSbm
                      ? (epirbSbm.battery_start_date
                          ? epirbSbm.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (epirbSbm.battery_expiry_date
                          ? epirbSbm.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirbSbm && epirbSbm.file_path && (
                    <ViewFile
                      filePath={epirbSbm.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    twoDay && twoDay.file_path ? "success" : "info"
                   }
                    />}
                  title="Two Way"
                  dateTime={
                    twoDay
                      ? (twoDay.battery_start_date
                          ? twoDay.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (twoDay.battery_expiry_date
                          ? twoDay.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {twoDay && twoDay.file_path && (
                    <ViewFile
                      filePath={twoDay.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    epirb && epirb.file_path ? "success" : "info"
                   }
                    />}
                  title="Epirb"
                  dateTime={
                    epirb
                      ? (epirb.battery_start_date
                          ? epirb.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (epirb.battery_expiry_date
                          ? epirb.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {epirb && epirb.file_path && (
                    <ViewFile
                      filePath={epirb.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} display={"flex"} alignItems={"center"}>
                <TimelineItem
                  color="info"
                  icon={<UploadFileIcon color=
                   {
                    sart && sart.file_path ? "success" : "info"
                   }
                    />}
                  title="Sart"
                  dateTime={
                    sart
                      ? (sart.battery_start_date
                          ? sart.battery_start_date
                          : t("Empty")) +
                        " / " +
                        (sart.battery_expiry_date
                          ? sart.battery_expiry_date
                          : t("Empty"))
                      : t("Empty")
                  }
                />{" "}
                <SuiBox mb={1} lineHeight={0} ml={3}>
                  {sart && sart.file_path && (
                    <ViewFile
                      filePath={sart.file_path}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        ) : dataSet.length == 0 && !loading ? (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Certificates not found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default CertificateInformation;
