import { useCallback, useEffect, useState } from "react";

import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { getAccessToken } from "constants/valueStorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import { auth } from "../../firebase/firebase";
import { uploadFromBlobAsync } from "./uploadFromBlobAsync";

import { useTranslation } from "react-i18next";

export default function App({ id, jobId, type, resourceType, returnValue, onDelete }) {
  const [dataSet, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [returnId, setReturnId] = useState(0);
  const [message, setMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const { t } = useTranslation();

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = () => {
    if (auth.currentUser && id) {
      client
        .query({
          query: gql`
          query users_by_pk {
            users_by_pk(id: ${id}) {
              user_resources(where: {status: {_eq: true}}) {
                file {
                  name
                }
                id
                resource_type
              }
            }
          }
          `,
        })
        .then((result) => {
          if (result.data.users_by_pk.user_resources.length > 0) {
            result.data.users_by_pk.user_resources.map((data) => {
              if (data.file !== null && data.resource_type === resourceType) {
                setDataSet(data);
              }
            });
          } else {
            console.log(result.data.users_by_pk.user_resources, id);
          }
        });
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles?.[0];
      if (!file) {
        return;
      }

      setIsLoading(true);
      setError(null);
      setMessage(null);

      const datenow = moment().format("yyyy-MM-DD-HHmmss");

      const filenamepath = "certificateFiles/" + datenow + "-" + file.name;

      try {
        await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: filenamepath,
        });
      } catch (e) {
        setIsLoading(false);
        setError(e.message);
        return;
      }

      if (auth.currentUser) {
        const userToken = getAccessToken();
        const uid = auth.currentUser.uid;

        const headers = {
          Authorization: `Bearer ${userToken}`,
        };
        const client = new ApolloClient({
          uri: "https://hasura-trc.365tech.dev/v1/graphql",
          headers,
          cache: new InMemoryCache(),
        });

        client
          .mutate({
            mutation: gql`
            mutation insert_files_one {
              insert_files_one(object: { name: ${JSON.stringify(file.name)}, file_path: ${JSON.stringify(
              filenamepath
            )}, uid: ${JSON.stringify(uid)}, job_id: ${jobId ? jobId : null}, type: ${type}}) {
                id
              }
            }
          `,
          })
          .then((result) => {
            setIsLoading(false);
            returnValue(result.data.insert_files_one.id, resourceType, setDataSet);
            setMessage("File was uploaded 👍");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [auth.currentUser]
  );
  return (
    <SuiBox>
      <Dropzone
        onDrop={onDrop}
        accept="image/*,.pdf,.doc,.docx"
        minSize={1024} // 1MB
        maxSize={104857600} // 100MB
        multiple={false}
        noClick={dataSet.file ? true : false}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
          const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : "";

          return (
            <SuiBox
              sx={{
                border: "0.0625rem solid #d2d6da",
                borderRadius: "0.5rem",
                cursor: "pointer",
                textAlign: "center",
                marginTop: "5px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
              }}
              {...getRootProps({
                className: `dropzone ${additionalClass}`,
              })}
            >
              <input {...getInputProps()} />
              {isLoading ? (
                <SuiBox
                  sx={{
                    width: "100%",
                    alignSelf: "center",
                    padding: "10px",
                  }}
                >
                  <CircularProgress color="info" />
                </SuiBox>
              ) : dataSet.file ? (
                <SuiTypography
                  component="label"
                  textTransform="capitalize"
                  variant="button"
                  fontWeight="regular"
                  textColor="text"
                  alignSelf="center"
                  padding="10px"
                >
                  {" "}
                  <SuiBox mt={1} display="flex">
                    {dataSet.file.name && (
                      <SuiTypography
                        component="label"
                        textTransform="capitalize"
                        variant="button"
                        fontWeight="regular"
                        textColor="text"
                        alignSelf="center"
                      >
                        {dataSet.file.name}
                      </SuiTypography>
                    )}
                  </SuiBox>
                  <SuiBox>
                    <SuiButton
                      variant="text"
                      buttonColor="error"
                      onClick={() => {
                        onDelete(dataSet.id, setDataSet);
                        setMessage("File was deleted 👍");
                      }}
                    >
                      <Icon className="deleteFolder">delete</Icon>&nbsp;
                      {t("Delete")}
                    </SuiButton>
                  </SuiBox>
                </SuiTypography>
              ) : (
                <SuiTypography
                  component="label"
                  textTransform="capitalize"
                  variant="button"
                  fontWeight="regular"
                  textColor="text"
                  alignSelf="center"
                  padding="10px"
                >
                  {t("Drag 'n' drop file here, or click to select file")}
                </SuiTypography>
              )}
            </SuiBox>
          );
        }}
      </Dropzone>
      {message && <Alert severity={"success"}>{message ? message : "null"}</Alert>}
    </SuiBox>
  );
}
