import React from "react";
import { withRouter } from "react-router-dom";
import AuthUserContext from "../components/AuthUserContext";
import { firebase } from "../firebase";

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!authCondition(authUser)) {
          //if the authorization fails, redirects to sign in page
          this.props.history.push("/signin");
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {/* it either renders the passed component or not */}
          {(authUser) =>
            authCondition(authUser) ? (
              <Component {...this.props} loggedUser={authUser} />
            ) : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization); //using withRouter so we have access to history props
};

export default withAuthorization;
