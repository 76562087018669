/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "components/schemas/vesselForm";

const {
  formField: { 
  name,
  //imo,
},
} = checkout;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
   // [imo.name]: Yup.number().required(imo.errorMsg),
  }),
];
