/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "components/OrderSchemas/formPerson";

const {
  formField: { name, userType, password, confirmPassword, email },
} = checkout;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    userType: Yup.object().shape({
      value: Yup.string().required(userType.invalidMsg),
    }).nullable().required(userType.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [confirmPassword.name]: Yup.string().required(confirmPassword.errorMsg).oneOf([Yup.ref("password"), null], confirmPassword.invalidMsg),
  }),
  Yup.object().shape({
    // [address1.name]: Yup.string().required(address1.errorMsg),
    // [city.name]: Yup.string().required(city.errorMsg),
    // [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
  }),
  Yup.object().shape({
    // [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];
