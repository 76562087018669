/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
// Image
// import adobeXD from "assets/images/small-logos/logo-xd.svg";
// import atlassian from "assets/images/small-logos/logo-atlassian.svg";
// import slack from "assets/images/small-logos/logo-slack.svg";
// import spotify from "assets/images/small-logos/logo-spotify.svg";
// import jira from "assets/images/small-logos/logo-jira.svg";

function complexReportsDoughnutChartData({reversedPorts}) {
  // images: [adobeXD, atlassian, slack, spotify, jira],
  var labels= [
    (reversedPorts[0] && reversedPorts[0][0]),
    (reversedPorts[1] && reversedPorts[1][0]),
    (reversedPorts[2] && reversedPorts[2][0]),
    (reversedPorts[3] && reversedPorts[3][0]),
    (reversedPorts[4] && reversedPorts[4][0]),
    (reversedPorts[5] && reversedPorts[5][0])]

  var datasets= {
    label: "Referrals",
    backgroundColors: ["primary", "info", "warning", "success", "dark"],
    data: [(reversedPorts[0] &&reversedPorts[0][1]),
    (reversedPorts[1] &&reversedPorts[1][1]),
    (reversedPorts[2] &&reversedPorts[2][1]),
    (reversedPorts[3] && reversedPorts[3][1]),
    (reversedPorts[4] && reversedPorts[4][1]),
    (reversedPorts[5] && reversedPorts[5][1])],
  }

  return ({
    labels:[...labels],
    datasets:{...datasets},    
  })
}

// Setting default values for the props of DashboardNavbar
complexReportsDoughnutChartData.defaultProps = {
};

// Typechecking props for the DashboardNavbar
complexReportsDoughnutChartData.propTypes = {
  reversedPorts: PropTypes.arrayOf(PropTypes.object),
};
export default complexReportsDoughnutChartData;