/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Page components
import FormFieldError from "components/FormFieldError";
import { useTranslation } from "react-i18next";

function Profile({ formData }) {
  const { formField, values } = formData;
  const { publicEmail, note } = formField;
  const { publicEmail: publicEmailV, note: noteV } = values;
  const { t, i18n } = useTranslation();

  return (
    <SuiBox>
      <SuiTypography variant="h5" fontWeight="bold">
        {t("AddaNote")}
      </SuiTypography>
      <SuiBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormFieldError
              type={note.type}
              label={t(note.label)}
              name={note.name}
              value={noteV}
              placeholder={t(note.placeholder)}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for Profile
Profile.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Profile;
