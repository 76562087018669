import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/database";
import "@firebase/storage";
import "@firebase/firestore";
import env from './env';


if (!firebase.apps.length) {
  // Only works with sign up
  var SignUp = firebase.initializeApp({
    apiKey: "AIzaSyCUACQQEjzNI89KjFun1MNXBhkacZ0rhfA",
    authDomain: "trc-marine.firebaseapp.com",
    databaseURL: "https://trc-marine-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "trc-marine",
    storageBucket: "trc-marine.appspot.com",
    messagingSenderId: "399124379704",
    appId: "1:399124379704:web:4f5b271d4954a9b3714ecd",
    measurementId: "G-E3PY2LZWXL"
  }, 'SignUp');

  //initializing with the config object
  firebase.initializeApp(env);
}

const SignUpdb = SignUp.database();
const SignUpAuth = SignUp.auth();
const db = firebase.database();
const auth = firebase.auth();
const firestoredb = firebase.firestore(); 
const fbstorage = firebase.storage(); 
const providerGoogle = new firebase.auth.GoogleAuthProvider();

export { firebase, SignUpAuth, SignUpdb, db, auth, firestoredb, fbstorage, providerGoogle };
