/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import ActionCell from "components/ActionCell/vesselData";
function vesselData({ dataset }) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item) => {
      data.push({
        vesselName: item.name,
        agent: item.agent,
        //  owner: item.owner,
        imo: item.imo,
        action: <ActionCell id={item.id} />,
      });
    });
  }

  return {
    columns: [
      { Header: "IMO", name: "imo", accessor: "imo" },
      {
        Header: "Ship Name",
        name: "vesselName",
        width : "80%",
        accessor: "vesselName",
      },
      { Header: "Process", name: "action", accessor: "action" },
    ],

    rows: [...data],
  };
}
// Setting default values for the props of DashboardNavbar
vesselData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
vesselData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default vesselData;
