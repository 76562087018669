/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import ActionCellHumanResources from "components/ActionCell/hr";
import ActionCellHumanResourcesDelete from "components/ActionCell/hrDelete";
import i18n from "i18next";
import SuiBadge from "components/SuiBadge";

const Operator = (
  <SuiBadge
    variant="contained"
    color="info"
    size="xs"
    //  value="undone"
    badgeContent="Operator"
    container
  />
);

const TRCAdmin = (
  <SuiBadge
    variant="contained"
    color="error"
    size="xs"
    //  value="undone"
    badgeContent="TRC Admin"
    container
  />
);

const OfficeWorker = (
  <SuiBadge
    variant="contained"
    color="warning"
    size="xs"
    //value="undone"
    badgeContent="Office Worker"
    container
  />
);

function dataTableData({ dataset }) {
  var data = [];

  if (dataset.length > 0) {
    dataset.map((item) => {
      data.push({
        name: item.name,
        profession: item.profession,
        type: i18n.t(item.user_type),
        action: <ActionCellHumanResources id={item.id} />,
        DeleteContact: (
          <ActionCellHumanResourcesDelete uid={item.uid} id={item.id} />
        ),
      });
    });
  }

  return {
    columns: [
      { Header: "Name", name: "name", accessor: "name" },
      {
        Header: "Profession",
        name: "profession",
        accessor: "profession",
      },
      {
        Header: "User Type",
        name: "type",
        accessor: "type",
        Cell: ({ value }) =>
          value === "Operator"
            ? Operator
            : value === "Operatör"
            ? Operator
            : value === "TRC Admin"
            ? TRCAdmin
            : value === "Office Worker"
            ? OfficeWorker
            : value === "Ofis Çalışanı"
            ? OfficeWorker
            : "",
      },
      { Header: "Process", accessor: "action" },
      { Header: "Delete Contact", accessor: "DeleteContact" },
    ],

    rows: [...data],
  };
}

// Setting default values for the props of DashboardNavbar
dataTableData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
dataTableData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string.isRequired,
};

export default dataTableData;
