/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// ui kit components
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import trLocale from 'date-fns/locale/tr';

// formik components
import { useField } from "formik";

// Soft UI Dashboard PRO React components
import SuiInput from "../SuiInput";
import SuiBox from "../SuiBox";

// function SuiDatePickerFormik({ input, ...rest }) {
//   return (
//     <Flatpickr
//       {...rest}
//       render={({ defaultValue }, ref) => (
//         <SuiInput {...input} defaultValue={defaultValue} inputRef={ref} />
//       )}
//     />
//   );
// }

function SuiDatePickerFormik({ input, value, ...rest }) {
  const [field, meta, { setValue, setTouched }] = useField(rest);

  const onChange = ( value ) => {
    setValue(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
      <DatePicker
        value={value}
        onChange={onChange}
        mask={"__.__.____"}
        {...rest}
        // renderInput={({ defaultValue }, ref) => (
        //   <SuiBox>
        //     <SuiInput {...input} defaultValue={value} inputRef={ref} />
        //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //       <input ref={inputRef} {...inputProps} />
        //       {InputProps?.endAdornment}
        //     </Box>
        //   </SuiBox>
        // )}
        renderInput={({ inputRef, inputProps, InputProps }) => (
            <SuiInput endAdornment={InputProps?.endAdornment} inputRef={inputRef} {...inputProps} />
        )}
      />
    </LocalizationProvider>
  );
}

// Setting default values for the props of SuiDatePickerFormik
SuiDatePickerFormik.defaultProps = {
  input: {},
};

// Typechecking props for the SuiDatePickerFormik
SuiDatePickerFormik.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDatePickerFormik;