/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import withAuthorization from "../../withAuthorization"; //redirects to sign in if user not signed in
import { useTranslation } from "react-i18next";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

// firebase auth
import { auth } from "firebase";
import { db } from "firebase/firebase";
import { setUserClaims } from "constants/valueStorage";

// Soft UI Dashboard PRO React example components
import PageLayout from "components/LayoutContainers/PageLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import DataTable from "components/Tables/DataTable";

// Data
import listData from "components/data/listData";
import timelineData from "components/data/timelineData";

// EditProduct page components

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "constants/pageRoutes";

// Custom styles for the Baise
import styles from "./styles";
import image from "assets/images/curved-images/curved22.jpg";

function ProductsList(props) {
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const classes = styles({ image });
  const { t } = useTranslation();

  const onSubmit = event => {
    const { history } = props;
    auth
      .doSignInWithEmailAndPassword(email, password)
        .then((authUser) => {
          if (authUser) {
            db.ref('users/' + authUser.user.uid).once('value').then((snapshot) => {
              if (snapshot && (snapshot.val().claims==="officeWorker" || snapshot.val().claims==="trcAdmin")) {
                  setUserClaims(snapshot.val().claims)
                  setEmail('')
                  setPassword('')
                  history.push("/");
              } else {
                  auth.doSignOut()
                  alert("This user does not have access privileges.")
                }
            }).catch( e => {
              alert(e.message);
            })
          }
  
        })
        .catch(error => {
          alert(error.message);
        });
  };

  
  return (
    <PageLayout>
      <Grid container justifyContent="center" className={classes.coverLayout}>
        <Grid item xs={11} sm={8} md={5} xl={3}>
          <SuiBox mt={20} >
            <SuiBox pt={3} px={3}>
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold" textColor="info" textGradient>
                  {t("WelcomeAgain")}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" fontWeight="regular" textColor="text">
                {t("EnteryourEmailadressandpasswordtologin")}
              </SuiTypography>
            </SuiBox>
            <SuiBox p={3}>
              <SuiBox component="form" role="form">
                <SuiBox mb={2} lineHeight={1.25}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {t("Email")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                </SuiBox>
                <SuiBox mb={2} lineHeight={1.25}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {t("Password")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                </SuiBox>
                <SuiBox mt={4} mb={1}>
                  <SuiButton variant="gradient" buttonColor="info" onClick={() => onSubmit()} fullWidth>
                    {t("Login")}
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <SuiBox
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            customClass={classes.coverLayout_imageBox}
          >
            <SuiBox customClass={classes.coverLayout_image} />
          </SuiBox>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

const authCondition = authUser => 
  authUser == null; //true and false
export default withAuthorization(authCondition)(ProductsList);
