/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components

import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import Grid from "@mui/material/Grid";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import OrderInformation from "components/VesselDetail/OrderInformation";
import PastTransactions from "components/VesselDetail/PastTransactions";
import VesselInformation from "components/VesselDetail/VesselInformation";
import EquipmentList from "components/VesselDetail/EquipmentList";
import pageRoutes from "constants/pageRoutes";

function VesselDetail(props) {
  const { id } = props.match.params;

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid item xs={12} lg={12} mb={3}>
            <PastTransactions id={id} />
          </Grid>
          <Grid item xs={12} lg={12} mb={3}>
            <OrderInformation id={id} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid item xs={12} lg={12} mb={3}>
            <VesselInformation id={id} />
          </Grid>
          <Grid item xs={12} lg={12} mb={3}>
            <EquipmentList id={id} />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(VesselDetail);
