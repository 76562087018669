/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";

// @mui material components
import Card from "@mui/material/Card";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Swal from "sweetalert2";
import SuiTypography from "components/SuiTypography";
import SuiDatePicker from "components/SuiDatePicker";
import { useHistory } from "react-router-dom";
// Settings page components
import FormField from "components/FormField";
import { useTranslation } from "react-i18next";
import axios from "axios";

// Images
const sofa =
  "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80";

function ProductImage({ id }) {
  const [loading, setLoading] = useState(true);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [dataSet, setDataSet] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [uid, setUid] = useState(null);
  const [profession, setProfession] = useState(null);
  const [mail, setMail] = useState(null);
  const [name, setName] = useState(null);
  const [hesCode, setHesCode] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(["12.11.2021", "12.11.2021"]);
  const history = useHistory();
  const userToken = getAccessToken();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => {
    setOpen(false);
  };
  const passwordRequirements = [
    t("One special characters"),
    t("Min 6 characters"),
    t("One number (2 are recommended)"),
  ];

  const [repeatPassword, setRepeatPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onSubmit = () => {
    if (repeatPassword == newPassword && newPassword.length > 5) {
      // update password with axios url https://europe-west1-trc-marine.cloudfunctions.net/updatePassword
      try {
        axios
          .post(
            "https://europe-west1-trc-marine.cloudfunctions.net/updatePassword",
            {
              uid: uid,
              password: newPassword,
            }
          )
          .then(function (response) {
            Swal.fire({
              title: t("Password changed"),
              icon: "success",
              confirmButtonText: t("OK"),
            });
            setLoadingPassword(false);
          });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: t("Error"),
          text: t("An error has occurred"),
          icon: "error",
          confirmButtonText: t("OK"),
        });
        setLoadingPassword(false);
      }
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("Your password is not valid"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
      setLoadingPassword(false);
    }
  };

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SuiBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <SuiTypography
          variant="button"
          textColor="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SuiTypography>
      </SuiBox>
    );
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
            query users_by_pk {
              users_by_pk(id:${id}) {
              hes_code
              id
              uid
              mail
              name
              nationality
              profession
              user_type
              date_of_birth
            }
          }
        `,
        })
        .then((result) => {
          setDataSet(result.data.users_by_pk);
          setUid(result.data.users_by_pk.uid);
          setName(result.data.users_by_pk.name);
          setNationality(result.data.users_by_pk.nationality);
          setProfession(result.data.users_by_pk.profession);
          setDateOfBirth(result.data.users_by_pk.date_of_birth);
          setMail(result.data.users_by_pk.mail);
          setHesCode(result.data.users_by_pk.hes_code);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  let isHesCodeMatch = () => {
    return hesCode
      ? hesCode.match("^[A-Z][0-9][A-Z][0-9]-[0-9][0-9][0-9][0-9]-[0-9][0-9]$")
      : false;
  };

  const sentUpdate = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
            mutation update_users_by_pk {
              update_users_by_pk(pk_columns: {id: ${id}}, _set: {
                name: ${JSON.stringify(name)}, 
                nationality: ${JSON.stringify(nationality)},  
                profession:${JSON.stringify(profession)}, 
                mail: ${JSON.stringify(mail)},  
                hes_code: ${JSON.stringify(hesCode)}, 
                date_of_birth: ${JSON.stringify(dateOfBirth)}, 
                 }) {
                id
                uid
            }
        }
        `,
        })
        .then((result) => {
          Swal.fire(t("Successfully saved!"), " ", "success").then((result) => {
            if (result.isConfirmed) {
              history.push("/human/resources/detail/" + id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card>
      <SuiBox p={3}>
        {dataSet && !loading ? (
          <SuiBox>
            <SuiBox>
              <SuiBox
                mb={5}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {/* <SuiTypography
                  variant="h6"
                  alignSelf="center"
                  fontWeight="bold"
                  gutterBottom
                  textColor="text"
                >
                  {t("Edit Informations")}
                </SuiTypography> */}
                <SuiButton
                  variant="gradient"
                  buttonColor="info"
                  size="small"
                  onClick={() => sentUpdate()}
                >
                  {t("Save")}
                </SuiButton>
              </SuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label={t("Name")}
                    placeholder="N/A"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label={t("Nationality")}
                    placeholder="N/A"
                    value={nationality}
                    onChange={(event) => setNationality(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label={t("Profession")}
                    placeholder="N/A"
                    value={profession}
                    onChange={(event) => setProfession(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SuiBox>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {" "}
                      {t("Date of birth")}
                    </SuiTypography>
                    <SuiBox>
                      <SuiDatePicker
                        value={dateOfBirth}
                        onChange={setDateOfBirth}
                      />
                    </SuiBox>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label={t("Email")}
                    placeholder="SefaUmut@gmail.com"
                    value={mail}
                    disabled
                    onChange={(event) => setMail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label={t("Hes Code")}
                    placeholder="A2B3-1232-12"
                    value={hesCode}
                    // error={!isHesCodeMatch() && "Hes Code is not valid"}
                    // success={isHesCodeMatch()}
                    onChange={(event) => setHesCode(event.target.value)}
                  />
                </Grid>
              </Grid>
            </SuiBox>
            <Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: "flex" }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Card>
                    <SuiBox p={3}>
                      <Grid container display={"flex"} spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <SuiBox display="flex" justifyContent="space-between">
                            <SuiTypography variant="h5" fontWeight="bold">
                              {t("ChangePassword")}
                            </SuiTypography>
                            <SuiButton
                              variant="gradient"
                              buttonColor="info"
                              size="small"
                              iconOnly
                              onClick={handleClose}
                            >
                              <Icon
                                className="material-icons-round"
                                fontSize="large"
                              >
                                close
                              </Icon>
                            </SuiButton>
                          </SuiBox>
                        </Grid>
                        <Grid container style={{ justifyContent: "center" }}>
                          <SuiBox component="form" pb={3} py={3} px={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <FormField
                                  label={t("Enteryournewpassword")}
                                  placeholder={t("NewPassword")}
                                  value={newPassword}
                                  onChange={(event) =>
                                    setNewPassword(event.target.value)
                                  }
                                  inputProps={{
                                    type: "password",
                                    autoComplete: "",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <FormField
                                  label={t("Confirm Password")}
                                  placeholder={t("Confirm Password")}
                                  value={repeatPassword}
                                  onChange={(event) =>
                                    setRepeatPassword(event.target.value)
                                  }
                                  inputProps={{
                                    type: "password",
                                    autoComplete: "",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <SuiBox justifyContent="center" textAlign="center">
                              <SuiBox
                                component="ul"
                                pl={3.25}
                                mb={{ xs: 4, sm: 2 }}
                                mt={{ xs: 4, sm: 2 }}
                              >
                                {renderPasswordRequirements}
                              </SuiBox>
                              <SuiBox ml="auto">
                                <SuiButton
                                  buttonColor="dark"
                                  size="small"
                                  disabled={loadingPassword}
                                  onClick={() => {
                                    setLoadingPassword(true);
                                    onSubmit();
                                  }}
                                >
                                  {loadingPassword ? (
                                    <CircularProgress
                                      size="20px"
                                      color="white"
                                    />
                                  ) : (
                                    t("UPDATE")
                                  )}
                                </SuiButton>
                              </SuiBox>
                            </SuiBox>
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Card>
                </Grid>
              </Modal>
            </Card>
            <Grid container spacing={1} mt={3}>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-end"
              >
                <SuiButton
                  variant="outlined"
                  buttonColor="info"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  {t("ChangePassword")}
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default ProductImage;
