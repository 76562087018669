import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import "moment/locale/tr";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "Başlangıç ve Bitiş Tarihi": "Start and End Date",
          "Select Staff": "Select Staff",
          "Battery Date": "Battery Date",
          Type: "Type",
          "Sign Out": "Sign Out",
          AnnouncementDescription: "Enter the announcement description.",
          "Announcement.step1": "Announcement",
          "Battery End Date": "Battery End Date",
          "Change Date": "Change Date",
          "Certificate History": "Certificate History",
          About: "About",
          "File Add": "File Add",
          "Active Orders": "Active Services",
          Agent: "Agent",
          ActualDeliveryDate: "Actual Delivery Date",
          AddOrder: "Add Service",
          addvessel: "Add Vessel",
          LocationInfo: "You can see the location information",
          DaysLeft: "Days Left",
          Days: "Days",
          Day: "Day",
          AddaLocation: "Add a Location",
          AdditionalDocuments: "Additional Documents",
          AdditionalFiles: "Additional Files",
          "Add Service": "Add Service",
          Announcement: "Announcement",
          "Create your announcement...": "Create your announcement...",
          Approveyournewpassword: "Approve your new password",
          ArrivalLocation: "Arrival Location",
          "Assigned Staff": "Assigned Staff",
          available: "Available",
          busy: "Busy",
          AreYouSure: "Are you sure?",
          "You want to drop the car?": "You want to drop the car?",
          EditOrder: "Edit Service",
          Folders: "Folders",
          "Edit Folders": "Edit Folders",
          "Date of birth": "Date of birth",
          AverageOrderAmount: "Average Service Amount",
          Operatorordercount: "Operator Service Count",
          "Operator Weekend Number of Orders":
            "Operator Weekend Number of Services",
          "BATTERY : 0-45 Day": "BATTERY : 0-45 Day",
          "Battery Add": "Battery Add",
          "Equipment updated successfully": "Equipment updated successfully",
          "You want to delete the car?": "You want to delete the car?",
          Battery: "Battery",
          "Starting Date": "Starting Date",
          Edit: "Edit",
          Yes: "Yes",
          Cancel: "Cancel",
          "Boat Agency": "Boat Agency",
          "PlateNumber" : "Plate Number",
          "StartDate" : "Start Date",
          "EndDate" : "End Date",
          "UserName" : "User Name",
          Buttons: "Buttons",
          Canceled: "Canceled",
          "Canceled Orders": "Canceled Services",
          Tersane: "Shipyard",
          Certificate: "Certificate",
          "Certificate Add": "Certificate Add",
          "CERTIFICATE: 0-45 Day": "CERTIFICATE: 0-45 Day",
          "Jobs not found": "Jobs not found",
          ChangePassword: "Change Password",
          "Company Name": "Company Name",
          Completed: "Completed",
          ConfirmNewPassword: "Confirm New Password",
          Createnewuser: "Create new user",
          Crew: "Crew",
          Currency: "Currency",
          Operator: "Operator",
          OfficeWorker: "Office Worker",
          CurrentOrders: "Current Services",
          CustomsDeclaration: "Customs Declaration",
          CustomsStatus: "Customs Status",
          Dashboard: "Dashboard",
          Didyouforgetyourpassword: "Did you forget your password?",
          DropTheFileHere: "Drop the file here..",
          "Drag n drop file here, or click to select file":
            "Drag 'n' drop file here, or click to select file",
          Done: "Done",
          DutyStatus: "Duty Status",
          "E-mail": "E-mail",
          Email: "Email",
          "Recent Records": "Recent Records",
          info2: "Employees who has the most service count are shown.",
          EnterNewPassword: "Enter New Password",
          Enterinformationsaboutyourorder:
            "Enter informations about your service.",
          " Enter informations about your person.":
            " Enter informations about your person.",
          EnteryourEmailadressandpasswordtologin:
            "Enter your Email adress and password to login",
          Enteryournewpassword: "Enter your new password",
          Enteryourregisteredemailaddress:
            "Enter your registered e-mail address",
          "Account settings": "Account settings",
          EnterYourNote: "Enter Your Note",
          EnterYourFirstNote: "Enter Your First Note",
          EnterNewShipInformation: "Enter New Ship Information",
          "Confirm Password": "Confirm Password",
          Error: "Error",
          "Vehicle Past": "Vehicle Past",
          "One special characters": "One special characters",
          "Min 6 characters": "Min 6 characters",
          "One number (2 are recommended)": "One number (2 are recommended)",
          Export: "Export",
          ExportAsExcel: "Export As Excel",
          Fetch: "Fetch",
          Flag: "Flag",
          "Criminal Records": "Criminal Records",
          FilesNotFound: "Files Not Found",
          ForgetPassword: "Forgot Password",
          ForgotPassword: "Forgot Password?",
          "Folders not found.": "Folders not found.",
          GotoHomePage: "Go to Home Page",
          "Gross Tonnage": "Gross Tonnage",
          "Batt. Exp.": "Batt. Exp.",
          "Successfully added!": "Successfully added!",
          "Successfully updated!": "Successfully updated!",
          Success: "Success",
          "Vehicle updated successfully": "Vehicle updated successfully",
          Ok: "Ok",
          "Health Report": "Health Report",
          "Hes Code": "Hes Code",
          "Human Resources": "Human Resources",
          IMO: "IMO",
          InProgress: "In Progress",
          Invoice: "Invoice",
          LastLogs: "Last Logs",
          "List Active Orders": "List Active Services",
          "List Locations": "List Locations",
          "List Ships": "List Ships",
          "List Vessels": "List Vessels",
          "List Past Orders": "List Past Services",
          "Past Orders": "Past Services",
          Location: "Location",
          Locations: "Locations",
          Login: "Login",
          MonthlyOrderAmount: "Monthly Service Amount",
          MyAccount: "My Account",
          Name: "Name",
          callSign: "Call Sign",
          import1:
            "To see all the services, you can click 'See all services' button.",
          Nationality: "Nationality",
          Owner: "Owner",
          "Plate Number": "Plate Number",
          NewLocationAdded: "New Location Added",
          NewOrderAdded: "New Service Added",
          NewPassword: "New Password",
          "New Certificate Added": "New Certificate Added",
          "New Vessel Added.": "New Vessel Added.",
          "New Subject": "New Subject",
          Subject: "Subject",
          "New Message": "New Message",
          Message: "Message",
          Notes: "Notes",
          Order: "Service",
          Orders: "Services",
          "Enterinformationsaboutyourdelivery": "Enter informations about your delivery.",
          "Order Amount": "Service Amount",
          OrderDate: "Service Date",
          OrderInformations: "Service Informations",
          OrderInvoice: "Service Invoice",
          OrderOwner: "Service Owner",
          OrderPlaced: "Service Placed",
          OrderStatus: "Service Status",
          OrderEditing: "Service Editing",
          PageNotFound: "Page Not Found",
          PasOrders: "Past Services",
          "Past Transactions": "Past Transactions",
          "Past Transactions not found.": "Past Transactions not found.",
          Password: "Password",
          "Search...": "Search...",
          Passwordresetlinksenttoyourregisteredemailaddress:
            "Password reset link sent to your registered e-mail address",
          Passwordthatyouenteredarenotthesame:
            "Password that you entered are not the same.",
          Passport: "Passport",
          Personnel: "Personnel",
          "Personal Informations": "Personal Informations",
          Preview: "Preview",
          "Person Photo": "Person Photo",
          Informations: "Informations",
          "Personel Informations": "Personel Informations",
          PreviewOrder: "Preview Service",
          Process: "Process",
          ProcessInformationNotFound: "Process Information Not Found",
          Profile: "Profile",
          Profession: "Profession",
          Port: "Port",
          Readyforsupply: "Ready for supply",
          Register: "Register",
          RemainingDays: "Remaining Days",
          ResetPassword: "Reset Password",
          Resetyourpassword: "Reset your password",
          Remove: "Remove",
          Save: "Save",
          "See All": "See All",
          "Select Certificate": "Select Certificate",
          Ship: "Ship",
          "Ship Name": "Ship Name",
          ShipNameisrequired: "Ship Name is required.",
          Shipped: "Shipped",
          Ships: "Ships",
          "Certificates not found.": "Certificates not found.",
          ShipInformationStatus: "Ship Information Status",
          "Shows the order status instantly.":
            "Shows the service status instantly.",
          StatusInformations: "Status Informations",
          "Sign In": "Sign In",
          "Sign Out": "Sign Out",
          SignUp: "Sign Up",
          VesselInfo: "You can see the ship information.",
          Status: "Status",
          "Previous Jobs": "To Do List",
          TeamMembers: "Team Members",
          "The7digitIMOnumberisrequired.":
            "The 7-digit IMO number is required.",
          "Things To Do": "Things To Do",
          Thisuserdoesnothaveaccessprivileges:
            "This user does not have access privileges",
          Time: "Time",
          ToDo: "To Do",
          "Top Business Ports": "Top Business Ports",
          TopDocks: "Top Docks",
          DestinationPort: "Destination Port",
          TotalCrewDeliveries: "Total Crew Deliveries",
          TotalNumberofDeliveries: "Total Number of Deliveries",
          "Total Order Amount": "Total Service Amount",
          "Total Order Amount ($)": "Total Service Amount ($)",
          TypeOfOrder: "Type Of Service",
          Jobs: "Jobs",
          UPDATE: "UPDATE",
          "User Type": "User Type",
          Manufacter: "Manufacter",
          Vehicles: "Vehicles",
          "Delete Contact": "Delete Contact",
          LocationInformations: "Location Informations",
          "Ship Name/Delivery Location": " Ship Name/Delivery Location",
          ReferanceNumber: "Referance Number",
          WelcomeAgain: "Welcome Again",
          ReferanceNumberisrequired: "Referance Number is required",
          Wesuggestyoutogotothehomepagewhilewesolvethisissue:
            "We suggest you to go to the homepage while we solve this issue.",
          Writeyourfirstnote: "Write your first note",
          Writeyournote: "Write your note",
          entriesperpage: "Entries per page",
          Youcancreateanewuserbyenteringemailandpassword:
            "You can create a new user by entering email and password.",
          Youcanfollowyouractiveordersinthispage:
            "You can follow your active services in this page.",
          Youcanfollowyourpastordersinhere:
            "You can follow your past services in here.",
          "You can follow your announcement in here.":
            "You can follow your announcement in here.",
          "You can track personal information on this page.":
            "You can track personal information on this page.",
          Yourpasswordhasbeenupdated: "Your password has been updated",
          "You can track certificate information on this page.":
            "You can track certificate information on this page.",
          Yourprofile: "Your profile",
          add: "add",
          database: "Database",
          "Log Out": "Log Out",
          deliveryplace: "delivery place",
          next: "next",
          Back: "back",
          "Covid Vaccine Card": "Covid Vaccine Card",
          AddaNote: "Add a Note",
          Operators: "Operators",
          shippingmethod: "Shipping Method",
          Deliverycompany: "Delivery company",
          Deliverycompanyisrequired: "Delivery company is required.",
          DeliveryLocation: "Delivery Location",
          "Engine Supplier": "Boat Agency",
          ex: "ex.",
          Agent: "Agent",
          "Appointed Personels": "Appointed Personels",
          UploadAdditionalFiles: "Upload Additional Files",
          "Note for Delivery. *optional": "Note for Delivery. *optional",
          "Delivery Location": "Delivery Location",
          "Delivery Informations": "Delivery Informations",
          "Education Certificate": "Education Certificate",
          "Add Order": "Add Service",
          "Add Ship Order": "Add Ship Service",
          "Add Ship": "Add Ship",
          "Add Land Order": "Add Land Service",
          "Add Message": "Add Message",
          "Driving Licence": "Driving Licence",
          Identity: "Identity",
          "Edit Informations": "Edit Informations",
          Vessel: "Vessel",
          vesselName: "Vessel Name",
          "Vessel Name": "Vessel Name",
          "Enterinformationsaboutyourtodo": "Enter informations about your todo.",
          "Vessel List": "Vessel List",
          "Location List": "Location List",
          "No Equipment Found.": "No Equipment Found.",
          "Latest Jobs": "Latest Jobs",
          "Admission To Employment": "Admission To Employment",
          "Vessel Informations": "Vessel Informations",
          "Equipment List": "Equipment List",
          "Equipment Add": "Equipment Add",
          "Equipment Edit": "Equipment Edit",
          "Add Location": "Add Location",
          GrossTonnage: "Gross Tonnage",
          KelLand: "Kell Land",
          "Place Of Residance": "Place Of Residance",
          EnterNewLocationInformation: "Enter New Location Information",
          "Location Name": "Location Name",
          "Location Informations": "Location Informations",
          edit: "Edit",
          "End Date": "End Date",
          editVessel: "Edit Vessel",
          January: "January",
          February: "February",
          "Dosya Eklemek İster misiniz?": "Would you like to attach a file?",
          March: "March",
          April: "April",
          May: "May",
          typeOfOrder: "Working condition",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December",
          hasChanged: " has changed",
          sales: "Sales",
          dateOfBirth: "Date Of Birth",
          "Date Of Birth": "Date Of Birth",
          "Certificate expiry": "Certificate expiry",
          "Certificate not found.": "Certificate not found.",
          "Battery expiry": "Battery expiry",
          Add: "Add",
          "Certificate History": "Certificate History",
          Cancel: "Cancel",
          "Serial No": "Serial No",
          "Vehicles is not found" : "Vehicle history not found",
          "IMO/Referance Number": "IMO/Referance Number",
          Empty: "Empty",
          "Batarya Tarihi": "Battery Date",
          "New Certificate": "New Certificate",
          Delete: "Delete",
          "Successfully saved!": "Successfully saved!",
          "Ship informations status": "Ship Informations Status",
          "Assigned Operators": "Assigned Operators",
          "Jobs not found": "Jobs not found.",
          "Announcement not found.": "Announcement not found.",
          "Password changed": "Password changed",
          "Your password has been changed.": "Your password has been changed.",
          "An error has occurred.": "An error has occurred.",
          "Your password is not valid.": "Your password is not valid.",
          trcAdmin: "TRC Admin",
          operator: "Operator",
          officeWorker: "Office Worker",
          "Location Editing": "Location Editing",
          "Add Vehicle": "Add Vehicle",
          BatteryAdd: "Add Battery Date",
          "Enter New Vehicle Information": "Enter New Vehicle Information",
          "Vehicle Plate": "Vehicle Plate",
          "Vehicle Informations": "Vehicle Informations",
        },
      },
      tr: {
        translations: {
          "Başlangıç ve Bitiş Tarihi": "Başlangıç ve Bitiş Tarihi",
          "Select Staff": "Personel Seç",
          "Battery Date": "Batarya Tarihi",
          "Battery End Date": "Batarya Bitiş Tarihi",
          "Jobs not found.": "İş geçmişi bulunamadı.",

          Battery: "Batarya",
          Add: "Ekle",
          "Serial No": "Seri Numarası",
          "Admission To Employment": "İşe Kabul",
          "Equipment List": "Ekipman Listesi",
          "Equipment Add": "Ekipman Ekle",
          "Equipment updated successfully": "Ekipman başarıyla güncellendi.",
          "You want to delete the car?": "Araç silmek istiyor musunuz?",
          "Equipment Edit": "Ekipman Düzenle",
          "Past Orders": "Tamamlanmış Servisler",
          "Certificate History": "Sertifika Geçmişi",
          "Battery expiry": "Batarya bitişi",
          "Latest Jobs": "Son İşler",
          "Operator": "Operatör",
          "Office Worker": "Ofis Çalışanı",
          "OfficeWorker": "Ofis Çalışanı",
          "Batarya Tarihi": "Batarya Tarihi",
          "Certificate expiry": "Sertifika bitişi",
          "Certificate History": "Sertifika Geçmişi",
          "Change Date": "Tarih değişiklik zamanı",
          About: "Hakkında",
          "Account settings": "Hesap ayarları",
          "Edit Folders": "Dosyaları Düzenle",
          "Edit Informations": "Bilgileri Düzenle",
          "Active Orders": "Aktif Servisler",
          "Appointed Personels": "Atanmış Personel",
          "Actual Delivery Date": "Gerçek Teslimat Tarihi",
          Agent: "Acenta",
          "Sign Out": "Çıkış Yap",
          "Assigned Staff": "Atanan personel",
          "Days Left": "Kalan Gün",
          AreYouSure: "Emin misin?",
          Folders: "Dosyalar",
          "Delivery Informations": "Teslimat Bilgileri",
          Deliverycompanyisrequired: "Teslimat şirketi gereklidir.",
          Deliverycompany: "Teslimat Şirketi",
          Identity: "Kimlik",
          "Education Certificate": "Eğitim Sertifikası",
          Operators: "Operatörler",
          ActualDeliveryDate: "gerçekleşen teslimat tarihi",
          "Additional Files": "Ek Dosyalar",
          AddOrder: "Servis Ekle",
          addvessel: "Gemi Ekle",
          "Enterinformationsaboutyourdelivery": "Teslimat bilgilerini girin",
          "Vehicles is not found" : "Araç geçmişi bulunamadı",
          "PlateNumber" : "Plaka Numarası",
          "StartDate" : "Başlangıç Tarihi",
          "EndDate" : "Bitiş Tarihi",
          "Enterinformationsaboutyourtodo": "Yapılacak işlerin bilgilerini girin",
          "UserName" : "Kullanıcı Adı",
          "Vehicle Paste": "Araç Geçmişi",
          "File Add": "Dosya Ekle",
          Type: "Tür",
          Cancel: "İptal",
          Manufacter: "Üretici",
          AddaLocation: "Lokasyon Ekle",
          "Ship Name/Delivery Location": "Gemi Adı/Teslimat yeri",
          "Delivery Location": "Teslimat Yeri",
          AdditionalDocuments: "Ek Belgeler",
          LocationInformations: "Lokasyon Bilgileri",
          AdditionalFiles: "Ek Dosyalar",
          "Successfully updated!": "Başarıyla güncellendi!",

          AddaNote: "Not Ekle",
          "Add Service": "Servis Ekle",
          Announcement: "Duyuru",
          Informations: "Bilgiler",
          "Create your announcement...": "Duyuru Oluştur...",
          Approveyournewpassword: "Yeni Şifreyi Onayla",
          ArrivalLocation: "Gideceği Liman",
          available: "Müsait",
          busy: "Meşgul",
          "Batt. Exp.": "Batarya Bitiş Tarihi",
          EditOrder: "Servisi Düzenle",
          "Date of birth": "Doğum Tarihi",
          "Export To Excel": "Export To Excel",
          entriesperpage: "Sayfa başına servisler",
          AverageOrderAmount: "Ortalama Servis Tutarı",
          "Battery Add": "Batarya Ekle",
          LocationInfo: "Lokasyon bilgilerini görebilirsiniz.",
          "Boat Agency": "Motor Acentası",
          Buttons: "TUŞLAR",
          Canceled: "İptal Edildi",
          "Canceled Orders": "İptal Edilen İş",
          Tersane: "Tersane",
          VesselInfo: "Gemi bilgilerini görebilirsiniz.",
          Certificate: "Sertifika",
          typeOfOrder: "İş Durumu",
          "Certificate Add": "Sertifika Ekle",
          "Certificate not found.": "Sertifika bulunamadı.",
          "CERTIFICATE: 0-45 Day": "SERTİFİKA: 0-45 Gün",
          ChangePassword: "Şifre Değiştir",
          "Company Name": "Firma Adı",
          "Covid Vaccine Card": "Covid Aşı Kartı",
          "Place Of Residance": "İkametgah",
          ReferanceNumberisrequired: "Referans numarası gereklidir",
          Completed: "Tamamlandı",
          DaysLeft: "Kalan Gün",
          Days: "Gün",
          Day: "Gün",
          "Date Of Birth": "Doğum Tarihi",
          ConfirmNewPassword: "Yeni Şifreyi Onayla",
          Createnewuser: "Yeni kullanıcı ekle",
          Crew: "Personel",
          Currency: "Para birimi",
          "Status Informations": "Durum Bilgileri",
          "Criminal Records": "Adli Sicil Kaydı",
          CurrentOrders: "AKTİF SİPARİŞLER",
          CustomsDeclaration: "Gümrük Beyannamesi",
          "Customs Status": "Gümrük Durumu",
          Dashboard: "Panel",
          Didyouforgetyourpassword: "Şifremi Unuttum?",
          DropTheFileHere: "Dosyayı buraya bırak..",
          "Drag 'n' drop file here, or click to select file":
            "Dosyayı buraya sürükle ve bırak veya dosyayı seçmek için tıkla...",
          Done: "Bitti",
          DutyStatus: "Gümrük durumu",
          "E-mail": "Email",
          Email: "Email",
          "Enter Your First Note": "İlk Notunuzu Giriniz",
          "Enter Your Note": "Notunuzu Giriniz",
          DestinationPort: "Teslimat Limanı",
          Operatorordercount: "Operator Servis Sayısı",
          "Operator Weekend Number of Orders":
            "Operator Hafta Sonu Servis Sayısı",
          "BATTERY : 0-45 Day": "BATARYA : 0-45 Gün",
          "Starting Date": "Başlangıç Tarihi",
          Edit: "Düzenle",
          "Delete Contact": "Kişiyi sil",
          EnterNewPassword: "Yeni Şifrenizi Giriniz",
          Enterinformationsaboutyourorder:
            "Servisiniz ile ilgili bilgileri giriniz.",
          "Enter informations about your person.":
            "Personeliniz ile ilgili bilgileri giriniz.",
          EnteryourEmailadressandpasswordtologin:
            "Oturum açmak için Email adresinizi ve parolanızı girin.",
          Enteryournewpassword: "Yeni Şifrenizi Giriniz",
          Enteryourregisteredemailaddress:
            "Kayıtlı olduğunuz mail adresini giriniz",
          EnterYourNote: "Notunuzu Giriniz",
          EnterYourFirstNote: "İlk Notunuzu Giriniz",
          EnterNewShipInformation: "Yeni Gemi Bilgisini Giriniz",
          "Confirm Password": "Şifreyi Onayla",
          Error: "Hata",
          "Driving Licence": "Sürücü Ehliyeti",
          "One special characters": "Bir özel karakter",
          "Min 6 characters": "Minimum 6 karakter",
          "One number (2 are recommended)": "Bir rakam (2 tavsiye edilir)",
          "Recent Records": "Son Kayıtlar",
          Export: "Excel Aktar",
          "No Equipment Found.": "Hiçbir Ekipman Bulunamadı.",
          ExportAsExcel: "Excel aktar",
          Fetch: "Getir",
          Flag: "Bayrak",
          FilesNotFound: "Dosyalar Bulunamadı",
          ForgetPassword: "ŞİFRE UNUTMA",
          ForgotPassword: "Şifreni mi unuttun?",
          "Folders not found.": "Dosyalar bulunamadı.",
          "Certificates not found.": "Sertifikalar bulunamadı.",
          GotoHomePage: "Ana sayfaya git",
          "Gross Tonnage": "Brüt Tonaj",
          "Health Report": "Sağlık Raporu",
          "Hes Code": "Hes Kodu",
          "Human Resources": "İnsan Kaynakları",
          "Previous Jobs": "Yapılacak İşler",
          IMO: "IMO",
          InProgress: "Yapılıyor",
          Invoice: "Servis Faturası",
          LastLogs: "Son Kayıtlar",
          "You want to drop the car?": "Aracı bırakmak istiyor musun?",
          "List Active Orders": "Aktif Servisleri Listele",
          "List Locations": "Lokasyonları Listele",
          "List Ships": "Gemileri Listele",
          "List Vessels": "Gemileri Listele",
          "List Past Orders": "Geçmiş Servisleri Listele",
          Location: "Lokasyon",
          Locations: "Lokasyon Bilgileri",
          Login: "Giriş Yap",
          MonthlyOrderAmount: "Aylık Servis Tutarı",
          MyAccount: "Hesabım",
          Yes: "Evet",
          Cancel: "İptal",
          Name: "İsim",
          NewLocationAdded: "Yeni Lokasyon Eklendi",
          NewOrderAdded: "Yeni servis eklendi",
          NewPassword: "Yeni Şifre",
          "New Certificate Added": "Yeni Sertifika Eklendi",
          "New Vessel Added.": "Yeni Gemi Eklendi.",
          "New Subject": "Yeni Konu",
          Subject: "Konu",
          "New Message": "Yeni Mesaj",
          Message: "Mesaj",
          Notes: "Notlar",
          Order: "Servis",
          Orders: "Servisler",
          "Order Amount": "Servis Tutarı",
          "Order Date": "Servis Tarihi",
          "Order Informations": "Servis Bilgileri",
          OrderInvoice: "Servis Faturası",
          "Order Owner": "Servisi Ekleyen",
          OrderPlaced: "Servis Girildi",
          "Order Status": "Servis Durumu",
          "Order Editing": "Servis Düzenle",
          PageNotFound: "Sayfa Bulunamadı",
          PasOrders: "Completed Services",
          "Past Transactions": "Geçmiş İşlemler",
          "Past Transactions not found.": "Geçmiş işlem bulunamadı.",
          Password: "Parola",
          "Search...": "Arama...",
          Passwordresetlinksenttoyourregisteredemailaddress:
            "Kayıtlı e-posta adresinize şifre sıfırlama bağlantısı gönderdik.",
          Passwordthatyouenteredarenotthesame:
            "Girdiğiniz parola birbiriyle aynı değil.",
          Passport: "Pasaport",
          Personnel: "Personel",
          "Personal Informations": "Kişinin Bilgileri",
          "Person Photo": "Kişinin Fotoğrafı",
          "Personel Informations": "Personel bilgileri",
          PreviewOrder: "Servisi Görüntüle",
          Preview: "Ön izleme",
          Process: "İşlemler",
          ProcessInformationNotFound: "İş Süreci Bilgisi Bulunamadı",
          Profile: "Profil",
          Profession: "Uzmanlık",
          Port: "Liman",
          Readyforsupply: "İkmale Hazır",
          Register: "KAYIT OLMA",
          RemainingDays: "Kalan Gün",
          ResetPassword: "Şifre Sıfırlama",
          Success: "Başarılı",
          "Vehicle updated successfully": "Araç başarıyla güncellendi",
          Ok: "Tamam",
          Resetyourpassword: "Şifreyi Sıfırla",
          Remove: "Sil",
          Save: "Kaydet",
          "See All": "Tüm İşleri Gör",
          "Select Certificate": "Sertifika Seç",
          Ship: "Gemi",
          "Ship Name": "Gemi Adı",
          ShipNameisrequired: "Gemi adı zorunludur.",
          Shipped: "Sevk Edildi",
          "Dosya eklemek ister misiniz?": "Dosya eklemek ister misiniz?",
          Ships: "Gemi Bilgileri",
          ShipInformationStatus: "Gemi Bilgi Durumu",
          "Shows the order status instantly.":
            "Anlık olarak servis durumunu gösterir.",
          StatusInformations: "Durum Bilgileri",
          "Sign In": "Giriş Yap",
          "Sign Out": "Çıkış Yap",
          SignUp: "Kayıt Ol",
          Status: "Durumu",
          info2: "En fazla servis sayısına sahip olan çalışanlar gösterilir.",
          TeamMembers: "Ekip Üyeleri",
          "The7digitIMOnumberisrequired.": "7 haneli IMO numarası zorunlu.",
          "Things To Do": "Yapılacaklar",
          Thisuserdoesnothaveaccessprivileges:
            "Bu kullanıcının erişim yetkisi yok",
          Time: "Zaman",
          ToDo: "Yapılacak",
          BatteryAdd: "Bateri Tarihi Ekle",
          KelLand: "Salma Döşeme",
          "Dosya Eklemek İster misiniz?": "Dosya Eklemek İster misiniz?",
          "Top Business Ports": "En çok iş yapılan limanlar",
          TopDocks: "En çok iş yapılan Liman",
          "The 7-digit IMO number is required.":
            "7 haneli IMO numarası giilmelidir.",
          TotalCrewDeliveries: "Personel İş Sayısı",
          TotalNumberofDeliveries: "Toplam Yapılan Teslimat",
          "Total Order Amount": "Toplam servis Tutarı",
          "Total Order Amount ($)": "Toplam Servis Tutarı ($)",
          TypeOfOrder: "Gönderim Şekli",
          Jobs: "İşler",
          UPDATE: "Güncelle",
          "Successfully added!": "Başarıyla Eklendi!",
          "Upload Additional Files": "Ek Dosya Yükle",
          "User Type": "Kullanıcı Tipi",
          ReferanceNumber: "Referans Numarası",
          Vehicles: "Araçlar",
          "Export To Excel": "Excel'i Dışarı Aktar",
          WelcomeAgain: "Tekrar Hoşgeldiniz",
          Wesuggestyoutogotothehomepagewhilewesolvethisissue:
            "Biz bu sorunu çözerken ana sayfaya gitmenizi öneririz",
          Writeyourfirstnote: "İlk notunuzu yazınız",
          Writeyournote: "Notunuzu yazınız",
          Youcancreateanewuserbyenteringemailandpassword:
            "Email ve parola girerek yeni kullanıcı oluşturabilirsiniz",
          Youcanfollowyouractiveordersinthispage:
            "Güncel servislerinizi buradan takip edebilirsiniz.",
          Youcanfollowyourpastordersinhere:
            "Geçmiş servislerinizi buradan takip edebilirsiniz.",
          "You can follow your announcement in here.":
            "Duyurularınzı burdan takip edebilirsiniz.",
          "You can track personal information on this page.":
            "Kişisel bilgilerinizi bu sayfadan takip edebilirsiniz.",
          Yourpasswordhasbeenupdated: "Parolanız Güncellenmiştir",
          "You can track certificate information on this page.":
            "Sertifika bilgisi takibini bu sayfadan yapabilirsiniz.",
          Yourprofile: "Profiliniz",
          add: "ekle",
          Cancel: "İptal Et",
          import1:
            "Tüm servisleri görmek için 'Tüm servisleri gör' butonuna tıklayabilirsiniz.",
          Nationality: "Uyruk",
          Owner: "Armatör",
          "Plate Number": "Plaka",
          database: "Veri Tabanı",
          "Log Out": "Çıkış Yap",
          deliveryplace: "teslimat yeri",
          DeliveryLocation: "Teslimat Yeri",
          "Engine Supplier": "Motor Acentası",
          next: "ileri",
          Back: "geri",
          shippingmethod: "gönderim şekli",
          "Vessel Informations": "Gemi Bilgileri",
          vesselName: "Gemi Adı",
          "Vessel Name": "Gemi Adı",
          "Add Order": "Servis Ekle",
          "Add Ship Order": "Gemi Servisleri Ekle",
          "Add Ship": "Gemi Ekle",
          "Add Land Order": "Kara Servisleri Ekle",
          "Add Message": "Mesaj Ekle",
          "Add New Person": "Yeni Kişi Ekle",
          Vessel: "Gemi",
          AnnouncementDescription: "Duyuru Açıklamasını giriniz.",
          "Vessel List": "Gemi Listesi",
          "Location List": "Lokasyon Listesi",
          AddShip: "Gemi Ekle",
          "Add Location": "Lokasyon Ekle",
          EnterNewLocationInformation: "Yeni Lokasyon Bilgisi Giriniz",
          "Location Name": "Lokasyon İsmi",
          "Location Informations": "Lokasyon Bilgileri",
          edit: "Düzenle",
          "End Date": "Bitiş Tarihi",
          UploadAdditionalFile: "Ek Dosya Yükle",
          "Note for Delivery. *optional": "Teslimat için notlar *opsiyonel",
          editVessel: "Gemiyi Düzenle",
          January: "Ocak",
          February: "Şubat",
          March: "Mart",
          April: "Nisan",
          May: "Mayıs",
          June: "Haziran",
          July: "Temmmuz",
          August: "Ağustos",
          September: "Eylül",
          October: "Ekim",
          November: "Kasım",
          December: "Aralık",
          hasChanged: " değişti",
          sales: "Satışlar",
          " named ship has been assigned to you with Imo Number ":
            " isimli gemi şu Imo numarasına atandı : ",
          "IMO/Referance Number": "IMO/Referans Numarası",
          Empty: "Boş",
          "New Certificate": "Yeni Sertifika",
          Delete: "Sil",
          dateOfBirth: "Doğum Tarihi",
          "Successfully saved!": "Başarıyla kaydedildi!",
          "Ship informations status": "Gemi Durum Bilgisi",
          "Assigned Operators": "Atanan Operatörler",
          "Jobs not found.": "İş bulunamadı.",
          "Announcement not found.": "Duyuru bulunamadı.",
          "Arrival Location": "Varış Yeri",
          "Announcement.step1": "Duyuru",
          "Password changed": "Parola değiştirildi",
          "Your password has been changed": "Parolanız değiştirildi",
          "An error has occurred": "Bir hata oluştu",
          "Your password is not valid": "Parolanız geçersiz",
          trcAdmin: "TRC Admin",
          callSign: "Çağrı İşareti",
          GrossTonnage: "Brüt Tonaj",
          Cancel: "İptal Et",
          operator: "Operatör",
          officeWorker: "Ofis Çalışanı",
          "Location Editing": "Lokasyon Düzenleme",
          "Add Vehicle": "Araç Ekle",
          "Enter New Vehicle Information": "Yeni Araç Bilgilerini Giriniz",
          "Vehicle Plate": "Araç Plakası",
          "Vehicle Informations": "Araç Bilgileri",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });
// i18 language switcher for moment.js
moment.locale(i18n.language);

export default i18n;
