import React from 'react'
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import SignOutButton from './SignOut';
import { getUserClaims } from "constants/valueStorage";
import { auth } from '../firebase/firebase';
import cardDetails from './CardDetails';

function Navigation() {
    return (
        <div style={{position: "fixed", top: "0", width: "100%", zIndex: "99"}}>
          <Navbar className="header" collapseOnSelect expand="lg" bg="avs" variant="dark" style={{padding: "0 1rem" }}>
            <Navbar.Brand style={{padding: "0"}}>
              <Link to="/">
                <Button id="mylogo" variant="transparent">
                    <img src="/logo_avs.png" alt="AVS Logo" height={"46px"} />
                </Button>
              </Link>
            </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav variant="avs" className="m-auto">
                {
                  auth.currentUser && (
                    <Nav>
                      <NavLink exact activeClassName="active" to="/" className='navlink-avs'>
                          Active Orders
                      </NavLink>
                      <NavLink exact activeClassName="active" to="/order/history" className='navlink-avs'>
                          Order History
                      </NavLink>
                      <NavLink exact activeClassName="active" to="/order/add" className='navlink-avs'>
                          Add Order
                      </NavLink>
                      
                      { getUserClaims() == "trcAdmin" && <NavLink exact activeClassName="active" to="/signup" className='navlink-avs'>
                          Add User
                      </NavLink>}
                    </Nav>
                )}
                </Nav>
                {
                  auth.currentUser === null ?(
                  <Nav variant="avs-right">
                    <NavLink exact activeClassName="active" to="/signin" className='navlink-avs'>
                      SignIn/SignUp
                    </NavLink>
                  </Nav>):
                  (<Nav>
                    <NavLink exact activeClassName="active" to="/account" className='navlink-avs'>
                        Profile
                    </NavLink>
                    <SignOutButton />
                  </Nav>
                  ) 
                }                
              </Navbar.Collapse>
          </Navbar>
        </div>
    )
}

export default Navigation;
