/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    refnumber: {
      name: "refnumber",
      label: "Reference Number",
      type: "text",
      placeholder: "ex. 1234567",
    },
    deliveryCompany: {
      name: "deliveryCompany",
      label: "Delivery company",
      type: "text",
      placeholder: "ex. 365Ship",
      errorMsg: "Delivery company is required.",
    },
    company: {
      name: "company",
      label: "company name",
      type: "text",
      placeholder: "ex. 365Tech",
    },
    price: {
      name: "price",
      label: "order amount",
      type: "number",
      placeholder: "ex. 1907",
    },
    currency: {
      name: "currency",
      label: "currency",
      type: "text",
      placeholder: "ex. $ USD",
    },
    operators: {
      name: "operators",
      label: "Personnel",
      type: "text",
      placeholder: "ex. Adnan Kurt",
    },
    shippingMethod: {
      name: "shippingMethod",
      label: "shipping method",
      type: "text",
      placeholder: "ex. Kargo",
    },
    arrivalLoc: {
      name: "arrivalLoc",
      label: "delivery location",
      type: "text",
      placeholder: "ex. 365Tech servis",
    },
    note: {
      name: "note",
      label: "notes",
      placeholder: "Note for delivery. *optional",
    },
    plateNumber: {
      name: "plateNumber",
      label: "plate Number",
      type: "text",
      placeholder: "ex. 34BN6176",
    },
  },
};
