/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import FormField from "components/FormField";
import { useTranslation } from "react-i18next";

function WorkDetail({ id }) {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const [agent, setAgent] = useState(null);
  const [callSign, setCallSign] = useState(null);
  const [flag, setFlag] = useState(null);
  const [grosstonnage, setGrosstonnage] = useState(null);
  const [kellLand, setKelLand] = useState(null);
  const [mmsi, setMmsi] = useState(null);
  const [imo, setImo] = useState(null);
  const [name, setName] = useState(null);
  const [owner, setOwner] = useState(null);
  const [portState, setPortStete] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userToken = getAccessToken();

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = async () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessels_by_pk {
              vessels_by_pk(id: ${id}) {
                agent
                call_sign
                flag
                grosstonnage
                id
                imo
                kell_land
                mmsi
                name
                owner
                port_state
                }
            }
            `,
        })
        .then((result) => {
          setDataSet(result.data.vessels_by_pk);
          setAgent(result.data.vessels_by_pk.agent);
          setCallSign(result.data.vessels_by_pk.call_sign);
          setFlag(result.data.vessels_by_pk.flag);
          setGrosstonnage(result.data.vessels_by_pk.grosstonnage);
          setImo(result.data.vessels_by_pk.imo);
          setKelLand(result.data.vessels_by_pk.kell_land);
          setMmsi(result.data.vessels_by_pk.mmsi);
          setName(result.data.vessels_by_pk.name);
          setOwner(result.data.vessels_by_pk.owner);
          setPortStete(result.data.vessels_by_pk.port_state);
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const sentUpdate = () => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
                mutation update_vessels_by_pk {
                update_vessels_by_pk(pk_columns: {id: ${id}}, _set: {
                    agent: ${agent ? JSON.stringify(agent) : null} , 
                    call_sign: ${callSign ? JSON.stringify(callSign) : null} ,  
                    flag: ${flag ? JSON.stringify(flag) : null} , 
                    grosstonnage: ${
                      grosstonnage ? JSON.stringify(grosstonnage) : null
                    } ,  
                    kell_land:  ${kellLand ? JSON.stringify(kellLand) : null} , 
                    imo:  ${imo ? JSON.stringify(imo) : null} , 
                    mmsi: ${mmsi ? JSON.stringify(mmsi) : null} , 
                    name: ${name ? JSON.stringify(name) : null} , 
                    owner: ${owner ? JSON.stringify(owner) : null} ,  
                    port_state: ${
                      portState ? JSON.stringify(portState) : null
                    } ,
                     }) {
                    id
                }
            }
            `,
        })
        .then((result) => {
          getData();
          Swal.fire(t("Successfully saved!"), " ", "success").then((result) => {
            if (result.isConfirmed) {
              history.push("/db/vessel/detail/" + id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Vessel Informations")}
          </SuiTypography>
          <SuiButton
            onClick={() => sentUpdate()}
            variant="gradient"
            buttonColor="info"
            size="small"
          >
            {t("Save")}
          </SuiButton>
        </SuiBox>
        {dataSet && !loading ? (
          <Grid container mt={1} spacing={3}>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("Ship Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                placeholder="ex. 365."
                label="IMO"
                value={imo}
                onChange={(e) => setImo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("callSign")}
                value={callSign}
                onChange={(e) => setCallSign(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label="MMSI"
                value={mmsi}
                onChange={(e) => setMmsi(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("Owner")}
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                placeholder="ex. 365."
                label={t("GrossTonnage")}
                value={grosstonnage}
                onChange={(e) => setGrosstonnage(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("Agent")}
                value={agent}
                onChange={(e) => setAgent(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("Flag")}
                value={flag}
                onChange={(e) => setFlag(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                label="Port"
                placeholder="ex. 365Tech."
                value={portState}
                onChange={(e) => setPortStete(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                placeholder="ex. 365Tech."
                label={t("KelLand")}
                value={kellLand}
                onChange={(e) => setKelLand(e.target.value)}
              />
            </Grid>
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default WorkDetail;
