/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db, auth, fbstorage } from "firebase/firebase";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "constants/valueStorage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Folders from "components/Folders";
import { useTranslation } from "react-i18next";

function FolderInfo({ id }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState([]);
  const [criminalRecord, setCriminalRecord] = useState(null);
  const [healthReport, setHealthReport] = useState(null);
  const [passport, setPassport] = useState(null);
  const [diplomaOne, setDiplomaOne] = useState(null);
  const [ppd, setPpd] = useState(null);
  const [ekTwo, setEkTwo] = useState(null);
  const [drivingLicence, setDrivingLicence] = useState(null);
  const [diplomaTwo, setDiplomaTwo] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [educationCertificate, setEducationCertificate] = useState(null);
  const [covidVaccineCard, setCovidVaccineCard] = useState(null);
  const [placeResidance, setPlaceResidance] = useState(null);
  const [admissionEmployment, setAdmissionEmployment] = useState(null);
  const { t, i18n } = useTranslation();

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = () => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });

      client
        .query({
          query: gql`
            query users_by_pk {
              users_by_pk(id: ${id}) {
                user_resources (where: {status: {_eq: true}}){
                created_at
                resource_type
                id
                status
                file {
                  file_path
                  name
                }
              }
            }
        }
     `,
        })
        .then((result) => {
          result.data.users_by_pk.user_resources.length > 0
            ? setDataSet(result.data.users_by_pk.user_resources)
            : setLoading(false);
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (dataSet.length > 0) {
      dataSet.map((data) => {
        if (data.file) {
          switch (data.resource_type) {
            case "criminal_record":
              setCriminalRecord({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "passport":
              setPassport({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "health_report":
              setHealthReport({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "diploma_one":
              setDiplomaOne({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
              });
              break;
            case "ppd":
              setPpd({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "ek_two":
              setEkTwo({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "driving_licence":
              setDrivingLicence({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "diploma_two":
              setDiplomaTwo({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "identity":
              setIdentity({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "education_certificate":
              setEducationCertificate({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            case "covid_vaccine_card":
              setCovidVaccineCard({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
            case "place_residance":
              setPlaceResidance({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
            case "admission_employment":
              setAdmissionEmployment({
                name: data.file.name,
                file_path: data.file.file_path,
                id: data.id,
                status: data.status,
              });
              break;
            default:
              break;
          }
        }
      });
      setLoading(false);
    }
  }, [dataSet]);

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
        <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Folders")}
          </SuiTypography>
        </SuiBox>
        {dataSet.length > 0 && !loading ? (
          <SuiBox>
            <Grid container height={130} mb={5} spacing={4}>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Criminal Records")}
                  data={!loading && criminalRecord}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders title={t("Passport")} data={!loading && passport} />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Health Report")}
                  data={!loading && healthReport}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders title="Diploma" data={!loading && diplomaOne} />
              </Grid>
            </Grid>
            <Grid container height={115} mb={5} spacing={3}>
              <Grid item xs={3} lg={3}>
                <Folders title="PPD" data={!loading && ppd} />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders title="EK-2" data={!loading && ekTwo} />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Driving Licence")}
                  data={!loading && drivingLicence}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders title="Diploma 2" data={!loading && diplomaTwo} />
              </Grid>
            </Grid>
            <Grid container height={115} mb={5} spacing={3}>
              <Grid item xs={3} lg={3}>
                <Folders title={t("Identity")} data={!loading && identity} />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Education Certificate")}
                  data={!loading && educationCertificate}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Covid Vaccine Card")}
                  data={!loading && covidVaccineCard}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Place Of Residance")}
                  data={!loading && placeResidance}
                />
              </Grid>
            </Grid>
            <Grid container height={115} mb={5} spacing={3}>
              <Grid item xs={3} lg={3}>
                <Folders
                  title={t("Admission To Employment")}
                  data={!loading && admissionEmployment}
                />
              </Grid>
            </Grid>
          </SuiBox>
        ) : dataSet.length == 0 && !loading ? (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {t("Folders not found.")}
            </SuiTypography>
          </SuiBox>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default FolderInfo;
