/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Grapql components
import React, { useState, useEffect, Component } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import { Formik, Form } from "formik";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Swal from "sweetalert2";
import { auth } from "../../firebase/firebase";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/FooterTheme";
import pageRoutes from "constants/pageRoutes";
import LocationAdd from "components/Location/LocationAdd";
import validations from "components/schemas/validations";
import form from "components/schemas/form";
import initialValues from "components/schemas/initialValues";

function getSteps() {
  return ["Location Informations"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <LocationAdd formData={formData} />;
    default:
      return null;
  }
}

function ProductsList() {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const userToken = getAccessToken();
    const uid = auth.currentUser.uid;

    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const client = new ApolloClient({
      uri: "https://hasura-trc.365tech.dev/v1/graphql",
      headers,
      cache: new InMemoryCache(),
    });

    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation insert_locations_one {
            insert_locations_one(object: { 
              location: ${JSON.stringify(
                values.locationName.replace(/[^A-Za-z]/g, "")
              )}, 
              location_full: ${JSON.stringify(values.locationName)}, 
              }) {
              location
            }
          }
        `,
        })
        .then((result) => {
          Swal.fire(t("Successfully added!"), " ", "success");
           history.push("/db/location/list");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 6px)",
      right: "calc(50% + 6px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    zIndex: 2,
    ...(ownerState.active && {
      color: theme.palette.success.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#ffffff",
      zIndex: 2,
      fontSize: 18,
      backgroundColor: theme.palette.success.main,
      borderRadius: "50%",
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Stepper
              activeStep={activeStep}
              connector={<QontoConnector />}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SuiBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <SuiBox />
                          ) : (
                            <SuiButton
                              variant="gradient"
                              buttonColor="light"
                              onClick={handleBack}
                            >
                              geri
                            </SuiButton>
                          )}
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            buttonColor="dark"
                          >
                            {isLastStep ? "ekle" : "ileri"}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(ProductsList);
