/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import withAuthorization from "../withAuthorization"; //redirects to sign in if user not signed in
import moment from "moment";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import pageRoutes from "constants/pageRoutes";
import certificateList from "components/data/certificateList";
import { useTranslation } from "react-i18next";

function CertificateList() {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });

      client
        .query({
          query: gql`
            query vessels {
              vessels(order_by: {vessel_certificates_aggregate: {count: desc}}) {
                id
                name
                imo
                vessel_certificates_aggregate(where: {status: {_eq: true}, change_date: {_is_null: true}, certificate_expiry_date: {_lte: ${JSON.stringify(
                  moment().add(45, "day")
                )}}}) {
                  aggregate {
                    count
                  }
                }
              }
            }
          `,
        })
        .then((result) => {
          client
            .query({
              query: gql`
              query vessels {
                vessels(order_by: {vessel_certificates_aggregate: {count: desc}}) {
                  id
                  name
                  imo
                  vessel_certificates_aggregate(where: {status: {_eq: true}, change_date: {_is_null: true}, battery_expiry_date: {_lte: ${JSON.stringify(
                    moment().add(45, "day")
                  )}}}) {
                    aggregate {
                      count
                    }
                  }
                }
              }
            `,
            })
            .then((result2) => {
              setDataSet(
                certificateList({
                  dataset: result.data.vessels,
                  dataset2: result2.data.vessels,
                })
              );
              setLoading(false);
            });
        });
    }
    setLoading(false);
  }, [auth.currentUser]);
  //  ship_infos_aggregate(where: {delivery_date: {_gte: ${JSON.stringify(moment().startOf('month'))}, _lte: ${JSON.stringify(moment().endOf('month'))}} ,order_currencies: {_eq: USD}, status: {_eq: delivered}}){
  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="bold" textColor="text">
                {t("Certificate")}
              </SuiTypography>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textColor="text"
              >
                {t("You can track certificate information on this page.")}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          {dataSet && !loading ? (
            <DataTable
              table={dataSet}
              entriesPerPage={{
                defaultValue: 16,
                entries: [16, 32, 64],
              }}
              canSearch
            />
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="TRC Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const authCondition = (authUser) => !!authUser; //true and false
export default withAuthorization(authCondition)(CertificateList);
