import React, { useState } from "react";
import { auth } from "../firebase";
import { Link, NavLink } from "react-router-dom";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { Nav, Button } from "react-bootstrap";
import SuiButton from "components/SuiButton";
import { GroupsSharp, Logout, PersonAdd } from "@mui/icons-material";
import stringAvatar from "assets/theme/functions/stringAvatar";
import { useTranslation } from "react-i18next";
import SuiBox from "components/SuiBox";
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
const SignOutLink = ({ user }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  return (
    <>
      {user ? (
        <>
          <SuiBox>
            <Tooltip title={t("Account settings")}>
              <IconButton
                onClick={handleOpenMenu}
                size="small"
                sx={{ ml: 2, p: 0 }}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar
                  {...stringAvatar({
                    name: user.email,
                    width: 46,
                    height: 46,
                    fontSize: 16,
                  })}
                  size="sx"
                />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={openMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                component={Link}
                to="/human/resources"
                onClick={handleCloseMenu}
                variant="gradient"
                size="small"
                sx={{ marginLeft: 1 }}
              >
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>{" "}
                {t("Human Resources")}
              </MenuItem>
              <MenuItem
                component={Link}
                to="/announcement"
                onClick={handleCloseMenu}
                variant="gradient"
                size="small"
                sx={{ marginLeft: 1 }}
              >
                <ListItemIcon>
                  <GroupsSharp fontSize="small" />
                </ListItemIcon>{" "}
                {t("Announcement")}
              </MenuItem>
              <Divider />
              <MenuItem
                component={Link}
                to="/signin"
                onClick={auth.doSignOut}
                variant="gradient"
                size="small"
                sx={{ marginLeft: 1 }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>{" "}
                {t("Sign out")}
              </MenuItem>
            </Menu>
          </SuiBox>
        </>
      ) : (
        <SuiButton
          component={Link}
          to="/signin"
          variant="gradient"
          buttonColor="info"
          size="small"
          circular
        >
          {t("Sign In")}
        </SuiButton>
      )}
    </>
  );
};

export default SignOutLink;
