/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Bu sayfanın cevirisini yapamadım:(

import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { auth, fbstorage } from "firebase/firebase";
import { getAccessToken } from "constants/valueStorage";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import moment from "moment";

const ProductInfo = ({ id }) => {
  const [shipInfo, setShipInfo] = useState();
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query ship_infos_by_pk {
              ship_infos_by_pk(id: ${id}) {
                id
                ETD
                ETA
                ETB
                UserUID
                delivery_date
                planned_delivery_date
                created_at
                plate_number
                order_total
                order_currencies
                currency {
                  symbol
                }
                company_name
                engine_supplier
                arrival_loc_name
                ship_office_worker_name {
                  name
                }
                ship_oparator_name {
                  name
                }
                imo
                agent
                shipname
                shippingMethodByShippingMethod {
                  method_desc
                  method
                }            
                locations {
                  location
                  location_full
                }
                customsStatusByCustomsStatus {
                  customs_status_desc
                  customs_status
                }
                ship_infos_status {
                  status_desc
                  status
                }
                assigned_jobs {
                  uid
                  users {
                    name
                  }
                }
                delivery_type
                delivery_company
                order_number
              }
            }
          `,
        })
        .then((result) => {
          setShipInfo(result.data.ship_infos_by_pk);
        });
    }
  };

  return (
    <SuiBox>
      <Card className="overflow-visible">
        <SuiBox p={3}>
          <SuiBox display="flex" justifyContent="space-between">
            <SuiTypography
              variant="h6"
              gutterBottom
              alignSelf="center"
              fontWeight="bold"
              textColor="text"
            >
              {t("Order Informations")}
            </SuiTypography>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => history.push("/order/edit/" + id)}
            >
              {t("Edit")}
            </SuiButton>
          </SuiBox>
          {shipInfo != null ? (
            <SuiBox>
              <SuiBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("imo")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                      >
                        {shipInfo.imo}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Ship Name")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.shipname}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Order Date")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.created_at &&
                          moment(shipInfo.created_at, "YYYY-MM-DD").format(
                            "DD.MM.YYYY"
                          )}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Agent")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.agent && shipInfo.agent}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Company Name")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.company_name && shipInfo.company_name}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Order Owner")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.ship_office_worker_name &&
                          shipInfo.ship_office_worker_name.name}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
            >
              <img
                src="/loader.gif"
                alt="AVS Logo"
                style={{
                  objectFit: "none",
                  height: "160px",
                  width: "100%",
                  borderRadius: "50px",
                }}
              />
            </SuiBox>
          )}
        </SuiBox>
      </Card>
      <SuiBox mt={3}>
        <Card className="overflow-visible">
          <SuiBox p={3}>
            <SuiTypography
              variant="h6"
              fontWeight="bold"
              textColor="text"
              gutterBottom
            >
              {t("Status Informations")}
            </SuiTypography>
            {shipInfo != null ? (
              <SuiBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Ship informations status")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.ship_infos_status &&
                          shipInfo.ship_infos_status.status_desc}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("Customs Status")}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      width="100%"
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {shipInfo.customsStatusByCustomsStatus &&
                          shipInfo.customsStatusByCustomsStatus
                            .customs_status_desc}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            ) : (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={0}
              >
                <img
                  src="/loader.gif"
                  alt="AVS Logo"
                  style={{
                    objectFit: "none",
                    height: "160px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox mt={3}>
        <Card className="overflow-visible">
          <SuiBox p={3}>
            <SuiTypography
              variant="h6"
              fontWeight="bold"
              textColor="text"
              gutterBottom
            >
              {t("Delivery Informations")}
            </SuiTypography>
            {shipInfo != null ? (
              <SuiBox>
                <SuiBox mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Assigned Operators")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.assigned_jobs &&
                            shipInfo.assigned_jobs
                              .map((item) => item.users.name)
                              .join(", ")}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("typeOfOrder")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.shippingMethodByShippingMethod &&
                            shipInfo.shippingMethodByShippingMethod.method_desc}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Engine Supplier")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.engine_supplier && shipInfo.engine_supplier}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Plate Number")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.plate_number && shipInfo.plate_number}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Arrival Location")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.locations &&
                            shipInfo.locations.location_full}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Actual Delivery Date")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.delivery_date &&
                            moment(shipInfo.delivery_date, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETA
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.ETA &&
                            moment(shipInfo.ETA, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETB
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.ETB &&
                            moment(shipInfo.ETB, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          ETD
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        width="100%"
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                        >
                          {shipInfo.ETD &&
                            moment(shipInfo.ETD, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  </Grid>
                </SuiBox>
              </SuiBox>
            ) : (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={0}
              >
                <img
                  src="/loader.gif"
                  alt="AVS Logo"
                  style={{
                    objectFit: "none",
                    height: "160px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
    </SuiBox>
  );
};

export default ProductInfo;
