/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import ActionCellLocation from "components/ActionCell/location";
function dataTableData({ dataset }) {
  var data = [];
  if (dataset.length > 0) {
    dataset.map((item) => {
      data.push({
        location: item.location_full,
        action: <ActionCellLocation id={item.id} />,
      });
    });
  }

  return {
    columns: [
      {
        Header: "Locations",
        width: "90%",
        name: "location",
        accessor: "location",
      },
      {
        Header: "İşlemler",

        name: "action",
        accessor: "action",
      },
    ],

    rows: [...data],
  };
}
// Setting default values for the props of DashboardNavbar
dataTableData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
dataTableData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};

export default dataTableData;
