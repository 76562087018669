/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FlatList from "flatlist-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import Dropzone from "components/Dropzone";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
// Custom styles for ComplexProjectCard
import style from "components/Cards/ProjectCards/ComplexProjectCard/styles";
import moment from "moment";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import ViewFile from "components/ViewFile";

function useWindowSize() {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return size;
}

function ComplexProjectCard({
  id,
  certId,
  color,
  type,
  image,
  title,
  dateTimeBattery,
  dateTime,
  dateExpiry,
  dateBatteryExpiry,
  battery,
  filePath,
  description,
  members,
  onInsert,
  onDelete,
}) {
  const classes = style();
  const [menu, setMenu] = useState(null);
  const [dropzone, setDropzone] = useState(false);
  const [editDropzone, setEditDropzone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [batteryExpiryDate, setBatteryExpiryDate] = useState("");
  const [batteryStartDate, setBatteryStartDate] = useState("");
  const [open, setOpen] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const { t, i18n } = useTranslation();
  const [height, width] = useWindowSize();

  // const [date, setDate] = useState("");

  const menuAction = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => setDropzone(true)}> {t("Add")}</MenuItem>
      <MenuItem onClick={() => setOpen(true)}>
        {" "}
        {t("Certificate History")}
      </MenuItem>
      {filePath && (
        <MenuItem
          onClick={() => {
            onDelete(certId);
            setDataSet([]);
            setEditDropzone(false);
            setDropzone(false);
            setMenu(null);
          }}
        >
          {t("Delete")}
        </MenuItem>
      )}
      {/* <MenuItem onClick={() => setDropzone(true)}>
        Remove
      </MenuItem> */}
      {/* <MenuItem onClick={close}>Delete</MenuItem> */}
    </Menu>
  );

  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;

    return (
      <SuiAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        customClass={classes.complexProjectCard_avatar}
      />
    );
  });

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const getData = async () => {
    setLoading(true);
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query vessels {
              vessels_by_pk(id: ${id}) {
                vessel_certificates(where: { status: {_eq: true}, certificate_type: {_eq: ${type}}}, order_by: {created_at: asc}) {
                  id
                  certificate_start_date
                  certificate_expiry_date
                  battery_start_date
                  battery_expiry_date
                  certificate_type
                  change_date
                }
              }
            }
          `,
        })
        .then((result) => {
          result.data.vessels_by_pk.vessel_certificates.length > 0
            ? setDataSet(result.data.vessels_by_pk.vessel_certificates)
            : setLoading(false);
        });
    }
  };

  const deleteFile = (resourceId, setDataSet) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation user_resources_by_pk {
            update_user_resources_by_pk(pk_columns: {id: ${resourceId}}, _set: {status: false}) {
              id
              status
            }
          }    
          `,
        })
        .then((result) => {
          setDataSet({});
          //  (result.data.ship_infos_by_pk && result.data.ship_infos_by_pk.files && result.data.ship_infos_by_pk.files.length > 0 ) && setFiles(result.data.ship_infos_by_pk.files)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderItem = (item) => (
    <Grid container p={3}>
      <Grid item xs={12} sm={2.5}>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {t("Starting Date")}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="regular"
          >
            {item.certificate_start_date}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={12} sm={2}>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {t("End Date")}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="regular"
          >
            {item.certificate_expiry_date}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {t("Battery Date")}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="regular"
          >
            {item.battery_start_date}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {t("Battery End Date")}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="regular"
          >
            {item.battery_expiry_date}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={12} sm={2}>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {t("Change Date")}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0} width="100%">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="regular"
          >
            {item.change_date}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );

  return (
    <Card>
      {!dropzone && !editDropzone ? (
        <SuiBox p={3}>
          <SuiBox display="flex" alignItems="center">
            <SuiAvatar
              src={image}
              alt={title}
              size="xl"
              variant="rounded"
              backgroundColor={color}
              customClass={classes.complexProjectCard_image}
            />
            <SuiBox ml={1} lineHeight={0}>
              <SuiBox mb={0} lineHeight={0} display={"flex"}>
                <SuiTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="medium"
                >
                  {title}
                </SuiTypography>
                {filePath && (
                  <ViewFile
                    filePath={filePath}
                    style={{
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </SuiBox>
              {members.length > -1 ? (
                <SuiBox display="flex">{renderMembers}</SuiBox>
              ) : null}
            </SuiBox>
            <SuiTypography
              textColor="secondary"
              onClick={menuAction}
              customClass={classes.complexProjectCard_dropdownIcon}
            >
              <Icon fontSize="default" className=" cursor-pointer">
                more_vert
              </Icon>
            </SuiTypography>
            {renderMenu(menu, closeMenu)}
          </SuiBox>
          <SuiBox my={2} lineHeight={1}>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
            >
              {description}
            </SuiTypography>
          </SuiBox>
          <Divider />
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SuiBox display="flex" flexDirection="column" lineHeight={0}>
              {dateExpiry && (
                <SuiTypography variant="caption" fontWeight="medium">
                  {t("Certificate expiry")} {moment(dateExpiry).fromNow()}
                </SuiTypography>
              )}
              {dateBatteryExpiry && (
                <SuiTypography variant="caption" fontWeight="medium">
                  {t("Battery expiry")} {moment(dateBatteryExpiry).fromNow()}
                </SuiTypography>
              )}
            </SuiBox>
            <SuiBox display="flex" flexDirection="column" textAlign="right">
              {dateTime && (
                <SuiTypography
                  variant="caption"
                  fontWeight="medium"
                  textColor="secondary"
                >
                  {dateTime}
                </SuiTypography>
              )}
              {dateTimeBattery && (
                <SuiTypography
                  variant="caption"
                  fontWeight="medium"
                  textColor="secondary"
                >
                  {dateTimeBattery}
                </SuiTypography>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      ) : (
        <Grid container p={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <Dropzone
              id={id}
              type={"vesselcertificate"}
              returnValue={setFile}
              onDelete={deleteFile}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <SuiBox mt={1}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                display="flex"
                justifyContent="center"
                textTransform="capitalize"
                marginBottom={0.5}
              >
                {t("Başlangıç ve Bitiş Tarihi")}
              </SuiTypography>
              <SuiBox display="flex" justifyContent="center">
                <SuiBox mr={0.5}>
                  <SuiDatePicker value={startDate} onChange={setStartDate} />
                </SuiBox>
                <SuiBox ml={0.5}>
                  <SuiDatePicker value={expiryDate} onChange={setExpiryDate} />
                </SuiBox>
              </SuiBox>
            </SuiBox>
            {battery && (
              <SuiBox mt={1}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  display="flex"
                  justifyContent="center"
                  textTransform="capitalize"
                  marginBottom={0.5}
                >
                  {t("Batarya Tarihi")}
                </SuiTypography>
                <SuiBox display="flex" justifyContent="center">
                  <SuiBox mr={0.5}>
                    <SuiDatePicker
                      value={batteryStartDate}
                      onChange={setBatteryStartDate}
                    />
                  </SuiBox>
                  <SuiBox ml={0.5}>
                    <SuiDatePicker
                      value={batteryExpiryDate}
                      onChange={setBatteryExpiryDate}
                    />
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            )}
            <Grid container mt={1} display={"flex"} justifyContent={"center"}>
              <Grid>
                <SuiButton
                  variant="gradient"
                  buttonColor="success"
                  size="small"
                  circular
                  onClick={() => {
                    setEditDropzone(false);
                    setDropzone(false);
                    setMenu(null);
                    onInsert(
                      batteryStartDate,
                      batteryExpiryDate,
                      expiryDate,
                      startDate,
                      type,
                      certId,
                      file
                    );
                  }}
                  disabled={
                    !expiryDate ||
                    !startDate ||
                    (battery && (!batteryExpiryDate || !batteryStartDate)) ||
                    !file
                  }
                >
                  {t("Save")}
                </SuiButton>
              </Grid>
              <Grid ml={2}>
                <SuiButton
                  variant="gradient"
                  buttonColor="error"
                  size="small"
                  circular
                  onClick={() => {
                    setEditDropzone(false);
                    setDropzone(false);
                    setMenu(null);
                  }}
                >
                  {t("Cancel")}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Card style={{ marginBottom: "20px" }}>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setExpiryDate("");
            setStartDate("");
            setBatteryExpiryDate("");
            setBatteryStartDate("");
            setFile(null);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            spacing={3}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              right: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
            }}
          >
            <Card
              sx={{
                width: "100%",
              }}
            >
              <SuiBox p={3}>
                <Grid container display={"flex"} spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SuiBox display="flex" justifyContent="space-between">
                      <SuiTypography
                        variant="h6"
                        fontWeight="bold"
                        gutterBottom
                        textColor="text"
                      >
                        {t("Certificate History")}
                      </SuiTypography>
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        iconOnly
                        onClick={() => {
                          setOpen(false);
                          setExpiryDate("");
                          setStartDate("");
                          setBatteryExpiryDate("");
                          setBatteryStartDate("");
                          setFile(null);
                        }}
                      >
                        <Icon className="material-icons-round" fontSize="large">
                          close
                        </Icon>
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
              <FlatList
                list={dataSet}
                renderItem={renderItem}
                renderWhenEmpty={() =>
                  loading ? (
                    <Card style={{ marginBottom: "10px" }}>
                      <img
                        src="/loader.gif"
                        alt="TRC Logo"
                        style={{
                          objectFit: "none",
                          height: "160px",
                          width: "100%",
                          borderRadius: "50px",
                        }}
                      />
                    </Card>
                  ) : (
                    <SuiBox p={3}>
                      <SuiBox display="grid" justifyContent="center">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {t("Certificate not found.")}
                        </SuiTypography>
                      </SuiBox>
                    </SuiBox>
                  )
                }
              />
            </Card>
          </Grid>
        </Modal>
      </Card>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "transparent",
  dateTime: "",
  members: [],
  battery: false,
  filePath: null,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "transparent",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  filePath: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  battery: PropTypes.bool,
};

export default ComplexProjectCard;
