/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "components/OrderSchemas/form";

const {
  formField: {
    imo,
    shipName,
    agent,
    company,
    price,
    currency,
    operators,
    shippingMethod,
    arrivalLoc,
    engineSupplier,
    eta,
    etb,
    etd,
    note,
    todo,
  },
} = checkout;

export default {
  [imo.name]: "",
  [shipName.name]: "",
  [agent.name]: "",
  [company.name]: "",
  [price.name]: "",
  [currency.name]: "",
  [operators.name]: "",
  [shippingMethod.name]: "",
  [arrivalLoc.name]: "",
  [engineSupplier.name]: "",
  [eta.name]: "",
  [etb.name]: "",
  [etd.name]: "",
  [note.name]: "",
  [todo]: [],
  [todo.name]: "",
};
