/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelectFormik from "components/SuiSelectFormik";
import SuiButton from "components/SuiButton";
import SuiDatePickerFormik from "components/SuiDatePickerFormik";

// Page components
import FormFieldError from "components/FormFieldError";
import SuiDatePicker from "components/SuiDatePicker";

function UserInfo({ formData }) {
  const { t, i18n } = useTranslation();

  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, nationality, profession, email, hesCode, userType, password, confirmPassword, dateOfBirth } = formField;
  const {
    name: nameV,
    nationality: nationalityV,
    profession: professionV,
    email: emailV,
    hesCode: hesCodeV,
    userType: userTypeV,
    password: passwordV,
    confirmPassword: confirmPasswordV,
    dateOfBirth: dateOfBirthV,
  } = values;
  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h6" fontWeight="bold" textColor="text">
          {t("Personel Informations")}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {t("Enter informations about your person.")}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={name.type}
              label={t(name.label)}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiSelectFormik
              name={userType.name}
              value={userTypeV}
              label={t(userType.label)}
              placeholder={userType.placeholder}
              error={errors.userType && touched.userType}
              success={userTypeV && !errors.userType}
              options={[
                { value: "officeWorker", label: "Office Worker" },
                { value: "operator", label: "Operator" },
                { value: "trcAdmin", label: "Trc Admin" },
              ]}
              isClearable
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={password.type}
              label={t(password.label)}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV && !errors.password}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={confirmPassword.type}
              label={t(confirmPassword.label)}
              name={confirmPassword.name}
              value={confirmPasswordV}
              placeholder={confirmPassword.placeholder}
              error={errors.confirmPassword && touched.confirmPassword}
              success={confirmPasswordV && !errors.confirmPassword}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={profession.type}
              label={t(profession.label)}
              name={profession.name}
              value={professionV}
              placeholder={profession.placeholder}
              success={professionV && !errors.profession}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={email.type}
              label={t(email.label)}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={hesCode.type}
              label={t(hesCode.label)}
              name={hesCode.name}
              value={hesCodeV}
              placeholder={hesCode.placeholder}
              error={!hesCodeV.match("^[A-Z][0-9][A-Z][0-9]-[0-9][0-9][0-9][0-9]-[0-9][0-9]$") && touched.hesCode}
              success={hesCodeV.match("^[A-Z][0-9][A-Z][0-9]-[0-9][0-9][0-9][0-9]-[0-9][0-9]$") && !errors.hesCode} 
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldError
              type={nationality.type}
              label={t(nationality.label)}
              name={nationality.name}
              value={nationalityV}
              placeholder={nationality.placeholder}
              success={nationalityV.length > 0 && !errors.nationality}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} >
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t(dateOfBirth.label)}
            </SuiTypography>
            <SuiDatePickerFormik 
              name={dateOfBirth.name}
              value={dateOfBirthV}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,

};

export default UserInfo;
