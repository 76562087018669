/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
// @mui material components
import Icon from "@mui/material/Icon";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CodeIcon from "@mui/icons-material/Code";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import CakeIcon from "@mui/icons-material/Cake";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import TimelineItem from "components/Timeline/TimelineItem";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";

// Images
const sofa =
  "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80";

function ProductImage({ id }) {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);
  const history = useHistory();
  const [profession, setProfession] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [hesCode, setHesCode] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });
      client
        .query({
          query: gql`
          query users_by_pk {
            users_by_pk(id:${id}) {
            hes_code
            id
            mail
            name
            nationality
            profession
            user_type
            date_of_birth
          }
        }
      `,
        })
        .then((result) => {
          setDataSet(result.data.users_by_pk);
          setName(result.data.users_by_pk.name);
          setNationality(result.data.users_by_pk.nationality);
          setProfession(result.data.users_by_pk.profession);
          setDateOfBirth(result.data.users_by_pk.date_of_birth);
          setEmail(result.data.users_by_pk.mail);
          setHesCode(result.data.users_by_pk.hes_code);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  return (
    <Card>
      <SuiBox p={3}>
        {/* <SuiBox
          component="img"
          src={sofa}
          alt="Product Image"
          borderRadius="lg"
          boxShadow="lg"
          width="100%"
        /> */}
        <SuiBox display="flex" justifyContent="flex-end">
          {/* <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {t("Informations")}
          </SuiTypography> */}
          <SuiButton
            variant="gradient"
            buttonColor="info"
            size="small"
            onClick={() => history.push("/human/resources/edit/" + id)}
          >
            {t("Edit")}
          </SuiButton>
        </SuiBox>
        {dataSet && !loading ? (
          <Grid container spacing={3}>
            <>
              <SuiBox p={3}>
                <TimelineItem
                  color="info"
                  icon="account_circle"
                  title={t("Name")}
                  dateTime={name}
                />
                <TimelineItem
                  color="info"
                  icon="email"
                  title={t("Email")}
                  dateTime={email}
                />
                <TimelineItem
                  color="info"
                  icon={<CodeIcon color="info" />}
                  title={t("Hes Code")}
                  dateTime={hesCode}
                />
                <TimelineItem
                  color="info"
                  icon={<InfoIcon color="info" />}
                  title={t("Profession")}
                  dateTime={profession}
                />
                <TimelineItem
                  color="info"
                  icon={<AccessibilityNewIcon color="info" />}
                  title={t("Nationality")}
                  dateTime={nationality}
                />
                <TimelineItem
                  color="info"
                  icon={<CakeIcon color="info" />}
                  title={t("dateOfBirth")}
                  dateTime={dateOfBirth}
                />
              </SuiBox>
            </>
          </Grid>
        ) : (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0}
          >
            <img
              src="/loader.gif"
              alt="TRC Logo"
              style={{
                objectFit: "none",
                height: "160px",
                width: "100%",
                borderRadius: "50px",
              }}
            />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
}

export default ProductImage;
