/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import React, { useState, useEffect, Component } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import pageRoutes from "constants/pageRoutes";
import trainingList from "components/data/trainingList";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import withAuthorization from "../withAuthorization";

function TrainingList() {
  const [loading, setLoading] = useState(true);
  const [dataSet, setDataSet] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });

      client
        .query({
          query: gql`
            query training {
              training {
                id
                organisation
                trainee
                training_date
                educator
              }
            }
          `,
        })
        .then((result) => {
          setDataSet(trainingList({ dataset: result.data.training }));
        });
    }
    setLoading(false);
  }, [auth.currentUser]);

  return (
    <DashboardLayout>
      <DefaultNavbar routes={pageRoutes} />
      <SuiBox pt={6} pb={3}>
        <SuiBox my={0}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={3}
            >
              <SuiBox lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Training Reports
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  You can track the training records on this page.
                </SuiTypography>
              </SuiBox>

              <Stack spacing={1} direction="row">
                <Link to="/training/add" className="decoration-none">
                  <SuiButton variant="gradient" buttonColor="info" size="small">
                    + Add Training
                  </SuiButton>
                </Link>
              </Stack>
            </SuiBox>
            {dataSet && !loading ? (
              <DataTable
                table={dataSet}
                entriesPerPage={{
                  defaultValue: 16,
                  entries: [16, 32, 64],
                }}
                canSearch
              />
            ) : (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={0}
              >
                <img
                  src="/loader.gif"
                  alt="TRC Logo"
                  style={{
                    objectFit: "none",
                    height: "160px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </SuiBox>
            )}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}
const authCondition = (authUser) => !!authUser; //true and false
//export default DataTables;
export default withAuthorization(authCondition)(TrainingList);
