/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// moment.js is a handy library to parse, validate, manipulate, and display dates.
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

function timelinedata({dataset}){
  var data = [];

  const getTitle = (type,order_status,customs_status) => {
    var title = "";
    if(type == "order"){
      title = order_status.status_desc
    }else if(type == "customs"){
      title = customs_status.customs_status_desc
    }
    return title
  }

  const getStatusColor = (type,status) => {
    var statusColor = "error";
    if(type == "customs"){
      statusColor = (status == "applied" ? "info" : (status == "affidavitclosed" ? "warning" : "success"))
    }else if(type == "order"){
      statusColor = ((status == "waiting") ? "primary" : (status == "inwarehouse") ? "info" : (status == "prepared") ? "warning" : (status == "delivered") ? "success" : "error")
    }
    return statusColor
  }
  if(dataset.length > 0 ) {
    dataset.map((item,key) => {
      data.push({ 
        color: getStatusColor(item.log_type,item.log_status), 
        icon: "change_circle",
        title: getTitle(item.log_type,item.order_status,item.customs_status),
        dateTime:item.created_at && moment(item.created_at).format('DD MMM YYYY HH:mm'), 
        badges:item.user_name && [item.user_name.name], 
        lastItem: dataset.length === key + 1,
      })
    })

  }

  return data;
}

// Setting default values for the props of DashboardNavbar
timelinedata.defaultProps = {
  dataset: null,
};

// Typechecking props for the DashboardNavbar
timelinedata.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
};


export default timelinedata;