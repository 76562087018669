/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { pink } from "@mui/material/colors";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import { Card, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import Todo from "components/Todo";
import moment from "moment";

const JobInfo = ({ id }) => {
  const [jobs, setJobs] = useState();
  const { t, i18n } = useTranslation();

  const userToken = getAccessToken();
  const uid = auth.currentUser.uid;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    if (auth.currentUser) {
      client
        .query({
          query: gql`
            query todo_list {
              todo_list(order_by: {id: asc} ,where: {ship_info_id: {_eq: ${id}}}) {
                id
                title
                completed
                created_at
              }
            }
          `,
        })
        .then((result) => {
          setJobs(result.data.todo_list);
        });
    }
  };
  // click algılama
  // const handleChange = (id) => {
  //   getDetail()
  // };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox
          mb={2}
          lineHeight={0}
          fontWeight={700}
          width="100%"
          display="flex"
        >
          <SuiTypography
            variant="h6"
            fontWeight="bold"
            textColor="text"
            gutterBottom
          >
            {t("Previous Jobs")}
          </SuiTypography>
        </SuiBox>
        {jobs &&
          jobs.map(({ id, title, completed, created_at }, key) => (
            <Grid container mb={2} spacing={3} p={1} key={key}>
              <Todo
                id={id}
                title={title}
                completed={completed}
                defaultChecked={completed}
                disabled={true}
                date={moment(created_at).format("LLLL")}
              />
            </Grid>
          ))}
      </SuiBox>
    </Card>
  );
};

// typechecking props for Address
JobInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default JobInfo;
