let accessToken = "";
let userClaims = "";

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};

export const setUserClaims = (s: string) => {
  userClaims = s;
};

export const getUserClaims = () => {
  return userClaims;
};