const config = {
  apiKey: "AIzaSyCUACQQEjzNI89KjFun1MNXBhkacZ0rhfA",
  authDomain: "trc-marine.firebaseapp.com",
  databaseURL: "https://trc-marine-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "trc-marine",
  storageBucket: "trc-marine.appspot.com",
  messagingSenderId: "399124379704",
  appId: "1:399124379704:web:4f5b271d4954a9b3714ecd",
  measurementId: "G-E3PY2LZWXL"
};
export default config;