/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import SuiBadge from "components/SuiBadge";
import ActionCell from "components/ActionCell/listData";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

// Badges
const canceled = (
  <SuiBadge
    color="error"
    size="extra-small"
    badgeContent="Cancelled"
    container
  />
);
const delivered = (
  <SuiBadge
    color="success"
    size="extra-small"
    badgeContent="Completed"
    container
  />
);
const prepared = (
  <SuiBadge
    color="warning"
    size="extra-small"
    badgeContent="Supply Ready"
    container
  />
);
const waiting = (
  <SuiBadge
    color="primary"
    size="extra-small"
    badgeContent="Order Placed"
    container
  />
);
const inwarehouse = (
  <SuiBadge color="info" size="extra-small" badgeContent="Shipped" container />
);
const remainingDayError = (value) => (
  <SuiBadge
    variant="contained"
    color="error"
    size="extra-small"
    badgeContent={value + " Days"}
    container
  />
);
const remainingDayWarning = (value) => (
  <SuiBadge
    variant="contained"
    color="warning"
    size="extra-small"
    badgeContent={value + " Days"}
    container
  />
);
const remainingDay = (value) => (
  <SuiBadge
    variant="contained"
    color="light"
    size="extra-small"
    badgeContent={value + " Days"}
    container
  />
);
function dataTableData({ dataset, type }) {
  var data = [];

  if (dataset.length > 0) {
    dataset.map((item) => {
      const remainingDay = item.planned_delivery_date
        ? moment
            .range(
              moment().format("YYYY.MM.DD"),
              moment(item.planned_delivery_date, "YYYY-MM-DD")
            )
            .diff("days")
        : "null";
      if (type == "active") {
        data.push({
          imo: item.delivery_type == "ship" ? item.imo : item.order_number,
          shipname:
            item.delivery_type == "ship"
              ? item.shipname
              : item.delivery_company,
          ship_oparator_name:
            item.assigned_jobs &&
            item.assigned_jobs.map((item) => item.users.name).join(", "),
          ship_office_worker_name:
            item.ship_office_worker_name &&
            item.ship_office_worker_name.name.split(" ").shift().charAt(0) +
              item.ship_office_worker_name.name.split(" ").pop().charAt(0),
          delivery_date:
            item.planned_delivery_date &&
            moment(item.planned_delivery_date, "YYYY-MM-DD").format("DD.MM.YY"),
          leftDay: remainingDay,
          ETA: item.ETA && moment(item.ETA, "YYYY-MM-DD").format("DD.MM.YY"),
          locationByArrivalLocName:
            item.locationByArrivalLocName &&
            item.locationByArrivalLocName.location_full,
          status: item.ship_infos_status
            ? item.ship_infos_status.status_desc
            : "",
          action: <ActionCell id={item.id} />,
        });
      } else if (type == "history") {
        data.push({
          imo: item.delivery_type == "ship" ? item.imo : item.order_number,
          shipname:
            item.delivery_type == "ship"
              ? item.shipname
              : item.delivery_company,
          ship_oparator_name:
            item.assigned_jobs &&
            item.assigned_jobs.map((item) => item.users.name).join(", "),
          ship_office_worker_name:
            item.ship_office_worker_name &&
            item.ship_office_worker_name.name.split(" ").shift().charAt(0) +
              item.ship_office_worker_name.name.split(" ").pop().charAt(0),
          delivery_date:
            item.delivery_date &&
            moment(item.delivery_date, "YYYY-MM-DD").format("DD.MM.YY"),
          ETA: item.ETA && moment(item.ETA, "YYYY-MM-DD").format("DD.MM.YY"),
          locationByArrivalLocName:
            item.locationByArrivalLocName &&
            item.locationByArrivalLocName.location_full,
          status: item.ship_infos_status
            ? item.ship_infos_status.status_desc
            : "",
          action: <ActionCell id={item.id} />,
        });
      }
    });
  }

  if (type == "active") {
    return {
      columns: [
        {
          Header: "IMO",
          name: "imo",
          accessor: "imo",
        },
        {
          Header: "Ship Name",
          name: "shipname",
          accessor: "shipname",
        },
        {
          Header: "Operators",
          name: "ship_oparator_name",
          accessor: "ship_oparator_name",
        },
        {
          Header: "Order Owner",
          name: "ship_office_worker_name",
          accessor: "ship_office_worker_name",
        },
        {
          Header: "Days Left",
          name: "leftDay",
          accessor: "leftDay",
          Cell: ({ value }) =>
            value <= 1
              ? remainingDayError(value)
              : value <= 2
              ? remainingDayWarning(value)
              : value !== "null"
              ? remainingDay(value)
              : null,
        },
        {
          Header: "ETA",
          name: "ETA",
          accessor: "ETA",
        },
        {
          Header: "Location",
          name: "locationByArrivalLocName",
          accessor: "locationByArrivalLocName",
        },
        {
          Header: "Status",
          name: "status",
          accessor: "status",
          Cell: ({ value }) =>
            value === "Tamamlandı"
              ? delivered
              : value === "Sipariş Girildi"
              ? waiting
              : value === "Sevk Edildi"
              ? inwarehouse
              : value.toLowerCase() == "ikmale hazır"
              ? prepared
              : canceled,
        },
        {
          Header: "Process",
          name: "action",
          accessor: "action",
        },
      ],

      rows: [...data],
    };
  } else if (type == "history") {
    return {
      columns: [
        { Header: "IMO", name: "imo", accessor: "imo" },
        {
          Header: "Ship Name",
          name: "shipname",
          accessor: "shipname",
        },
        {
          Header: "Operators",
          name: "ship_oparator_name",
          accessor: "ship_oparator_name",
        },
        {
          Header: "Order Owner",
          name: "ship_office_worker_name",
          accessor: "ship_office_worker_name",
        },
        {
          Header: "ETA",
          name: "ETA",
          accessor: "ETA",
        },
        {
          Header: "Location",
          name: "locationByArrivalLocName",
          accessor: "locationByArrivalLocName",
        },
        {
          Header: "Status",
          name: "status",
          accessor: "status",
          Cell: ({ value }) =>
            value === "Tamamlandı"
              ? delivered
              : value === "Sipariş Girildi"
              ? waiting
              : value === "Sevk Edildi"
              ? inwarehouse
              : value.toLowerCase() == "ikmale hazır"
              ? prepared
              : canceled,
        },
        {
          Header: "Process",
          name: "action",
          accessor: "action",
        },
      ],

      rows: [...data],
    };
  }
}

// Setting default values for the props of DashboardNavbar
dataTableData.defaultProps = {
  dataset: [{}],
};

// Typechecking props for the DashboardNavbar
dataTableData.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string.isRequired,
};

export default dataTableData;
