/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

function reportsBarChartData({ dataset4, dataset1, dataset2, dataset3 }) {
  var data = [];
  var labels = [
    moment().add(-3, "month").startOf("month").format("MMMM"),
    moment().add(-2, "month").startOf("month").format("MMMM"),
    moment().add(-1, "month").startOf("month").format("MMMM"),
    moment().startOf("month").format("MMMM"),
  ];
  var datasets = {
    label: "Sales",
    data: [
      dataset4.order_total,
      dataset3.order_total,
      dataset2.order_total,
      dataset1.order_total,
    ],
  };

  if (data.length > 0) {
    data.map((item) => {
      data.push({
        //  action: <ActionCell id={item.id} />,
      });
    });
  }

  return {
    labels: [...labels],
    datasets: { ...datasets },
  };
}
// Setting default values for the props of DashboardNavbar
reportsBarChartData.defaultProps = {};

// Typechecking props for the DashboardNavbar
reportsBarChartData.propTypes = {
  dataset1: PropTypes.arrayOf(PropTypes.object),
  dataset2: PropTypes.arrayOf(PropTypes.object),
  dataset3: PropTypes.arrayOf(PropTypes.object),
  dataset4: PropTypes.arrayOf(PropTypes.object),
};

export default reportsBarChartData;
