/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { db, auth } from "firebase/firebase";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Dropzone from "components/Dropzone";
import SuiButton from "components/SuiButton";

import { useHistory } from "react-router-dom";
import { getAccessToken } from "constants/valueStorage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

import { useTranslation } from "react-i18next";

function BasicInfo({ id }) {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    db.ref("users/" + auth.currentUser.uid)
      .once("value")
      .then((snapshot) => {
        if (snapshot) {
          setName(snapshot.val().name);
          setEmail(snapshot.val().email);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const handleSetVisible = () => setVisible(!visible);

  const deleteFile = (resourceId, setDataSet) => {
    if (auth.currentUser) {
      client
        .mutate({
          mutation: gql`
          mutation user_resources_by_pk {
            update_user_resources_by_pk(pk_columns: {id: ${resourceId}}, _set: {status: false}) {
              id
              status
            }
          }    
          `,
        })
        .then((result) => {
          setDataSet({});
          //  (result.data.ship_infos_by_pk && result.data.ship_infos_by_pk.files && result.data.ship_infos_by_pk.files.length > 0 ) && setFiles(result.data.ship_infos_by_pk.files)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpload = (fileId, type, setDataSet) => {
    if (auth.currentUser) {
      const userToken = getAccessToken();
      const uid = auth.currentUser.uid;

      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const client = new ApolloClient({
        uri: "https://hasura-trc.365tech.dev/v1/graphql",
        headers,
        cache: new InMemoryCache(),
      });

      client
        .mutate({
          mutation: gql`
          mutation insert_user_resources_one {
            insert_user_resources_one(object:
              {
                resource_type: ${type ? JSON.stringify(type) : null}, 
                file_id: ${fileId ? fileId : null},
                user_id:${id},
              }) {
              id
              file {
                name
              }
            }
          }
          `,
        })
        .then((result) => {
          setDataSet(result.data.insert_user_resources_one);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography
            variant="h6"
            alignSelf="center"
            fontWeight="bold"
            gutterBottom
            textColor="text"
          >
            {" "}
            {t("Edit Folders")}
          </SuiTypography>
        </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Criminal Records")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="criminal_record"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Passport")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="passport"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Health Report")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="health_report"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Diploma
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="diploma_one"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                KKD
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="ppd"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                EK-2
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="ek_two"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Driving Licence")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="driving_licence"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Diploma 2
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="diploma_two"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Identity")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="identity"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Education Certificate")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="education_certificate"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Covid Vaccine Card")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="covid_vaccine_card"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Place Of Residance")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="place_residance"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox width="100%" display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Admission To Employment")}
              </SuiTypography>
              <Dropzone
                id={id}
                type={"userresources"}
                resourceType="admission_employment"
                returnValue={handleUpload}
                onDelete={deleteFile}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
