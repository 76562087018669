/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { getAccessToken } from "constants/valueStorage";
import { auth } from "firebase/firebase";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import axios from "axios";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function ActionCell({ uid, id }) {
  const { t, i18n } = useTranslation();

  const userToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const client = new ApolloClient({
    uri: "https://hasura-trc.365tech.dev/v1/graphql",
    headers,
    cache: new InMemoryCache(),
  });

  //delete user firebase with axios https://europe-west1-trc-marine.cloudfunctions.net/deleteUser
  const deleteUser = () => {
    Swal.fire({
      text: t("You want to delete the user?"),
      icon: "warning",
      showCloseButton: true,
      confirmButtonText: t("Yes"),
    }).then((result) => {
      if (result.value) {
        axios
          .post(`https://europe-west1-trc-marine.cloudfunctions.net/deleteUser?uid=${uid}`)
          .then(function (response) {
            //update user status hasura
            if (auth.currentUser) {
              client
                .mutate({
                  mutation: gql`
            mutation  update_users_by_pk {
              update_users_by_pk(pk_columns: {id: ${id}}, _set: {status: false}) {
                id
              }
            }
          `,
                })
                .then((data) => {
                  Swal.fire({
                    name: "Success",
                    text: t("User deleted successfully"),
                    icon: "success",
                    confirmButtonText: t("Ok"),
                  }).then((result) => {
                    // sayfa refresh
                    window.location.reload();
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  return (
    <SuiBox display="flex" alignItems="center">
      <SuiButton component={Link} onClick={deleteUser}>
        <Tooltip title={t("Delete Contact")} placement="top" color="secondary">
          <Icon>delete</Icon>
        </Tooltip>
      </SuiButton>
    </SuiBox>
  );
}

export default ActionCell;
